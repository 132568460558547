<template>
  <div class="ReceiptDocument">
    <loading-component v-if="isLoading"></loading-component>

    <div v-else>
      <div id="PrintReceiptAction">
        <h4>Print Receipt</h4>
        <v-btn @click="print" color="warning">Print</v-btn>
      </div>
      <div class="sprint" v-for="index in 1" :key="index">
        <v-app>
          <v-main>
            <div>
              <table style="border: none !important">
                <tr style="border: none !important">
                  <td
                    width="55%"
                    align="center"
                    style="border: none !important"
                  >
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          width="25%"
                          style="border: none !important"
                          align="center"
                        >
                          <img
                            :src="trollyLogo"
                            alt="shipping logo"
                            width="100%"
                          />
                        </td>
                        <td
                          width="75%"
                          align="center"
                          style="border: none !important"
                        >
                          <strong style="font-size: 12px"
                            >BUKTI TITIP DAN PERINTAH PENGIRIMAN BARANG</strong
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td
                    width="45%"
                    style="font-size: 12px; border: none !important"
                  >
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          width="75%"
                          align="center"
                          style="border: none !important"
                        >
                          <strong>{{ barcodeValue }}</strong>
                        </td>
                        <td
                          width="25%"
                          align="center"
                          style="border: none !important"
                        >
                          <qrcode-vue
                            :value="qrValue"
                            :size="50"
                            level="H"
                          ></qrcode-vue>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr style="border: none !important">
                  <td valign="top">
                    <table
                      style="
                        width: 100%;
                        font-size: 12px;
                        border: none !important;
                      "
                    >
                      <tr>
                        <td width="85%" style="border: none !important">
                          <div style="margin: 5px">
                            <strong>Kepada :</strong>
                            <br />

                            {{ order.recipientName }}<br />

                            Alamat:
                            {{ order.recipientAddress }}<br />

                            <strong>No Telp :</strong><br />

                            {{ order.recipientPhone }}<br />
                          </div>
                        </td>
                        <td align="center" style="border: none !important">
                          <tr>
                            <td style="padding: 5px; text-align: center">
                              <strong>City Code</strong>
                            </td>
                          </tr>

                          <tr>
                            <td style="text-align: center">
                              <strong>{{ order.cityDestination }}</strong>
                              <br />
                              <strong>{{ order.recipientPostCode }}</strong>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td>
                    <div class="Cargo">
                      <span style="padding: 5px; font-size: 12px"
                        ><strong>Jumlah Titipan</strong></span
                      >

                      <table
                        style="margin: 12px auto; font-size: 12px"
                        width="90%"
                      >
                        <tr>
                          <td>Jml Item</td>
                          <td>Jml Kg</td>
                          <td>Jml Kg Final</td>
                          <td>Jml CBM</td>
                          <td>Jml Koli</td>

                          <td style="width: 80px; text-align: center">
                            <strong>Service</strong>
                          </td>
                        </tr>

                        <tr>
                          <th>{{ order.collies.length }}</th>
                          <th>{{ grossWeight(order.collies) }}</th>
                          <th>{{ kgFinal(order.collies) }}</th>
                          <th>
                            {{ (cbm(order.collies) / 1000000).toFixed(2) }}
                          </th>
                          <th>{{ sumField("JumlahKoli") }}</th>

                          <td style="text-align: center">
                            <strong>{{ order.moda }}</strong>
                          </td>
                        </tr>
                      </table>

                      <hr />

                      <table
                        width="100%"
                        style="
                          font-size: 12px !important;
                          border: none !important;
                        "
                      >
                        <tr>
                          <td style="border: none !important">
                            Jenis Pembayaran
                          </td>
                          <td style="border: none !important">
                            :
                            <strong>{{
                              order.dfod ? "Tagih Di Tempat Tujuan" : "Cash"
                            }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="border: none !important">Biaya Kirim</td>
                          <td style="border: none !important">
                            : <strong>{{ order.price }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="border: none !important">Biaya Lain</td>
                          <td style="border: none !important">
                            : <strong>{{ order.packingPrice }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="border: none !important">Total</td>
                          <td style="border: none !important">
                            : <strong>{{ order.totalPrice }}</strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>

                <tr style="border: none !important">
                  <td
                    style="
                      border-right: none !important;
                      border-bottom: none !important;
                    "
                    valign="top"
                  >
                    <table
                      style="
                        width: 100%;
                        padding: 5px;
                        font-size: 12px;
                        border: none !important;
                      "
                    >
                      <tr>
                        <td
                          width="85%"
                          style="border: none !important"
                          valign="top"
                        >
                          <div style="margin: 5px">
                            <strong>Dari :</strong>
                            <br />

                            {{ order.shipperName }}<br />

                            Alamat: {{ order.shipperAddress }}<br />

                            <strong>No Telp :</strong><br />

                            {{ order.shipperPhone }}<br />
                          </div>
                        </td>
                        <td align="center" style="border: none !important">
                          <tr>
                            <td style="padding: 5px; text-align: center">
                              <strong>City Code</strong>
                            </td>
                          </tr>

                          <tr>
                            <td style="text-align: center">
                              <strong>{{ order.cityOrigin }}</strong>
                              <br />
                              <strong style="text-align: center">{{
                                order.shipperPostCode
                              }}</strong>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </td>

                  <td
                    rowspan="1"
                    valign="top"
                    style="border-bottom: none !important"
                  >
                    <div
                      style="
                        margin: 5px;
                        font-size: 12px;
                        width: 50% !important;
                        float: left;
                        display: block;
                      "
                    >
                      Keterangan :<br />
                      <strong>{{ order.description }}</strong>
                      <br />

                      Nilai Barang:
                      <br />

                      <strong>{{ order.estimasiNilai }}</strong
                      ><br />

                      Asuransi: <br />
                      <strong>{{ order.insurance }}</strong
                      ><br />
                    </div>
                    <div
                      style="
                        width: 45% !important;
                        float: left;
                        display: block;
                        margin: 5px;
                        font-size: 12px;
                      "
                    >
                      Kategory : <br />
                      <strong>{{ order.kategory }}</strong>
                      <br />
                      Detail Koli :
                      <br />
                      <span
                        v-for="koli in order.collies"
                        :key="koli.IDInbKoli"
                        style="font-size: 10px !important"
                        >{{ koli.DescriptionItem }},
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td valign="bottom" style="border-top: none !important">
                    <p
                      style="
                        font-size: 8px;
                        padding: 5px 12px;
                        background-color: lightgrey;
                        margin-bottom: 5px;
                      "
                    >
                      Dengan menyerahkan barang titipan ke Trolly maka kami
                      menyatakan bahwa semua pernyataan terkait barang tersebut
                      adalah benar dan kami tunduk kepada syarat dan ketentuan
                      pengiriman Trolly.
                    </p>
                  </td>
                  <td style="border-top: none !important">
                    <table style="margin: 12px; width: 95%">
                      <tr>
                        <td>
                          <div style="padding: 5px; font-size: 8px">
                            <strong>Catatan</strong><br />
                            <ol style="font-size: 7px; padding-left: 12px">
                              <li>
                                Alamat pengirim dan penerim harus ditulis dengan
                                lengkap dan jelas
                              </li>
                              <li>
                                Barang titipan diterima pda hari sabtu atau pada
                                hari dimana keesokan harinya adalah hari libur,
                                akan dikirimkan pada hari berikutnya
                              </li>
                              <li>
                                Alamat yang tidak jelas tidak dapat diterima
                                termasuk alamat dengan menggunakan PO BOX
                              </li>
                            </ol>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      width="100%"
                      style="font-size: 12px; border: none !important"
                    >
                      <tr style="border: none !important">
                        <td colspan="2" style="border: none !important">
                          Estimasi Pengiriman : {{ order.etd }} kerja
                          <br />
                          <p
                            style="
                              text-align: start;
                              pading-left: 5px;
                              font-size: 10px !important;
                            "
                          >
                            Tanggal: {{ order.date }} / {{ index }}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="border: none !important">
                          <strong>Nama Pengirim</strong>
                        </td>
                        <td align="center" style="border: none !important">
                          <strong>Tanda Tangan</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td style="border: none !important">
                          <table style="width: 100%; border: none !important">
                            <tr>
                              <td
                                width="60%"
                                align="center"
                                style="border: none !important"
                              >
                                <barcode
                                  :value="barcodeValue"
                                  text-margin="0"
                                  background="none"
                                  width="1"
                                  height="30"
                                  font-size="14"
                                >
                                </barcode>
                              </td>
                              <td
                                width="40%"
                                align="center"
                                style="border: none !important; font-size: 14px"
                              >
                                <strong
                                  >{{ order.cityOrigin }} /
                                  {{ order.cityDestination }}</strong
                                >
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </v-main>
        </v-app>
      </div>

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :manual-pagination="true"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-content-width="207mm"
        :paginate-elements-by-height="100"
        pdf-orientation="portrait"
        ref="html2PdfInvoice"
        :html-to-pdf-options="htmlToPdfOptions"
      >
        <section slot="pdf-content">
          <div
            class="sprint"
            v-for="index in 1"
            :key="index"
            :style="index == 1 ? { 'margin-top': '30px !important' } : ''"
          >
            <v-app>
              <v-main>
                <div>
                  <table
                    style="
                      border: none !important;
                      font-weight: bold !important;
                    "
                  >
                    <tr style="border: none !important">
                      <td
                        width="55%"
                        align="center"
                        style="border: none !important"
                      >
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              width="25%"
                              style="border: none !important"
                              align="center"
                            >
                              <img
                                :src="trollyLogo"
                                alt="shipping logo"
                                width="100%"
                              />
                            </td>
                            <td
                              width="75%"
                              align="center"
                              style="border: none !important"
                            >
                              <strong style="font-size: 12px"
                                >BUKTI TITIP DAN PERINTAH PENGIRIMAN
                                BARANG</strong
                              >
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        width="45%"
                        style="font-size: 12px; border: none !important"
                      >
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              width="75%"
                              align="center"
                              style="border: none !important"
                            >
                              <strong>{{ barcodeValue }}</strong>
                            </td>
                            <td
                              width="25%"
                              align="center"
                              style="border: none !important"
                            >
                              <qrcode-vue
                                :value="qrValue"
                                :size="50"
                                level="H"
                                render-as="svg"
                              ></qrcode-vue>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr style="border: none !important">
                      <td valign="top">
                        <table
                          style="
                            width: 100%;
                            font-size: 12px;
                            border: none !important;
                          "
                        >
                          <tr>
                            <td width="85%" style="border: none !important">
                              <div style="margin: 5px">
                                <strong>Kepada :</strong>
                                <br />

                                {{ order.recipientName }}<br />

                                Alamat:
                                {{ order.recipientAddress }}<br />

                                <strong>No Telp :</strong><br />

                                {{ order.recipientPhone }}<br />
                              </div>
                            </td>
                            <td align="center" style="border: none !important">
                              <tr>
                                <td style="padding: 5px; text-align: center">
                                  <strong>City Code</strong>
                                </td>
                              </tr>

                              <tr>
                                <td style="text-align: center">
                                  <strong>{{ order.cityDestination }}</strong>
                                  <br />
                                  <strong>{{ order.recipientPostCode }}</strong>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td style="border-left: none !important">
                        <div class="Cargo">
                          <span style="padding: 5px; font-size: 12px"
                            ><strong>Jumlah Titipan</strong></span
                          >

                          <table
                            style="margin: 12px auto; font-size: 12px"
                            width="90%"
                          >
                            <tr>
                              <td>Jml. Item</td>
                              <td>Jml. Kg</td>
                              <td>Jml. Kg Final</td>
                              <td>Jml. CBM</td>
                              <td>Jml. Koli</td>

                              <td style="width: 80px; text-align: center">
                                <strong>Service</strong>
                              </td>
                            </tr>

                            <tr>
                              <th>{{ order.collies.length }}</th>
                              <th>{{ grossWeight(order.collies) }}</th>
                              <th>{{ kgFinal(order.collies) }}</th>
                              <th>
                                {{ (cbm(order.collies) / 1000000).toFixed(2) }}
                              </th>
                              <th>{{ sumField("JumlahKoli") }}</th>

                              <td style="text-align: center">
                                <strong>{{ order.moda }}</strong>
                              </td>
                            </tr>
                          </table>

                          <hr />

                          <table
                            width="100%"
                            style="
                              font-size: 12px !important;
                              border: none !important;
                              margin: 5px !important;
                            "
                          >
                            <tr>
                              <td style="border: none !important">
                                Jenis Pembayaran
                              </td>
                              <td style="border: none !important">
                                :
                                <strong>{{
                                  order.dfod ? "Tagih Di Tempat Tujuan" : "Cash"
                                }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none !important">
                                Biaya Kirim
                              </td>
                              <td style="border: none !important">
                                : <strong>{{ order.price }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none !important">
                                Biaya Lain
                              </td>
                              <td style="border: none !important">
                                : <strong>{{ order.packingPrice }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none !important">Total</td>
                              <td style="border: none !important">
                                : <strong>{{ order.totalPrice }}</strong>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr style="border: none !important">
                      <td
                        style="
                          border-right: none !important;
                          border-bottom: none !important;
                          border-right: 1px solid !important;
                        "
                        valign="top"
                      >
                        <table
                          v-if="index == 1 && order.dfod == true"
                          style="
                            width: 100%;
                            padding: 5px;
                            font-size: 12px;
                            border: none !important;
                          "
                        >
                          <tr>
                            <td
                              width="85%"
                              style="border: none !important"
                              valign="top"
                            >
                              <div style="margin: 5px">
                                <h1
                                  style="
                                    font-size: 40px;
                                    text-align: center;
                                    padding: 0 !important;
                                    margin: 0 !important;
                                  "
                                >
                                  DFOD
                                </h1>
                                <h1
                                  style="
                                    text-align: center;
                                    padding: 0 !important;
                                    margin: 0 !important;
                                  "
                                >
                                  Total Tagihan:
                                </h1>
                                <h1
                                  style="
                                    font-size: 20px;
                                    text-align: center;
                                    padding: 0 !important;
                                    margin: 0 !important;
                                  "
                                >
                                  {{ order.totalPrice }}
                                </h1>
                                <h1 style="text-align: center">
                                  Harap Di Tempel Pada Paket
                                </h1>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <table
                          v-else
                          style="
                            width: 100%;
                            padding: 5px;
                            font-size: 12px;
                            border: none !important;
                          "
                        >
                          <tr>
                            <td
                              width="85%"
                              style="border: none !important"
                              valign="top"
                            >
                              <div style="margin: 5px">
                                <strong>Dari :</strong>
                                <br />

                                {{ order.shipperName }}<br />

                                Alamat: {{ order.shipperAddress }}<br />

                                <strong>No Telp :</strong><br />

                                {{ order.shipperPhone }}<br />
                              </div>
                            </td>
                            <td align="center" style="border: none !important">
                              <tr>
                                <td style="padding: 5px; text-align: center">
                                  <strong>City Code</strong>
                                </td>
                              </tr>

                              <tr>
                                <td style="text-align: center">
                                  <strong>{{ order.cityOrigin }}</strong>
                                  <br />
                                  <strong style="text-align: center">{{
                                    order.shipperPostCode
                                  }}</strong>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td
                        rowspan="1"
                        valign="top"
                        style="
                          border-bottom: none !important;
                          border-left: none !important;
                        "
                      >
                        <div
                          style="
                            margin: 5px;
                            font-size: 12px;
                            width: 50% !important;
                            float: left;
                            display: block;
                          "
                        >
                          Keterangan :<br />
                          <strong>{{ order.description }}</strong>
                          <br />

                          Nilai Barang:
                          <br />

                          <strong>{{ order.estimasiNilai }}</strong
                          ><br />

                          Asuransi: <br />
                          <strong>{{ order.insurance }}</strong
                          ><br />
                        </div>
                        <div
                          style="
                            width: 40% !important;
                            float: left;
                            display: block;
                            margin: 5px;
                            font-size: 12px;
                          "
                        >
                          Kategory : <br />
                          <strong>{{ order.kategory }}</strong>
                          <br />
                          Detail Koli :
                          <br />
                          <span
                            v-for="koli in order.collies"
                            :key="koli.IDInbKoli"
                            style="font-size: 10px !important"
                            >{{ koli.DescriptionItem }},
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style="  border-right;: none !important">
                      <td valign="bottom" style="border-top: none !important">
                        <p
                          style="
                            font-size: 8px;
                            padding: 5px 12px;
                            background-color: lightgrey;
                            margin-bottom: 5px;
                             border-right;: none !important
                          "
                        >
                          Dengan menyerahkan barang titipan ke Trolly maka kami
                          menyatakan bahwa semua pernyataan terkait barang
                          tersebut adalah benar dan kami tunduk kepada syarat
                          dan ketentuan pengiriman Trolly.
                        </p>
                      </td>
                      <td
                        style="
                          border-top: none !important;
                          border-left: none !important;
                        "
                      >
                        <table style="margin: 5px; width: 97%">
                          <tr>
                            <td>
                              <div style="padding: 5px; font-size: 8px">
                                <strong>Catatan</strong><br />
                                <ol style="font-size: 7px; padding-left: 12px">
                                  <li>
                                    Alamat pengirim dan penerim harus ditulis
                                    dengan lengkap dan jelas
                                  </li>
                                  <li>
                                    Barang titipan diterima pda hari sabtu atau
                                    pada hari dimana keesokan harinya adalah
                                    hari libur, akan dikirimkan pada hari
                                    berikutnya
                                  </li>
                                  <li>
                                    Alamat yang tidak jelas tidak dapat diterima
                                    termasuk alamat dengan menggunakan PO BOX
                                  </li>
                                </ol>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          width="100%"
                          style="font-size: 12px; border: none !important"
                        >
                          <td colspan="2" style="border: none !important">
                            Estimasi Pengiriman : {{ order.etd }} kerja
                            <br />
                            <p
                              style="
                                text-align: start;
                                pading-left: 5px;
                                font-size: 10px !important;
                              "
                            >
                              Tanggal: {{ order.date }} / {{ index }}
                            </p>
                          </td>
                          <tr>
                            <td align="center" style="border: none !important">
                              <strong>Nama Pengirim</strong>
                            </td>
                            <td align="center" style="border: none !important">
                              <strong>Tanda Tangan</strong>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style="border-left: none !important">
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td style="border: none !important">
                              <table
                                style="width: 100%; border: none !important"
                              >
                                <tr>
                                  <td
                                    width="60%"
                                    align="center"
                                    style="border: none !important"
                                  >
                                    <barcode
                                      :value="barcodeValue"
                                      text-margin="0"
                                      background="none"
                                      width="1"
                                      height="30"
                                      font-size="14"
                                    >
                                    </barcode>
                                  </td>
                                  <td
                                    width="40%"
                                    align="center"
                                    style="
                                      border: none !important;
                                      font-size: 14px;
                                    "
                                  >
                                    <strong
                                      >{{ order.cityOrigin }} /
                                      {{ order.cityDestination }}</strong
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-main>
            </v-app>
          </div>

          <div class="html2pdf__page-break" />

          <div
            class="sprint"
            v-for="index in 1"
            :key="index"
            :style="index == 1 ? { 'margin-top': '30px !important' } : ''"
          >
            <v-app>
              <v-main>
                <div>
                  <table
                    style="
                      border: none !important;
                      font-weight: bold !important;
                    "
                  >
                    <tr style="border: none !important">
                      <td
                        width="55%"
                        align="center"
                        style="border: none !important"
                      >
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              width="25%"
                              style="border: none !important"
                              align="center"
                            >
                              <img
                                :src="trollyLogo"
                                alt="shipping logo"
                                width="100%"
                              />
                            </td>
                            <td
                              width="75%"
                              align="center"
                              style="border: none !important"
                            >
                              <strong style="font-size: 12px"
                                >BUKTI TITIP DAN PERINTAH PENGIRIMAN
                                BARANG</strong
                              >
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        width="45%"
                        style="font-size: 12px; border: none !important"
                      >
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              width="75%"
                              align="center"
                              style="border: none !important"
                            >
                              <strong>{{ barcodeValue }}</strong>
                            </td>
                            <td
                              width="25%"
                              align="center"
                              style="border: none !important"
                            >
                              <qrcode-vue
                                :value="qrValue"
                                :size="50"
                                level="H"
                                render-as="svg"
                              ></qrcode-vue>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr style="border: none !important">
                      <td valign="top">
                        <table
                          style="
                            width: 100%;
                            font-size: 12px;
                            border: none !important;
                          "
                        >
                          <tr>
                            <td width="85%" style="border: none !important">
                              <div style="margin: 5px">
                                <strong>Kepada :</strong>
                                <br />

                                {{ order.recipientName }}<br />

                                Alamat:
                                {{ order.recipientAddress }}<br />

                                <strong>No Telp :</strong><br />

                                {{ order.recipientPhone }}<br />
                              </div>
                            </td>
                            <td align="center" style="border: none !important">
                              <tr>
                                <td style="padding: 5px; text-align: center">
                                  <strong>City Code</strong>
                                </td>
                              </tr>

                              <tr>
                                <td style="text-align: center">
                                  <strong>{{ order.cityDestination }}</strong>
                                  <br />
                                  <strong>{{ order.recipientPostCode }}</strong>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td style="border-left: none !important">
                        <div class="Cargo">
                          <span style="padding: 5px; font-size: 12px"
                            ><strong>Jumlah Titipan</strong></span
                          >

                          <table
                            style="margin: 12px auto; font-size: 12px"
                            width="90%"
                          >
                            <tr>
                              <td>Jml. Item</td>
                              <td>Jml. Kg</td>
                              <td>Jml. Kg Final</td>
                              <td>Jml. CBM</td>
                              <td>Jml. Koli</td>

                              <td style="width: 80px; text-align: center">
                                <strong>Service</strong>
                              </td>
                            </tr>

                            <tr>
                              <th>{{ order.collies.length }}</th>
                              <th>{{ grossWeight(order.collies) }}</th>
                              <th>{{ kgFinal(order.collies) }}</th>
                              <th>
                                {{ (cbm(order.collies) / 1000000).toFixed(2) }}
                              </th>
                              <th>{{ sumField("JumlahKoli") }}</th>

                              <td style="text-align: center">
                                <strong>{{ order.moda }}</strong>
                              </td>
                            </tr>
                          </table>

                          <hr />

                          <table
                            width="100%"
                            style="
                              font-size: 12px !important;
                              border: none !important;
                              margin: 5px !important;
                            "
                          >
                            <tr>
                              <td style="border: none !important">
                                Jenis Pembayaran
                              </td>
                              <td style="border: none !important">
                                :
                                <strong>{{
                                  order.dfod ? "Tagih Di Tempat Tujuan" : "Cash"
                                }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none !important">
                                Biaya Kirim
                              </td>
                              <td style="border: none !important">
                                : <strong>{{ order.price }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none !important">
                                Biaya Lain
                              </td>
                              <td style="border: none !important">
                                : <strong>{{ order.packingPrice }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none !important">Total</td>
                              <td style="border: none !important">
                                : <strong>{{ order.totalPrice }}</strong>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr style="border: none !important">
                      <td
                        style="
                          border-right: none !important;
                          border-bottom: none !important;
                          border-right: 1px solid !important;
                        "
                        valign="top"
                      >
                        <table
                          v-if="index == 1 && order.dfod == true"
                          style="
                            width: 100%;
                            padding: 5px;
                            font-size: 12px;
                            border: none !important;
                          "
                        >
                          <tr>
                            <td
                              width="85%"
                              style="border: none !important"
                              valign="top"
                            >
                              <div style="margin: 5px">
                                <h1
                                  style="
                                    font-size: 40px;
                                    text-align: center;
                                    padding: 0 !important;
                                    margin: 0 !important;
                                  "
                                >
                                  DFOD
                                </h1>
                                <h1
                                  style="
                                    text-align: center;
                                    padding: 0 !important;
                                    margin: 0 !important;
                                  "
                                >
                                  Total Tagihan:
                                </h1>
                                <h1
                                  style="
                                    font-size: 20px;
                                    text-align: center;
                                    padding: 0 !important;
                                    margin: 0 !important;
                                  "
                                >
                                  {{ order.totalPrice }}
                                </h1>
                                <h1 style="text-align: center">
                                  Harap Di Tempel Pada Paket
                                </h1>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <table
                          v-else
                          style="
                            width: 100%;
                            padding: 5px;
                            font-size: 12px;
                            border: none !important;
                          "
                        >
                          <tr>
                            <td
                              width="85%"
                              style="border: none !important"
                              valign="top"
                            >
                              <div style="margin: 5px">
                                <strong>Dari :</strong>
                                <br />

                                {{ order.shipperName }}<br />

                                Alamat: {{ order.shipperAddress }}<br />

                                <strong>No Telp :</strong><br />

                                {{ order.shipperPhone }}<br />
                              </div>
                            </td>
                            <td align="center" style="border: none !important">
                              <tr>
                                <td style="padding: 5px; text-align: center">
                                  <strong>City Code</strong>
                                </td>
                              </tr>

                              <tr>
                                <td style="text-align: center">
                                  <strong>{{ order.cityOrigin }}</strong>
                                  <br />
                                  <strong style="text-align: center">{{
                                    order.shipperPostCode
                                  }}</strong>
                                </td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                      </td>

                      <td
                        rowspan="1"
                        valign="top"
                        style="
                          border-bottom: none !important;
                          border-left: none !important;
                        "
                      >
                        <div
                          style="
                            margin: 5px;
                            font-size: 12px;
                            width: 50% !important;
                            float: left;
                            display: block;
                          "
                        >
                          Keterangan :<br />
                          <strong>{{ order.description }}</strong>
                          <br />

                          Nilai Barang:
                          <br />

                          <strong>{{ order.estimasiNilai }}</strong
                          ><br />

                          Asuransi: <br />
                          <strong>{{ order.insurance }}</strong
                          ><br />
                        </div>
                        <div
                          style="
                            width: 40% !important;
                            float: left;
                            display: block;
                            margin: 5px;
                            font-size: 12px;
                          "
                        >
                          Kategory : <br />
                          <strong>{{ order.kategory }}</strong>
                          <br />
                          Detail Koli :
                          <br />
                          <span
                            v-for="koli in order.collies"
                            :key="koli.IDInbKoli"
                            style="font-size: 10px !important"
                            >{{ koli.DescriptionItem }},
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr style="  border-right;: none !important">
                      <td valign="bottom" style="border-top: none !important">
                        <p
                          style="
                            font-size: 8px;
                            padding: 5px 12px;
                            background-color: lightgrey;
                            margin-bottom: 5px;
                             border-right;: none !important
                          "
                        >
                          Dengan menyerahkan barang titipan ke Trolly maka kami
                          menyatakan bahwa semua pernyataan terkait barang
                          tersebut adalah benar dan kami tunduk kepada syarat
                          dan ketentuan pengiriman Trolly.
                        </p>
                      </td>
                      <td
                        style="
                          border-top: none !important;
                          border-left: none !important;
                        "
                      >
                        <table style="margin: 5px; width: 97%">
                          <tr>
                            <td>
                              <div style="padding: 5px; font-size: 8px">
                                <strong>Catatan</strong><br />
                                <ol style="font-size: 7px; padding-left: 12px">
                                  <li>
                                    Alamat pengirim dan penerim harus ditulis
                                    dengan lengkap dan jelas
                                  </li>
                                  <li>
                                    Barang titipan diterima pda hari sabtu atau
                                    pada hari dimana keesokan harinya adalah
                                    hari libur, akan dikirimkan pada hari
                                    berikutnya
                                  </li>
                                  <li>
                                    Alamat yang tidak jelas tidak dapat diterima
                                    termasuk alamat dengan menggunakan PO BOX
                                  </li>
                                </ol>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          width="100%"
                          style="font-size: 12px; border: none !important"
                        >
                          <td colspan="2" style="border: none !important">
                            Estimasi Pengiriman : {{ order.etd }} kerja
                            <br />
                            <p
                              style="
                                text-align: start;
                                pading-left: 5px;
                                font-size: 10px !important;
                              "
                            >
                              Tanggal: {{ order.date }} / {{ index }}
                            </p>
                          </td>
                          <tr>
                            <td align="center" style="border: none !important">
                              <strong>Nama Pengirim</strong>
                            </td>
                            <td align="center" style="border: none !important">
                              <strong>Tanda Tangan</strong>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style="border-left: none !important">
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td style="border: none !important">
                              <table
                                style="width: 100%; border: none !important"
                              >
                                <tr>
                                  <td
                                    width="60%"
                                    align="center"
                                    style="border: none !important"
                                  >
                                    <barcode
                                      :value="barcodeValue"
                                      text-margin="0"
                                      background="none"
                                      width="1"
                                      height="30"
                                      font-size="14"
                                    >
                                    </barcode>
                                  </td>
                                  <td
                                    width="40%"
                                    align="center"
                                    style="
                                      border: none !important;
                                      font-size: 14px;
                                    "
                                  >
                                    <strong
                                      >{{ order.cityOrigin }} /
                                      {{ order.cityDestination }}</strong
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-main>
            </v-app>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";

import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../utils/cookies";
import { formatToRupiah } from "../../utils/formatter";
import trollyLogo from "../../assets/images/trolly-logo.png";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Receipt",
  components: {
    barcode: VueBarcode,
    QrcodeVue,
    LoadingComponent,
    VueHtml2pdf,
  },
  data() {
    return {
      trollyLogo,
      barcodeValue: "",
      qrValue: "",
      order: {},
      minEtd: "",
      maxEtd: "",
      isLoading: true,
      htmlToPdfOptions: {
        margin: 0,
        DisablePdfCompression: 1,
        image: {
          type: "jpeg",
          quality: 0.95,
        },

        enableLinks: false,

        html2canvas: {
          dpi: 300,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
        },
      },
      finalWeight: 0,
    };
  },
  methods: {
    sumField(key) {
      return this.order.collies.reduce((a, b) => a + (b[key] || 0), 0);
    },
    grossWeight(item) {
      let grosslKg = item.reduce((a, b) => a + b.Berat * b.JumlahKoli, 0);
      return Math.ceil(grosslKg);
    },
    kgFinal(item) {
      let finalKg = item.reduce((a, b) => a + b.Terberat * b.JumlahKoli, 0);
      this.finalWeight = finalKg;
      return Math.ceil(finalKg);
    },
    cbm(item) {
      return item.reduce(
        (a, b) => a + b.Panjang * b.Lebar * b.Tinggi * b.JumlahKoli,
        0
      );
    },
    print() {
      // window.print();
      try {
        window.scrollTo({ top: 0 });
        this.$refs.html2PdfInvoice.generatePdf();
      } catch (error) {
        console.log(error);
      }
    },
    fetchDetail() {
      this.isLoading = true;
      const wbNumber = this.$route.query.wb;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders/${wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          const { ShipperLoc } = data.orderResult.Order;
          const { Recipient } = data.orderResult.Order;

          this.barcodeValue = data.orderResult.WBNumber.replace(/ /g, "");
          this.qrValue = data.orderResult.WBNumber.replace(/ /g, "");

          this.order = {
            date: moment(data.orderResult.DateCreated).format("ll"),
            time: moment(data.orderResult.DateCreated).format("LT"),
            cityOrigin: res.data.data.CityOriginSNI,
            cityDestination: Recipient.City.CitySNI,
            hubOrigin: data.orderResult.Order.Hub.NamaHub,
            hubPhone: data.orderResult.Order.Hub.Telp,
            collies: data.orderResult.InboundCollies,
            packingPrice: formatToRupiah(data.orderResult.TotalBiayaPacking),
            ppn: formatToRupiah(data.orderResult.PPN),
            price: formatToRupiah(
              data.orderResult.TotalBiaya - data.orderResult.TotalBiayaPacking
            ),
            totalPrice: formatToRupiah(data.orderResult.TotalBiaya),
            totalWeight: data.orderResult.TotalBerat,
            totalColly: data.orderResult.TotalKoli,
            wbNumber: data.orderResult.WBNumber,
            description: data.orderResult.Order.Keterangan,
            shipperName: ShipperLoc.Nama,
            shipperAddress: `${ShipperLoc.Alamat}, Kel. ${ShipperLoc.NamaKelurahan}, Kec. ${ShipperLoc.NamaKecamatan}, ${ShipperLoc.NamaCity}, ${ShipperLoc.KodePos}`,
            shipperPhone: ShipperLoc.Telp,
            shipperPostCode: ShipperLoc.KodePos,
            recipientCity: Recipient.City.NamaCity.toUpperCase(),
            recipientName: Recipient.Nama,
            recipientAddress: `${Recipient.Alamat}, Kel. ${Recipient.SubDistrict.NamaKelurahan}, ${Recipient.City.NamaCity}, ${Recipient.SubDistrict.KodePos}`,
            recipientPhone: Recipient.Telp,
            recipientPostCode: Recipient.SubDistrict.KodePos,
            isInsurance: data.orderResult.Order.IsInsurance,
            insurance: formatToRupiah(data.orderResult.Order.Insurance),
            estimasiNilai: formatToRupiah(data.orderResult.EstimasiNilai),
            villageName: Recipient.SubDistrict.NamaKelurahan,
            IDCityOrigin: res.data.data.CityOriginSNI,
            KodePosDestination: Recipient.SubDistrict.KodePos,
            dfod: data.orderResult.Order.Dfod,
            moda: data.orderResult.Order.Moda,
            etd: data.orderResult.Order.Etd,
            kategory: data.orderResult.Order.Kategory,
          };

          //show etd
          // this.getPrice(this.order);

          this.isLoading = false;
          let self = this;

          setTimeout(function () {
            self.print();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getPrice(order) {
      const profile = getProfileCookie();

      axios({
        url: `${baseUrl}/CheckPrice`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Length: 0,
          Height: 0,
          Width: 0,
          Weight: Number(order.totalWeight),
          CitySNI: order.cityOrigin,
          OriginCode: profile.OriginCode,
          VillageName: order.villageName.trim().toLowerCase(),
          KCX_ViaIDVia: 1,
          KCX_ServiceIDService: 9,
          KodePosDestination: order.KodePosDestination.toString(),
        },
      })
        .then((res) => {
          this.minEtd = res.data.data.minEtd;
          this.maxEtd =
            res.data.data.maxEtd != 0 ? res.data.data.maxEtd : "30++";
        })
        .catch(() => {});
    },
  },
  created() {
    this.fetchDetail();
  },
};
</script>

<style lang="scss" scoped>
.ReceiptDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintReceiptAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }
}

@media print {
  .ReceiptDocument {
    background-color: white;
  }

  #PrintReceiptAction {
    visibility: hidden;
  }

  #Receipt {
    position: absolute;
    top: 0;
    margin: 10px auto;
    width: 400px;
  }
}

.sprint {
  height: 140mm !important;
  margin: 10px;
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0px !important;
    // border: none !important;
  }
}
</style>
