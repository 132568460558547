<template>
  <div class="DropDownRecapComponent">
    <v-data-table :headers="headers" :items="items" hide-default-footer>
    </v-data-table>
    <br />
  </div>
</template>

<script>
export default {
  name: "DropDownRecapComponent",
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Order Id", value: "orderId" },
        { text: "Wb Number", value: "wbNumber" },
        { text: "Koli", value: "colly" },
        { text: "Berat", value: "weight" },
        { text: "Status", value: "status" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
