<template>
  <div class="MangeOutbound">
    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by="sortBy"
      :sort-desc="[true]"
      :loading="isLoading"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="success" @click="view(item)">
          detail
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import moment from "moment";

export default {
  name: "MangeOutbound",
  data() {
    return {
      sortBy: [
        "date",
        // "moda",
        "destination",
        "totalColly",
        "totalWeight",
        // "driver",
        // "vehicleNumber",
        "status",
      ],
      isLoading: false,
      headers: [
        { text: "No Manifest", value: "manifestId" },
        { text: "Date", value: "date" },
        // { text: "Moda", value: "moda" },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly" },
        { text: "Total Berat", value: "totalWeight" },
        // { text: "Driver", value: "driver" },
        // { text: "Nopol.", value: "vehicleNumber" },
        { text: "Status", value: "status" },
        // { text: "Action", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      console.log(item);
    },
    fetchOrder() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/HubManifest`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredManifest = res.data.data.filter(
            (el) => el.IDManifest != 0
          );
          this.items = filteredManifest.map((manifest) => {
            return {
              manifestId: manifest.IDManifest,
              date: moment(manifest.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              // moda: manifest.Moda,
              destination: manifest.KotaTujuan,
              totalColly: manifest.TotalKoli,
              totalWeight: manifest.TotalBerat,
              status: manifest.Status,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchOrder();
  },
};
</script>

<style lang="scss" scoped>
.MangeOutbound {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
