<template>
  <div class="DialogViewTransactionComponent">
    <v-dialog v-model="dialogViewTransaction" persistent max-width="600px">
      <v-card>
        <v-card-title> Detail Transaction </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Order Id</p>
                <p class="ma-0">
                  <strong>{{ transaction.orderId }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Date</p>
                <p class="ma-0">
                  <strong>{{ transaction.date }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Penerima</p>
                <p class="ma-0">
                  <strong>{{ transaction.recipient }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Tujuan</p>
                <p class="ma-0">
                  <strong>{{ transaction.destination }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Pengirim</p>
                <p class="ma-0">
                  <strong>{{ transaction.shipper }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Asal</p>
                <p class="ma-0">
                  <strong>{{ transaction.origin }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Total Koli</p>
                <p class="ma-0">
                  <strong>{{ transaction.colly }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Total Berat</p>
                <p class="ma-0">
                  <strong>{{ transaction.weight }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <h4 class="ma-0">Harga</h4>
                <h4 class="ma-0">
                  <strong>{{ transaction.price }}</strong>
                </h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <h4 class="ma-0">Status</h4>
                <h4 class="ma-0">
                  <strong>{{ transaction.status }}</strong>
                </h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="cyan" outlined @click="toggleDialog">cancel</v-btn> -->

          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogViewTransactionComponent",
  props: ["transaction"],
  data() {
    return {
      dialogViewTransaction: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogViewTransaction = !this.dialogViewTransaction;
    },
  },
};
</script>

<style lang="scss" scoped></style>
