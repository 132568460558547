<template>
  <div class="PageNotFound">
    <h1>404 Page not found</h1>
    <v-btn depressed color="black" class="white--text" @click="goHome"
      >To home</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.PageNotFound {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
