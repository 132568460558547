<template>
  <div class="DetailTransaction">
    <v-container class="ma-0 mb-4">
      <v-row>
        <v-col sm="2" class="pa-0">
          <v-text-field
            class="mr-2"
            v-model="startDate"
            type="date"
            outlined
            label="From"
            dense
          ></v-text-field>
        </v-col>

        <v-col sm="2" class="pa-0">
          <v-text-field
            v-model="endDate"
            type="date"
            outlined
            label="To"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="2" class="pa-0">
          <v-select
            class="mr-2"
            outlined
            dense
            label="Origin"
            item-text="text"
            item-value="value"
            :items="originList"
            v-model="origin"
          ></v-select>
        </v-col>

        <v-col sm="2" class="pa-0">
          <v-select
            outlined
            dense
            label="Destination"
            item-text="text"
            item-value="value"
            :items="destinationList"
            v-model="destination"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0">
          <v-select
            outlined
            dense
            label="Status"
            item-text="text"
            item-value="value"
            :items="statusList"
            v-model="status"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0 mr-2">
          <v-btn depressed color="cyan" class="mr-2 white--text" @click="search"
            >Search</v-btn
          >

          <v-btn depressed color="error" @click="refresh">refresh</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :loading="isLoading"
      :sort-by="sortBy"
      :sort-desc="[false]"
    >
      <template v-slot:top>
        <dialog-view-transaction-component
          :transaction="itemDetail"
          ref="viewTransactionDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="success" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogViewTransactionComponent from "../../../components/dashboard/module/DialogViewTransactionComponent.vue";

import { formatToRupiah } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import moment from "moment";

export default {
  components: { DialogViewTransactionComponent },
  name: "DetailTransaction",
  data() {
    return {
      itemDetail: {},
      startDate: "",
      endDate: "",
      status: "",
      origin: "",
      destination: "",
      originList: [],
      destinationList: [],
      statusList: [],
      isLoading: false,
      sortBy: [
        "date",
        "shipper",
        "origin",
        "recipient",
        "destination",
        "colly",
        "weight",
        "price",
        "hub",
        "status",
      ],
      headers: [
        { text: "Wb Number", value: "wbNumber", filterable: false },
        { text: "Date", value: "date" },
        { text: "Shipper", value: "shipper", filterable: false },
        { text: "Origin", value: "origin", filterable: false },
        { text: "Recipient", value: "recipient", filterable: false },
        { text: "Destination", value: "destination", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        // { text: "Hub", value: "hub", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      filteredItems: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewTransactionDialog.toggleDialog();
    },
    fetchOrders() {
      const hubId = getProfileCookie().KCX_HubIDHub;
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21",
          hub: hubId,
        },
      })
        .then((res) => {
          console.log(res.data.data);
          const items = res.data.data.map((order) => {
            return {
              wbNumber: order.WBNumber,
              date: moment(order.DateCreated)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              origin: order.Order.ShipperLoc.NamaCity,
              shipper: order.Order.ShipperLoc.Nama,
              recipient: order.Order.Recipient.Nama,
              destination: order.Order.Recipient.City.NamaCity,
              colly: order.TotalKoli,
              weight: order.TotalBerat,
              status: order.PackageStatus.Status,
              price: formatToRupiah(order.TotalBiaya),
              // hub: order.Order.Hub.NamaHub,
            };
          });
          this.items = items;
          this.filteredItems = items;
          this.generateList(items);
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    generateList(data) {
      this.statusList = [...new Set(data.map((item) => item.status))];
      this.originList = [...new Set(data.map((item) => item.origin))];
      this.destinationList = [...new Set(data.map((item) => item.destination))];
    },
    search() {
      if (this.startDate && this.endDate) {
        this.filterDate();
      }

      if (this.origin) {
        this.filterOrigin();
      }

      if (this.destination) {
        this.filterDestination();
      }

      if (this.status) {
        this.filterStatus();
      }
    },
    refresh() {
      this.filteredItems = this.items;
      this.startDate = "";
      this.endDate = "";
      this.destination = "";
      this.origin = "";
      this.status = "";
    },
    filterOrigin() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.origin == this.origin;
      });
    },
    filterDestination() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.destination == this.destination;
      });
    },
    filterStatus() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.status == this.status;
      });
    },
    filterDate() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.date >= this.startDate && el.date <= this.endDate;
      });
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>

<style lang="scss" scoped>
.DetailTransaction {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
