<template>
  <div class="FareCheckInternational">
    <div class="FareCheckInternational-Form">
      <v-form ref="form" v-model="valid">
        <v-container class="ma-0">
          <v-row>
            <v-col cols="12" sm="3">
              <v-combobox
                label="City Origin"
                outlined
                dense
                :rules="rules"
                :items="cityList"
                @input="setCity"
                :value="city"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="2">
              <v-select
                :items="['REGULAR', 'DIRECT', 'GLOBAL']"
                v-model="service"
                item-text="text"
                item-value="value"
                label="Service"
                outlined
                dense
                :rules="rules"
                @input="setService"
              ></v-select>
            </v-col>
            <v-col xs="2">
              <v-combobox
                label="Receiver Country"
                outlined
                dense
                :rules="rules"
                :items="filterCountry"
                v-model="country"
                item-text="CountryName"
              ></v-combobox>
            </v-col>
            <v-col xs="2">
              <v-combobox
                label="Type Paket"
                outlined
                dense
                :rules="rules"
                :items="service != 'DIRECT' ? ['Dokumen', 'Paket'] : ['Paket']"
                v-model="categoryPacket"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="weight"
                label="Berat, Kg"
                type="number"
                :rules="rules"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
              <v-text-field
                v-model="length"
                label="Panjang"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="width"
                label="Lebar"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="height"
                label="Tinggi"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col xs="2">
              <v-btn
                :loading="isLoading"
                color="cyan"
                class="white--text"
                depressed
                block
                @click="check"
                >cek tarif</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div class="FareCheckInternational-Result">
      <hr />
      <div v-if="isResult">
        <br />
        <h3>Origin: {{ origin }}</h3>
        <h3>Destination: {{ destination }}</h3>
        <br />
        <div v-if="listPrice.length > 0">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Service</th>
                  <th class="text-left">Price</th>
                  <th class="text-left">Etd</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listPrice" :key="item.price">
                  <td>
                    <v-chip class="ma-2" color="green" small text-color="white">
                      {{ item.service }} - {{ item.category }}
                    </v-chip>
                  </td>
                  <td>
                    <strong>{{
                      convertToRp(item.price + additionalPrice)
                    }}</strong>
                  </td>
                  <td>{{ item.etd }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p
            v-if="service == 'DIRECT'"
            class="mt-5 font-weight-red"
            style="color: red"
          >
            <br />
            <v-btn
              class="ml-5"
              color="red lighten-2"
              dark
              small
              @click="skdirect = true"
            >
              Syarat & Ketentuan
            </v-btn>
          </p>
          <span
            v-if="service == 'REGULAR'"
            style="color: red; font-size: 12px"
            class="text-center"
          >
            <br />
            <v-btn
              class="ml-5"
              color="red lighten-2"
              dark
              small
              @click="skreg = true"
            >
              Syarat & Ketentuan
            </v-btn>
          </span>

          <v-img style="margin: 20px auto" :src="tos" width="50%"></v-img>
        </div>
        <div v-else>
          <v-card class="d-flex align-center" height="50">
            <v-scroll-y-transition>
              <div class="flex-grow-1 text-center font-weight-black">
                <v-row>
                  <v-col v-if="isLoading" cols="12">Loading...</v-col>
                  <v-col v-else cols="12">Coba Beberapa Saat Lagi </v-col>
                </v-row>
              </div>
            </v-scroll-y-transition>
          </v-card>
        </div>
        <hr />
      </div>
      <template>
        <div class="text-center">
          <v-dialog v-model="skreg" width="700">
            <v-card>
              <v-card-title class="text-h5 lighten-2">
                Ketentuan umum layanan REGULER
              </v-card-title>

              <v-card-text class="pa-5">
                <div class="light-blue lighten-5 pa-3 black--text">
                  Berat yang dikenakan biaya (kg) kiriman ditentukan oleh berat
                  kotor sebenarnya atau berat volumetrik (P x L x T (cm) 5.000),
                  yang mana lebih besar lagi.
                  <ul>
                    <li>
                      Tarif di atas sudah termasuk (include) Fuel Surcharge,
                      Temporary Emergency Surcharge, Remote Area Surcharge.
                    </li>
                    <li>
                      Tarif di atas Tidak termasuk (exclude) Surcharge Special
                      Handling Fee and other Duties & Taxes, VAT 1.1%
                    </li>
                  </ul>
                </div>
                <div class="light-blue lighten-5 mt-3 pa-3 black--text">
                  <ol>
                    <li>
                      Harga yang berlaku untuk Layanan REG harus dikirim/diantar
                      langsung ke Trolly Asia HQ JAKARTA di alamat:
                      <ul>
                        <li>
                          Komplek Grand Puri Grisenda Blok GD No.30 Jakarta
                          Utara 14460
                        </li>
                      </ul>
                    </li>
                    <li>
                      Ketentuan Komoditi yang dapat dikirim via Trolly Asia –
                      (terlampir)
                    </li>
                    <ul>
                      <li>General Kargo</li>
                      <li>
                        Kosmetik – Personal shipment 1 – 2 kg ( untuk sementara
                        SIN & MY, di luar dari negara tsb harus konfirmasi
                        terlebih dahulu )
                      </li>
                      <li>
                        Herbal – personal herbal 1 kg ( untuk sementara SIN &
                        MY, di luar dari negara tsb harus konfirmasi terlebih
                        dahulu )
                      </li>
                      <li>
                        Makanan – makanan kering – personal food / personal
                        consumption – 4 kg ( untuk sementara SIN & MY, di luar
                        dari negara tsb harus konfirmasi terlebih dahulu )
                      </li>
                      <li>
                        Special komoditi harus dikonfirmasikan terlebih dahulu
                      </li>
                      <li>
                        Berat melebihi dari poin b s/d poin d harus
                        dikonfirmasikan terlebih dahulu
                      </li>
                      <li>
                        Untuk general kargo :
                        <ul>
                          <li>
                            maksimum berat per koli 25 kg, ( AUSTRALIA 20 kg per
                            koli ),
                          </li>
                          <li>maksimum Panjang 110 cm,</li>
                          <li>
                            maksimum keliling 330 cm ( 2Panjang + 2Lebar + 1
                            Tinggi )
                          </li>
                        </ul>
                      </li>
                      <li>
                        Wajib cek isi Kiriman sebelum menbuat order, apabila isi
                        barang tidak sesuai dengan dokumen pada saat proses
                        ekspor, & hal ini menyebabkan proses ekspor Trolly Asia
                        terhambat atau terhenti, maka Trolly Asia akan
                        mengenakan denda atau penalti kepada pemilik barang atau
                        denda minimal Rp.100.000 per pengiriman.
                      </li>
                      <li>
                        Harus menggunakan sistem online Trolly Asia untuk
                        membuat Order
                      </li>
                      <li>
                        Cut off time Penerima paket setiap senin - jumat cut off
                        jam 15:00, Proses barang H+1 setelah terima paket,
                        (misal barang pickup senin diberangkatkan selasa)
                      </li>
                    </ul>
                  </ol>
                </div>
                <div class="light-blue lighten-5 mt-3 pa-3 black--text">
                  Jangan ragu untuk menghubungi <strong>HQ</strong> jika Anda
                  memiliki pertanyaan. Terima kasih
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="skreg = false">
                  Mengerti
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <template>
        <div class="text-center">
          <v-dialog v-model="skdirect" width="700">
            <v-card>
              <v-card-title class="text-h5 lighten-2">
                Ketentuan umum layanan DIRECT
              </v-card-title>

              <v-card-text class="pa-5">
                <div class="light-blue lighten-5 pa-3 black--text">
                  <ul>
                    <!-- <li>Harga Belum Termasuk PPN</li> -->

                    <span v-if="listPrice.length > 0">
                      <li v-if="service == 'DIRECT'">
                        Keberangakatan setiap hari selasa - sabtu
                      </li>

                      <li v-if="service == 'DIRECT'">
                        Penerima paket setiap senin - jumat cut off jam 15:00,
                        Proses barang H+1 setelah terima paket, (misal barang
                        pickup senin diberangkatkan selasa)
                      </li>

                      <li v-if="service == 'DIRECT'">
                        DIRECT / GLOBAL : LEAD TIME Berlaku untuk normal
                        clearance
                      </li>

                      <li v-if="service == 'DIRECT'">
                        DIRECT: Lead time dihitung dalam hari kerja setelah
                        keberangkatan barang
                      </li>

                      <li v-if="service == 'DIRECT'">
                        GLOBAL: Keberangkatan H+1 setelah barang kami terima
                      </li>
                    </span>
                  </ul>
                </div>

                <div class="light-blue lighten-5 mt-3 pa-3 black--text">
                  Jangan ragu untuk menghubungi <strong>HQ</strong> jika Anda
                  memiliki pertanyaan. Terima kasih
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="skdirect = false">
                  Mengerti
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { formatToRupiah } from "../../../utils/formatter";
import { mapState, mapActions } from "vuex";
import { calculateVolumetricWeight } from "../../../utils/calculationInter";
import tos from "../../../assets/images/tos.jpeg";
import cityData from "../../../data/city.json";

export default {
  name: "FareCheckInternational",
  data() {
    return {
      origin: "Indonesia",
      destination: "",
      idOrigin: "",
      idDestination: "",
      weight: 0,
      valid: false,
      price: "",
      isLoading: false,
      isResult: false,
      skreg: false,
      skdirect: false,
      minEtd: 0,
      maxEtd: 0,
      rules: [(value) => !!value || "Required"],
      ruleWeight: [
        (value) => !!value || "Required",
        (value) => value >= 3 || "Minimal 3Kg",
      ],
      length: 0,
      height: 0,
      width: 0,
      villageName: "",
      citySNI: "",
      country: "",
      tos,
      etd: "",
      service: "",
      listPrice: [],
      filterCountry: [],
      categoryPacket: "Paket",
      kodeCityOrigin: "",
      additionalPrice: 0,
      city: "",
    };
  },
  async mounted() {
    await this.getCountry();
  },
  methods: {
    ...mapActions({
      getCountry: "shipper/getCountry",
    }),
    check() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.getPrice();
      }
    },
    setService(input) {
      this.filterCountry = [];
      this.country = "";

      if (input == "REGULAR") {
        this.filterCountry = this.countryData;
      } else if (input == "DIRECT") {
        this.countryData.find((country) => {
          if (
            country.IDCountry == 190 ||
            // country.IDCountry == 114 ||
            country.IDCountry == 132 ||
            country.IDCountry == 239 ||
            country.IDCountry == 209
          ) {
            this.filterCountry.push(country);
          }
        });

        // this.weight = 3;
      } else {
        this.filterCountry = [];
      }
      // console.log(this.countryData);
    },
    getPrice() {
      this.isLoading = true;
      this.listPrice = [];
      const volumeWeight = calculateVolumetricWeight(
        this.length,
        this.width,
        this.height
      );
      const weight = Number(this.weight);
      const mostWeight = volumeWeight > weight ? volumeWeight : weight;

      axios({
        url: `${baseUrl}/CheckPriceInternational`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Koli: 1,
          Length: this.length,
          Height: this.height,
          Width: this.width,
          Weight: mostWeight,
          IDCountry: this.country.IDCountry,
          Service: this.service,
          KategoryPaket: this.categoryPacket,
          KodeCityOrigin: this.kodeCityOrigin,
        },
      })
        .then((res) => {
          this.isResult = true;
          this.etd = res.data.data.maxEtd;
          let price = res.data.data.price.filter(
            (el) => el.service == this.service
          );
          this.listPrice = price;
          this.listPrice.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
          this.isLoading = false;
          this.destination = this.country.CountryName;
          this.additionalPrice = res.data.data.additionalPrice;
        })
        .catch(() => {
          this.isResult = true;
          this.price = "No data";
          this.isLoading = false;
        });
    },
    convertToRp(bil) {
      return formatToRupiah(bil);
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);
      if (city) {
        // this.kodeCityOrigin = city.KodeCity;
        this.kodeCityOrigin = city.idCity;
        this.city = city.city;
      }
    },
  },
  computed: {
    ...mapState({
      countryData: (state) => state.shipper.countryData,
    }),
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.FareCheck {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
