<template>
  <div class="CreateOrder">
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirmation</v-card-title>
        <v-card-text>Apakah anda menambah order lain?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="done">SELESAI</v-btn>
          <v-btn color="green darken-1" text @click="addMore">TAMBAH</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDfod" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirmation</v-card-title>
        <v-card-text>Apakah anda membuat order DFOD?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="confirmDfod = false"
            >BATAL</v-btn
          >

          <v-btn
            :loading="isLoadingDfod"
            color="green darken-1"
            text
            @click="book('dfod')"
            >YA</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid" lazy-validation>
      <ShipperFormComponent />
      <br />
      <RecipientFormComponent />
      <br />
      <ItemFormComponent />
      <hr />

      <div class="CreateOrder-Summary">
        <v-container>
          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Koli</v-col>
            <v-col cols="2" class="pa-0">{{ collies }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Total Berat</v-col>
            <v-col cols="2" class="pa-0">{{ Math.ceil(mostWeight) }} Kg</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Total Biaya Packing</v-col>
            <v-col cols="2" class="pa-0" v-if="vendor == 'SENTRAL'">{{
              toRupiah(packingPrice)
            }}</v-col>
            <v-col cols="2" class="pa-0" v-else>{{ toRupiah(0) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Biaya Kirim</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(sendPrice) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Diskon</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(discount) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">PPN</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(ppn) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0">Asuransi</v-col>
            <v-col cols="2" class="pa-0">{{ toRupiah(insurance) }}</v-col>
          </v-row>

          <v-row class="d-flex justify-end ma-0">
            <v-col cols="2" class="pa-0"><strong>Total Biaya</strong></v-col>
            <v-col cols="2" class="pa-0">
              <strong v-if="sendPrice > 0">{{ toRupiah(totalBiaya) }}</strong>
              <strong v-else>{{ toRupiah(0) }}</strong>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="CreateOrder-Action">
        <v-btn outlined color="cyan" width="200" @click="clear">clear</v-btn>

        <v-btn
          :disabled="sendPrice > 0 ? false : true"
          :loading="isLoading"
          depressed
          color="cyan"
          d
          width="200"
          class="ml-2 white--text"
          @click="book"
          >book
        </v-btn>
        <v-btn
          v-if="dfod && citySNI == 'CGK'"
          :disabled="sendPrice > 0 ? false : true"
          :loading="isLoadingDfod"
          depressed
          color="warning"
          width="200"
          class="ml-2 white--text"
          @click="confirmDfod = true"
          >DFOD</v-btn
        >
        <!-- <v-btn
          v-if="dfod"
          disabled
          :loading="isLoadingDfod"
          depressed
          color="warning"
          width="200"
          class="ml-2 white--text"
          >DFOD</v-btn
        > -->
      </div>
    </v-form>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ShipperFormComponent from "@/components/dashboard/inbound/ShipperFormComponent.vue";
import RecipientFormComponent from "@/components/dashboard/inbound/RecipientFormComponent.vue";
import ItemFormComponent from "@/components/dashboard/inbound/ItemFormComponent.vue";

import { formatToRupiah } from "../../../utils/formatter";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import {
  INSURANCE_RATE,
  INSURANCE_RATE_SENTRAL,
  INSURANCE_ADDITIONAL_COST,
  INSURANCE_ADDITIONAL_COST_SENTRAL,
} from "../../../constant/insurance";

export default {
  name: "CreateOrder",
  components: {
    ShipperFormComponent,
    RecipientFormComponent,
    ItemFormComponent,
  },
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      isLoadingDfod: false,
      showDialog: false,
      confirmDfod: false,
      citySNI: getProfileCookie().CitySNI,
    };
  },
  methods: {
    book(type) {
      this.valid = this.$refs.form.validate();
      const hubId = getProfileCookie().KCX_HubIDHub;
      if (!this.valid) {
        this.snackbar = true;
        this.snackbarText = "Mohon periksa data anda kembali!";
      }
      if (this.valid) {
        if (type == "dfod") {
          this.isLoadingDfod = true;
        } else {
          this.isLoading = true;
        }
        let endPoint = "";

        const isWalkin = this.$store.state.order.isWalkin;
        const idShipper = this.$store.state.order.idShipper;
        const idRecipient = this.$store.state.order.idRecipient;
        const idCollies = this.$store.state.order.idCollies;
        const idOrder = this.$store.state.order.idOrder;
        const shipper = this.$store.state.order.shipperData;
        const recipient = this.$store.state.order.recipientData;
        const item = this.$store.state.order.itemData;

        if (isWalkin) {
          endPoint = "HubOrders";
        } else {
          endPoint = "BookOrder";
        }

        const insurance = this.insurance;
        const isDfod = type == "dfod" ? true : false;
        axios({
          url: `${baseUrl}/${endPoint}`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            hub: hubId,
          },
          data: {
            idOrder,
            idShipper,
            idRecipient,
            idCollies,
            shipper: {
              Nama: shipper.shipperName,
              Telp: shipper.shipperPhone,
              Email: shipper.shipperEmail,
              Alamat: shipper.shipperAddress,
              IDCity: shipper.shipperCityId,
              NamaCity: shipper.shipperCity,
              NamaKecamatan: shipper.shipperKecamatan,
              NamaKelurahan: shipper.shipperKelurahan,
              KodePos: shipper.shipperPostCode,
              IDKecamatan: shipper.shipperKecamatanId,
              IDKelurahan: shipper.shipperKelurahanId,
              IDHub: shipper.shipperIDHub,
              NewShipper: shipper.newShipper,
            },
            recipient: {
              Nama: recipient.recipientName,
              Telp: recipient.recipientPhone,
              Email: recipient.recipientEmail,
              Kota: recipient.recipientCity,
              Alamat: recipient.recipientAddress,
              CityIDCity: recipient.recipientCityId,
              KelurahanIDKelurahan: recipient.recipientKelurahanId,
              KelurahanKodePos: recipient.recipientPostCode,
              IDHub: recipient.recipientIDHub,
              NewRecipient: recipient.newRecipient,
              RecipientNamaKelurahan: recipient.recipientNamaKelurahan,
              RecipientKelurahan: recipient.recipientKelurahan,
              RecipientKecamatanId: recipient.recipientKecamatanId,
              RecipientNamaKecamatan: recipient.recipientNamaKecamatan,
            },
            item: {
              Moda: item.moda.toUpperCase(),
              Keterangan: item.description,
              Instruction: item.instruction,
              Diskon: item.discount,
              // PPN: item.tax,
              PPN: this.ppn,
              EstimasiNilai: item.estimateValue,
              Asuransi: insurance,
              TotalKoli: this.collies,
              TotalBerat: Math.ceil(this.mostWeight),
              TotalBiayaPacking: this.packingPrice,
              BiayaKirim: this.sendPrice,
              TotalBiaya: Math.round(
                this.totalPrice + this.insurance + this.ppn
              ),
              IsInsurance: item.isInsurance,
              collies: item.collies.map((el) => {
                return {
                  JumlahKoli: el.numberColly,
                  Berat: el.weight,
                  Panjang: el.length,
                  Lebar: el.width,
                  Tinggi: el.height,
                  BeratVolume: el.volumeWeight,
                  Terberat: el.mostWeight,
                  IsPacking: el.isPacking,
                  BiayaPacking: item.vendor == "JNE" ? 0 : el.packingPrice,
                  TotalBiaya: item.vendor == "JNE" ? 0 : el.packingPrice,
                  DescriptionItem: el.descriptionItem,
                };
              }),
              Kategory: item.packageCategory,
              Vendor: item.vendor,
              Dfod: isDfod,
              Etd: item.etd,
            },
          },
        })
          .then((res) => {
            this.showDialog = true;
            this.isLoading = false;
            this.isLoadingDfod = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            // this.clear();
            this.showDialog = true;
          })
          .catch((err) => {
            this.isLoading = false;
            this.isLoadingDfod = false;
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    clear() {
      this.$store.commit("order/CLEAR_DATA");
      this.valid = this.$refs.form.resetValidation();
      this.showDialog = false;
      window.scrollTo({ top: 0 });
      window.location.reload();
      // this.$router.push("/dashboard/order-confirmation");
    },
    done() {
      this.$store.commit("order/CLEAR_DATA");
      this.valid = this.$refs.form.resetValidation();
      this.showDialog = false;
      this.$router.push("/dashboard/order-confirmation");
    },
    toRupiah(value) {
      return formatToRupiah(value);
    },
    calculatedWeightToPrice(price) {
      const perKiloPrice = price / 10;
      if (this.$store.state.order.itemData.totalWeight > 10) {
        return this.$store.state.order.itemData.totalWeight * perKiloPrice;
      }
      return this.$store.state.order.itemData.totalWeight * perKiloPrice;
    },
    addMore() {
      // this.$store.commit("order/CLEAR_RECIPIENT_INFORMATION");
      // this.valid = this.$refs.form.resetValidation();
      // this.showDialog = false;
      window.scrollTo({ top: 0 });
      window.location.reload();
    },
  },
  computed: {
    collies() {
      const collies = this.$store.state.order.itemData.collies;

      if (collies.length != 0) {
        return collies.reduce((acc, obj) => {
          return acc + obj.numberColly;
        }, 0);
      }
      return 0;
    },
    mostWeight() {
      // return this.$store.state.order.itemData.totalWeight;
      const collies = this.$store.state.order.itemData.collies;
      if (collies.length != 0) {
        let totWeight = collies.reduce((acc, obj) => {
          return acc + obj.mostWeight * obj.numberColly;
        }, 0);
        if (totWeight < 5) {
          return 5;
        } else {
          return totWeight;
        }
      }
      return 0;
    },
    packingPrice() {
      const collies = this.$store.state.order.itemData.collies;
      const vendor = this.$store.state.order.itemData.vendor;
      if (vendor == "JNE") {
        return 0;
      } else {
        if (collies.length != 0) {
          return collies.reduce((acc, obj) => {
            return acc + obj.packingPrice;
          }, 0);
        }
      }
      return 0;
    },
    sendPrice() {
      return this.$store.state.order.itemData.kargoPrice;
    },
    vendor() {
      return this.$store.state.order.itemData.vendor;
    },
    insurance() {
      if (this.$store.state.order.itemData.isInsurance) {
        if (this.$store.state.order.itemData.vendor == "JNE") {
          const estimateValue = parseFloat(
            this.$store.state.order.itemData.estimateValue
          );
          return estimateValue * INSURANCE_RATE + INSURANCE_ADDITIONAL_COST;
        } else if (this.$store.state.order.itemData.vendor == "SENTRAL") {
          const estimateValue = parseFloat(
            this.$store.state.order.itemData.estimateValue
          );
          let totIns =
            estimateValue * INSURANCE_RATE_SENTRAL +
            INSURANCE_ADDITIONAL_COST_SENTRAL;
          return totIns > 5000 ? totIns : 5000;
        }
      }
      return 0;
      // return this.$store.state.order.itemData.insurance;
    },
    discount() {
      return Math.round(this.$store.state.order.itemData.discount);
    },
    ppn() {
      // const total = this.packingPrice + this.sendPrice - this.discount;
      // return Math.ceil(total * 0.011);
      return 0;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    totalPrice() {
      const total = this.packingPrice + this.sendPrice - this.discount;
      return total;
    },
    totalBiaya() {
      if (this.totalPrice > 0)
        return Math.ceil(this.totalPrice + this.insurance + this.ppn);
      return 0;
    },
    dfod() {
      return this.$store.state.order.itemData.dfod;
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateOrder {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px;
  }

  &-Action {
    text-align: right;
    padding: 16px;
  }

  &-Summary {
    text-align: right;
  }
}
</style>
