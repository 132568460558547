<template>
  <div class="RecapTransaction">
    <v-container class="ma-0 mb-4 d-flex">
      <v-container>
        <v-row>
          <v-col class="pa-0">
            <v-text-field
              class="mr-2"
              v-model="startDate"
              type="date"
              outlined
              label="From"
              dense
            ></v-text-field>
          </v-col>

          <v-col class="pa-0">
            <v-text-field
              v-model="endDate"
              type="date"
              outlined
              label="To"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="pa-0">
            <v-select
              outlined
              dense
              label="Hub"
              item-text="text"
              item-value="value"
              :items="hubList"
              v-model="hub"
            ></v-select>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col class="pa-0 mr-2">
            <v-btn
              depressed
              color="cyan"
              class="white--text mr-2"
              @click="search"
              >Search</v-btn
            >

            <v-btn depressed color="error" class="mr-2" @click="refresh"
              >refresh</v-btn
            >

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed color="primary" dark v-bind="attrs" v-on="on">
                  Export
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in actions"
                  :key="index"
                  @click="item.action"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="d-flex justify-end">
        <div>
          <h2>Total Transaction</h2>
          <h2>{{ totalTransaction }}</h2>
        </div>
      </v-container>
    </v-container>

    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="filteredItems"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="manifestId"
      :sort-by="sortBy"
      :sort-desc="[true]"
      show-expand
    >
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-recap-component :items="item.data" />
        </td>
      </template>
      <template v-slot:[`item.dfod`]="{ item }">
        <v-chip
          v-if="item.data[0].dfod"
          color="green"
          text-color="white"
          x-small
        >
          DFOD
        </v-chip>
        <v-chip v-else color="orange" text-color="white" x-small> CASH </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DropDownRecapComponent from "../../../components/dashboard/module/DropDownRecapComponent.vue";

import { formatToRupiah } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
// import moment from "moment";
import timezone from "moment-timezone";
import exportFromJSON from "export-from-json";

export default {
  name: "RecapTransaction",
  components: { DropDownRecapComponent },
  data() {
    return {
      isLoading: false,
      startDate: "",
      endDate: "",
      hub: "",
      hubList: [],
      expanded: [],
      sortBy: [
        "date",
        "hub",
        "origin",
        "destination",
        "colly",
        "weight",
        "transaction",
      ],
      headers: [
        { text: "Manifest Id", value: "manifestId", filterable: false },
        { text: "Date", value: "date" },
        { text: "Hub", value: "hub" },
        { text: "Origin", value: "origin", filterable: false },
        { text: "Destination", value: "destination", filterable: false },
        { text: "Order Type", value: "dfod", filterable: false },
        { text: "Total Koli", value: "colly", filterable: false },
        { text: "Total Berat", value: "weight", filterable: false },
        { text: "Transaction", value: "transaction", filterable: false },
        { text: "Info", value: "data-table-expand", filterable: false },
      ],
      items: [],
      filteredItems: [],
      actions: [
        {
          title: "Excel",
          action: this.exportToExcel,
        },
      ],
    };
  },
  methods: {
    search() {
      // console.log(this.filteredItems);
      if (this.startDate && this.endDate) {
        this.filterDate();
      }

      if (this.hub) {
        this.filterHub();
      }
    },
    refresh() {
      this.itemsFiltered = this.items;
      this.startDate = "";
      this.endDate = "";
      this.hub = "";
      this.fetchManifests();
    },
    generateList(data) {
      // this.statusList = [...new Set(data.map((item) => item.status))];
      this.hubList = [...new Set(data.map((item) => item.hub))];
    },
    filterDate() {
      this.filteredItems = this.items;

      this.filteredItems = this.filteredItems.filter((el) => {
        let startdate = timezone(new Date(this.startDate))
          .startOf("days")
          .format("x");
        let endDate = timezone(new Date(this.endDate))
          .endOf("days")
          .format("x");
        let date = timezone(new Date(el.date)).format("x");
        return date >= startdate && date <= endDate;
      });
    },
    filterHub() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.hub == this.hub;
      });
    },
    fetchManifests() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/HubManifest`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "1,2,3,4,5,6,7",
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredManifest = res.data.data.filter(
            (el) => el.IDManifest != 0
          );
          // console.log(filteredManifest);
          const items = filteredManifest.map((manifest) => {
            let transaction = 0;

            return {
              manifestId: manifest.IDManifest,
              date: timezone(manifest.Date)
                .tz("Asia/Jakarta")
                .format("YYYY-MM-DD, HH:mm"),
              hub: manifest.HubDetail.NamaHub,
              origin: manifest.KotaAsal,
              destination: manifest.KotaTujuan,
              colly: manifest.TotalKoli,
              weight: manifest.TotalBerat,
              data: manifest.Order.map((el) => {
                transaction += el.TotalBiaya;

                return {
                  orderId: el.IDOrder,
                  wbNumber: el.WBNumber,
                  colly: el.TotalKoli,
                  weight: el.TotalBerat,
                  transaction: formatToRupiah(el.TotalBiaya),
                  transactionNum: el.TotalBiaya,
                  dfod: el.Order.Dfod,
                };
              }),
              nominal: transaction,
              transaction: formatToRupiah(transaction),
            };
          });

          this.items = items;
          this.filteredItems = items;
          this.generateList(this.items);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.items = [];
          this.isLoading = false;
        });
    },
    exportToExcel() {
      console.log(this.filteredItems);
      const currentData = this.filteredItems;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        el.data.forEach((element) => {
          data.push({
            Number: number,
            Tanggal: el.date,
            "Order Id": element.orderId,
            "Waybill No.": element.wbNumber,
            "Manifest Id": el.manifestId,
            "Origin City": el.origin,
            "Destination City": el.destination,
            Koli: element.colly,
            "Berat (Kg)": element.weight,
            "Total Billing (Rp)": element.transactionNum,
          });
          number++;
        });
      });

      const fileName = "RecapTransaction";
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
  },
  created() {
    this.fetchManifests();
  },
  computed: {
    totalTransaction() {
      return formatToRupiah(
        this.filteredItems.reduce((acc, obj) => {
          return acc + obj.nominal;
        }, 0)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.RecapTransaction {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
