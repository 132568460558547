export const userType = {
  SUPERVISOR_HUB: 4,
  DATA_ENTRY_HUB: 5,
  OPERATION_HUB: 6,
};

export const userTypeList = [
  {
    value: 1,
    text: "MANAGER_HO",
  },
  {
    value: 2,
    text: "SUPER_ADMIN_HO",
  },
  {
    value: 3,
    text: "OPERATION_HO",
  },
  {
    value: 4,
    text: "SUPERVISOR_HUB",
  },
  {
    value: 5,
    text: "DATA_ENTRY_HUB",
  },
  {
    value: 6,
    text: "OPERATION_HUB",
  },
  {
    value: 7,
    text: "DRIVER",
  },
];
