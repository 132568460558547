<template>
  <div>
    <loading-component v-if="isLoading"></loading-component>

    <div v-else class="LabelDocument">
      <div>
        <div id="PrintLabelAction">
          <h4>Print Label</h4>
          <v-btn @click="print" color="warning">Print</v-btn>
        </div>

        <div class="ShippingLabel" id="Label" ref="shippinglabel">
          <div v-for="colly in 1" :key="colly">
            <table
              style="font-size: 8px !important; margin: 0px auto !important"
            >
              <tr>
                <td align="center" width="20%">
                  <h1 style="color: black; text-align: center; font-size: 16px">
                    EXPRESS
                  </h1>
                </td>
                <td width="60%">
                  <img
                    :src="trollyLogo"
                    alt="Trolly logo"
                    id="TrollyLogo"
                    width="70%"
                    style="margin: 0 auto; display: block"
                  />
                </td>

                <td align="center" width="20%">
                  DESTINATION COUNTRY
                  <h1 style="color: black; font-size: 20px">
                    {{ order.countryCode }}
                  </h1>
                </td>
              </tr>

              <tr>
                <td colspan="2" align="center">
                  <barcode
                    :value="barcodeValue"
                    text-margin="0"
                    background="none"
                    width="1"
                    height="50"
                    font-size="10"
                  >
                  </barcode>

                  <p>{{ order.date }}, {{ order.time }}</p>
                </td>

                <td align="center">
                  <qrcode-vue
                    :value="qrValue"
                    :size="60"
                    level="H"
                  ></qrcode-vue>

                  <p>reff: -</p>
                </td>
              </tr>

              <tr>
                <td colspan="2" rowspan="2" valign="top">
                  <strong>Recipient Name</strong>
                  <br />
                  <span style="font-size: 10px">
                    {{ order.recipientCity }} ({{ order.recipientName }})
                    <br />
                    {{ order.recipientAddress }}
                    <br />
                    {{ order.recipientPhone }}
                    <br />
                    {{ order.IDType }} : {{ order.IDNo }}
                  </span>
                </td>

                <td>
                  <h4>RUTE</h4>
                  <span style="font-size: 13px; font-weight: bold">
                    ID - {{ order.countryCode }}</span
                  >
                </td>
              </tr>

              <tr>
                <td>
                  <span style="font-size: 10px">
                    <strong> 1 Colly</strong>
                    <br />
                    {{ order.totalWeight }} KG
                  </span>
                </td>
              </tr>

              <tr>
                <td colspan="2" valign="top">
                  <strong>Shipper Name :</strong>
                  <span style="font-size: 10px">
                    <br />{{ order.hubOrigin }} <br />{{ phone }}
                    <br />
                  </span>
                </td>

                <td style="font-size: 10px">
                  <span>Insurance : </span>
                  <span v-if="order.isInsurance">Yes</span>
                  <span v-else>No</span>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <strong>Description:</strong><br />
                  <span style="font-size: 10px">
                    {{ order.description ? order.description : "-" }}
                    <br />
                  </span>
                </td>

                <td>
                  <h4>HUB CODE</h4>
                  <span style="font-size: 12px">
                    <h4>{{ order.hubCode }}</h4>
                  </span>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <strong>Shipping Instruction :</strong>
                  <br />
                  <span style="font-size: 10px">
                    {{ order.instruction ? order.instruction : "-" }}
                  </span>
                </td>
              </tr>

              <tr>
                <td colspan="3" align="center">
                  <img
                    :src="instructionLogo"
                    alt="Instruction Logo"
                    id="InstructionLogo"
                    width="60%"
                  />
                </td>
              </tr>
            </table>
            <hr />
            <div class="pagebreak"></div>
          </div>
        </div>

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :manual-pagination="true"
          :pdf-quality="2"
          pdf-format="a6"
          pdf-content-width="98mm"
          pdf-orientation="portrait"
          ref="html2PdfInvoice"
          :html-to-pdf-options="htmlToPdfOptions"
        >
          <section slot="pdf-content">
            <div class="sprint">
              <v-app>
                <v-main>
                  <div v-for="colly in 1" :key="colly">
                    <table
                      style="
                        width: 100%;
                        font-size: 12px !important;
                        margin-top: 5px !important;
                        margin-bottom: 5px !important;
                        margin-right: 5px !important;
                        margin-left: 5px !important;
                        font-weight: bold !important;
                      "
                    >
                      <tr>
                        <td align="center" width="20%">
                          <h1
                            style="
                              color: black;
                              text-align: center;
                              font-size: 18px;
                            "
                          >
                            EXPRESS
                          </h1>
                        </td>
                        <td width="60%">
                          <img
                            :src="trollyLogo"
                            alt="Trolly logo"
                            id="TrollyLogo"
                            width="70%"
                            style="margin: 0 auto; display: block"
                          />
                        </td>

                        <td align="center" width="20%">
                          <span style="font-size: 12px !important"
                            >DESTINATION COUNTRY</span
                          >
                          <h1 style="color: black; font-size: 20px">
                            {{ order.countryCode }}
                          </h1>
                        </td>
                      </tr>

                      <tr
                        style="padding: 0px !important; margin: 0px !important"
                      >
                        <td colspan="2" align="center" valign="top">
                          <barcode
                            :value="barcodeValue"
                            text-margin="0"
                            background="none"
                            width="1"
                            height="40"
                            font-size="12"
                          >
                          </barcode>
                          <div style="font-size: 10px; margin-top: -5px">
                            {{ order.date }}, {{ order.time }}
                          </div>
                        </td>

                        <td align="center">
                          <qrcode-vue
                            :value="qrValue"
                            :size="60"
                            level="H"
                            render-as="svg"
                          ></qrcode-vue>
                          reff: -
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2" rowspan="2" valign="top">
                          <strong>Recipient Name</strong>
                          <br />
                          <span style="font-size: 11px">
                            {{ order.recipientCity }} ({{
                              order.recipientName
                            }})
                            <br />
                            {{ order.recipientAddress }}
                            <br />
                            {{ order.recipientPhone }}
                            <br />
                            {{ order.IDType }} : {{ order.IDNo }}
                          </span>
                        </td>

                        <td>
                          <h4>Route</h4>
                          <span style="font-size: 14px; font-weight: bold">
                            ID - {{ order.countryCode }}</span
                          >
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <span style="font-size: 12px">
                            <strong> 1 Colly</strong>
                            <br />
                            {{ order.totalWeight }} KG
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2" valign="top">
                          <strong style="font-size: 10px"
                            >Shipper Name :</strong
                          >
                          <span style="font-size: 11px">
                            <br />{{ order.hubOrigin }} <br />{{ phone }}
                            <br />
                          </span>
                        </td>

                        <td>
                          <span style="font-size: 10px">Insurance : </span>
                          <div style="font-size: 12px">
                            <span v-if="order.isInsurance">Yes</span>
                            <span v-else>No</span>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <strong>Description:</strong><br />
                          <span style="font-size: 11px">
                            {{ order.description ? order.description : "-" }}
                            <br />
                          </span>
                        </td>

                        <td>
                          <h4>HUB CODE</h4>
                          <span style="font-size: 14px">
                            <h4>{{ order.hubCode }}</h4>
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="3">
                          <strong>Shipping Instruction :</strong>
                          <br />
                          <span style="font-size: 11px">
                            {{ order.instruction ? order.instruction : "-" }}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="3" align="center">
                          <img
                            :src="instructionLogo"
                            alt="Instruction Logo"
                            id="InstructionLogo"
                            width="40%"
                          />
                        </td>
                      </tr>
                    </table>
                    <div class="html2pdf__page-break" />
                  </div>
                </v-main>
              </v-app>
            </div>
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";

import jsPdf from "jspdf";
import html2canvas from "html2canvas";
import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie } from "../../utils/cookies";
import moment from "moment";
import { formatToRupiah } from "../../utils/formatter";
import trollyLogo from "../../assets/images/trolly-logo-black.svg";
import instructionLogo from "../../assets/images/instruction-logo.jpeg";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "LabelComponent",
  components: {
    barcode: VueBarcode,
    QrcodeVue,
    LoadingComponent,
    VueHtml2pdf,
  },
  data() {
    return {
      barcodeValue: "",
      trollyLogo,
      instructionLogo,
      qrValue: "",
      isLoading: true,
      order: {},
      showLabelPrint: false,
      htmlToPdfOptions: {
        margin: 0,
        DisablePdfCompression: 1,
        image: {
          type: "jpeg",
          quality: 0.95,
        },

        enableLinks: false,

        html2canvas: {
          dpi: 360,
          scale: 10,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a6",
          orientation: "portrait",
          putOnlyUsedFonts: true,
        },
      },
      phone: "",
    };
  },
  created() {
    this.fetchDetail();
  },

  methods: {
    fetchDetail() {
      const wbNumber = this.$route.query.wb;
      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrdersInter/${wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          const { ShipperLoc } = data.orderResult.Order;
          const { RecipientInter } = data.orderResult.Order;

          this.barcodeValue = data.orderResult.WBNumber.replace(/ /g, "");
          this.qrValue = data.orderResult.WBNumber.replace(/ /g, "");
          this.phone = data.phone.ValueConstant;

          this.order = {
            date: moment(data.orderResult.DateCreated).format("ll"),
            time: moment(data.orderResult.DateCreated).format("LT"),
            cityOrigin: res.data.data.orderResult.CodeCountry,
            itemDetails: data.orderResult.Order.ItemDetails,
            hubOrigin: data.orderResult.Order.Hub.NamaHub,
            hubCode: data.orderResult.Order.Hub.IDHub,
            hubPhone: data.orderResult.Order.Hub.Telp,
            collies: data.orderResult.InboundCollies[0],
            packingPrice: formatToRupiah(data.orderResult.TotalBiayaPacking),
            price: formatToRupiah(data.orderResult.TotalBiaya),
            totalPrice: formatToRupiah(
              data.orderResult.TotalBiayaPacking + data.orderResult.TotalBiaya
            ),
            totalWeight: data.orderResult.TotalBerat,
            totalColly: data.orderResult.TotalKoli,
            wbNumber: data.orderResult.WBNumber,
            description: data.orderResult.Order.Keterangan,
            instruction: data.orderResult.Order.Instruction,
            shipperName: ShipperLoc.Nama,
            shipperAddress: `${ShipperLoc.Alamat}, Kel. ${ShipperLoc.NamaKelurahan}, Kec. ${ShipperLoc.NamaKecamatan}, ${ShipperLoc.NamaCity}, ${ShipperLoc.KodePos}`,
            shipperPhone: ShipperLoc.Telp,
            shipperPostCode: ShipperLoc.KodePos,
            recipientCountry: RecipientInter.Country.CountryName.toUpperCase(),
            countryCode: RecipientInter.Country.CodeCountry.toUpperCase(),
            recipientName: RecipientInter.Nama,
            recipientAddress: `${RecipientInter.Alamat}`,
            recipientPhone: RecipientInter.Telp,
            recipientPostCode: RecipientInter.KodePos,
            isInsurance: data.orderResult.Order.IsInsurance,
            insurance: formatToRupiah(data.orderResult.Order.Insurance),
            estimasiNilai: formatToRupiah(data.orderResult.EstimasiNilai),
            IDType: RecipientInter.IDType,
            IDNo: RecipientInter.IDNo,
          };
          this.isLoading = false;
          // let self = this;

          // setTimeout(function () {
          //   self.print();
          // }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    generatePdf() {
      window.html2canvas = html2canvas;
      const doc = new jsPdf("p", "pt", "a6");
      doc.html(document.querySelector("#Label"), {
        callback: function (pdf) {
          pdf.save("label.pdf");
        },
      });
    },
    async print() {
      try {
        window.scrollTo({ top: 0 });
        this.$refs.html2PdfInvoice.generatePdf();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" media="print">
.html2pdf__page-break {
  margin: 0 !important;
}
.LabelDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintLabelAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }

  .ShippingLabel {
    margin: 0 auto;
    width: 10cm;
    background-color: white;

    #TrollyLogo {
      width: 10rem;
    }

    #InstructionLogo {
      width: 20rem;
      height: 4rem;
    }

    h4,
    h3,
    p {
      margin: 0;
      padding: 0;
    }

    table {
      border-spacing: 0;
      margin: 2px;
    }

    hr {
      border-top: 1px dashed black;
      margin: 5px auto;
    }

    td {
      border: 1px solid black;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    &--left {
      display: flex;
      flex-direction: column;
      text-align: start;
      padding: 5px;
    }

    &--center {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
    }

    .Identifier {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
    }
  }
}

.sprint {
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px !important;
  }
}

@media print {
  .ShippingLabel {
    background-color: white;
  }
  #PrintLabelAction {
    visibility: hidden;
  }

  #Label {
    size: a6 !important;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-0%);
    margin: 0 auto;
  }

  .Label {
    size: a6 !important;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-0%);
    margin: 0 auto;
  }

  @page {
    size: auto;
    // size: 100mm 150mm;
    margin: 0mm;
  }
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}

@page {
  // margin: 0 auto;
  // size: a6;
  // size: auto;
  // size: 1800px 3600px;
  // margin: 25mm 25mm 25mm 25mm;
  width: 98mm;
  height: 148mm;
}

body {
  margin: 0px;
}
</style>
