<template>
  <div class="Home">
    <div class="Home-Image">
      <img :src="shippingLogo" alt="shipping logo" />
    </div>
    <div class="Home-Container">
      <h2>Welcome to Trolly Hub</h2>
      <br />
      <LoginComponent
        v-if="type == 'login'"
        @changeType="changeAuthType($event)"
      />

      <RegisterComponent
        v-else-if="type == 'register'"
        @changeType="changeAuthType($event)"
        @saveData="saveRegisterData($event)"
      />

      <OtpComponent
        v-else-if="type == 'otp'"
        :phone="phone"
        :password="password"
        @changeType="changeAuthType($event)"
      />
    </div>
  </div>
</template>

<script>
import LoginComponent from "@/components/authentication/LoginComponent.vue";
import RegisterComponent from "@/components/authentication/RegisterComponent.vue";
import shippingImage from "../assets/images/shipping-logo.jpg";
import OtpComponent from "@/components/authentication/OtpComponent.vue";

export default {
  name: "Home",
  components: {
    LoginComponent,
    RegisterComponent,
    OtpComponent,
  },
  data() {
    return {
      type: "login",
      phone: "",
      password: "",
      shippingLogo: shippingImage,
    };
  },
  methods: {
    changeAuthType(e) {
      this.type = e;
    },
    saveRegisterData(e) {
      this.phone = e.phone;
      this.password = e.password;
    },
  },
};
</script>

<style lang="scss" scoped>
.Home {
  width: 100%;
  height: 100vh;
  background-color: #f8feff;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  display: grid;
  grid-template-columns: 1fr auto;

  &-Image {
    align-self: center;
    padding-left: 40px;
    img {
      width: 100%;
    }
  }

  &-Container {
    margin: 40px;
    align-self: center;
    text-align: center;
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 1px 2px 5px rgba(128, 128, 128, 0.411);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
