<template>
  <div class="DialogAddItemComponent">
    <v-dialog v-model="dialogAddItem" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          block
          depressed
          color="success"
          v-bind="attrs"
          v-on="on"
          :disabled="packageCategory"
        >
          Tambah
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add Item </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Deskripsi Barang"
              :rules="requiredRule"
              outlined
              dense
              v-model="colly.descriptionItem"
            ></v-text-field>

            <v-text-field
              label="Jumlah Koli"
              type="number"
              :rules="rule.minNumber"
              outlined
              dense
              v-model="colly.numberColly"
            ></v-text-field>

            <v-text-field
              label="Berat, Kg"
              type="number"
              :rules="rule.minNumber"
              outlined
              dense
              v-model="colly.weight"
            ></v-text-field>

            <v-text-field
              label="Panjang, Cm"
              type="number"
              :rules="rule.minNumber"
              outlined
              dense
              v-model="colly.length"
            ></v-text-field>

            <v-text-field
              label="Lebar, Cm"
              type="number"
              :rules="rule.minNumber"
              outlined
              dense
              v-model="colly.width"
            ></v-text-field>

            <v-text-field
              label="Tinggi, Cm"
              type="number"
              :rules="rule.minNumber"
              outlined
              dense
              v-model="colly.height"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="dialogAddItem = false">
            cancel
          </v-btn>
          <v-btn color="cyan" class="white--text" depressed @click="addColly">
            add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  calculateVolumetricWeight,
  calculateVolumetricWeightJTR,
  calculateVolumetricWeightUdara,
} from "../../../utils/calculation";

export default {
  name: "DialogAddItemComponent",
  props: {
    resetListPrice: { type: Function },
    packageCategory: { type: Boolean },
  },
  data() {
    return {
      dialogAddItem: false,
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      rule: {
        minNumber: [
          (value) => !!value || "Required",
          (v) => v > 0 || "Minimal Number is 1",
        ],
      },
      snackbar: false,
      snackbarText: "",
      colly: {
        numberColly: 1,
        weight: "",
        length: "",
        width: "",
        height: "",
        volumeWeight: 0,
        isPacking: false,
        packingPrice: 0,
        mostWeight: 0,
        totalPrice: 0,
        descriptionItem: "",
      },
    };
  },
  computed: {
    moda() {
      return this.$store.state.order.itemData.moda;
    },
  },
  methods: {
    async addColly() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        let volumeWeight, mostWeight;
        const collies = [...this.$store.state.order.itemData.collies];
        const weight = Number(this.colly.weight);

        if (this.moda == "Darat") {
          if (this.$store.state.order.itemData.vendor == "JNE") {
            volumeWeight = calculateVolumetricWeightJTR(
              this.colly.length,
              this.colly.width,
              this.colly.height
            );
          } else {
            volumeWeight = calculateVolumetricWeight(
              this.colly.length,
              this.colly.width,
              this.colly.height
            );
          }

          mostWeight = volumeWeight > weight ? volumeWeight : weight;
        } else {
          volumeWeight = calculateVolumetricWeightUdara(
            this.colly.length,
            this.colly.width,
            this.colly.height
          );
          let isSurcharge = volumeWeight > weight ? volumeWeight : weight;
          mostWeight = isSurcharge > 50 ? isSurcharge * 2 : isSurcharge;
        }

        collies.push({
          numberColly: Number(this.colly.numberColly),
          weight,
          length: Number(this.colly.length),
          width: Number(this.colly.width),
          height: Number(this.colly.height),
          volumeWeight,
          isPacking: false,
          packingPrice: 0,
          mostWeight,
          totalPrice: 0,
          descriptionItem: this.colly.descriptionItem,
        });

        this.valid = this.$refs.form.reset();
        this.dialogAddItem = false;
        this.$store.commit("order/SET_ITEM", { collies });
        this.$store.commit("order/SET_ITEM", {
          kargoPrice: 0,
        });
        this.resetListPrice();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
