export const orderModule = {
  namespaced: true,
  state: () => ({
    isWalkin: true,
    idOrder: "",
    idShipper: "",
    idRecipient: "",
    idCollies: [],
    shipperData: {
      shipperName: "",
      shipperPhone: "",
      shipperEmail: "",
      shipperCity: "",
      shipperCityId: "",
      shipperKelurahan: "",
      shipperKecamatan: "",
      shipperKecamatanId: "",
      shipperKelurahanId: "",
      shipperPostCode: "",
      shipperAddress: "",
      shipperIDHub: "",
      newShipper: true,
    },
    recipientData: {
      recipientName: "",
      recipientPhone: "",
      recipientEmail: "",
      recipientCity: "",
      recipientCityId: "",
      recipientKelurahan: "",
      recipientNamaKelurahan: "",
      recipientNamaKecamatan: "",
      recipientKecamatanId: "",
      recipientKelurahanId: "",
      recipientPostCode: "",
      recipientAddress: "",
      recipientIDHub: "",
      recipientContactPerson: "",
      recipientIDCountry: "",
      recipientCountryName: "",
      newRecipient: true,
      idType: "",
      idNo: "",
    },
    itemData: {
      moda: "Darat",
      description: "",
      instruction: "",
      discount: 0,
      tax: 0,
      estimateValue: 0,
      collies: [],
      itemsDetail: [],
      totalWeight: 0,
      packingPrice: 0,
      kargoPrice: 0,
      totalPrice: 0,
      isInsurance: true,
      insurance: 0,
      itemValue: "IDR",
      pebHardCopy: "NO",
      paymentType: "PREEPAID",
      kategoryPacket: [],
      shipmentType: "AG02JKT001",
      etdMin: 0,
      etdMax: 0,
      price3PL: 0,
      resi3pl: "",
      packageCategory: "",
      vendor: "",
      dfod: false,
      etd: "",
      nameSurcharge: "",
      costSurcharge: 0,
      descSurcharge: "",
      addCostTotal: 0,
      additionalCost: [
        {
          typeAdditional: "",
          costAdditional: 0,
        },
      ],
      listPrice: [],
      prefixOrder: "",
      service: "",
    },
    log: {
      request: [],
      response: [],
    },
  }),
  mutations: {
    SET_SHIPPER(state, payload) {
      state.shipperData = { ...state.shipperData, ...payload };
    },
    SET_RECIPIENT(state, payload) {
      state.recipientData = { ...state.recipientData, ...payload };
    },
    SET_ITEM(state, payload) {
      state.itemData = { ...state.itemData, ...payload };
    },
    SET_WALKIN(state, payload) {
      state.isWalkin = payload.isWalkin;
      state.idOrder = payload.idOrder;
      state.idShipper = payload.idShipper;
      state.idRecipient = payload.idRecipient;
      state.idCollies = payload.idCollies;
    },
    CLEAR_DATA(state) {
      state.isWalkin = true;
      state.idOrder = "";
      state.idShipper = "";
      state.idRecipient = "";
      state.idCollies = [];
      state.shipperData = {
        shipperName: "",
        shipperPhone: "",
        shipperEmail: "",
        shipperCity: "",
        shipperCityId: "",
        shipperKelurahan: "",
        shipperKecamatan: "",
        shipperKecamatanId: "",
        shipperKelurahanId: "",
        shipperPostCode: "",
        shipperAddress: "",
        shipperIDHub: "",
        newShipper: true,
        citySNI: "",
      };
      state.recipientData = {
        recipientName: "",
        recipientPhone: "",
        recipientEmail: "",
        recipientCity: "",
        recipientCityId: "",
        recipientKelurahan: "",
        recipientKecamatanId: "",
        recipientKelurahanId: "",
        recipientPostCode: "",
        recipientAddress: "",
        recipientIDHub: "",
        recipientContactPerson: "",
        recipientIDCountry: "",
        recipientCountryName: "",
        newRecipient: true,
      };
      state.itemData = {
        moda: "Darat",
        description: "",
        instruction: "",
        discount: 0,
        tax: 0,
        estimateValue: 0,
        collies: [],
        itemsDetail: [],
        totalWeight: 0,
        packingPrice: 0,
        kargoPrice: 0,
        totalPrice: 0,
        isInsurance: true,
        insurance: 0,
        itemValue: "IDR",
        pebHardCopy: "NO",
        paymentType: "PREEPAID",
        kategoryPacket: [],
        shipmentType: "AG02JKT001",
        etdMin: 0,
        etdMax: 0,
      };
    },
    CLEAR_SHIPPER(state) {
      state.shipperData = {
        shipperName: "",
        shipperPhone: "",
        shipperEmail: "",
        shipperCity: "",
        shipperCityId: "",
        shipperKelurahan: "",
        shipperKecamatan: "",
        shipperKecamatanId: "",
        shipperKelurahanId: "",
        shipperPostCode: "",
        shipperAddress: "",
        shipperIDHub: "",
      };
    },
    CLEAR_RECIPIENT(state) {
      state.recipientData = {
        recipientName: "",
        recipientPhone: "",
        recipientEmail: "",
        recipientCity: "",
        recipientCityId: "",
        recipientKelurahan: "",
        recipientKecamatanId: "",
        recipientKelurahanId: "",
        recipientPostCode: "",
        recipientAddress: "",
        recipientIDHub: "",
        recipientContactPerson: "",
        recipientIDCountry: "",
        recipientCountryName: "",
      };
    },
    CLEAR_RECIPIENT_INFORMATION(state) {
      state.isWalkin = true;
      state.idOrder = "";
      state.idShipper = "";
      state.idRecipient = "";
      state.idCollies = [];
      state.recipientData = {
        recipientName: "",
        recipientPhone: "",
        recipientEmail: "",
        recipientCity: "",
        recipientCityId: "",
        recipientKelurahan: "",
        recipientKecamatanId: "",
        recipientKelurahanId: "",
        recipientPostCode: "",
        recipientAddress: "",
      };
      state.itemData = {
        moda: "Darat",
        description: "",
        instruction: "",
        discount: 0,
        tax: 0,
        estimateValue: 0,
        collies: [],
        totalWeight: 0,
        packingPrice: 0,
        kargoPrice: 0,
        totalPrice: 0,
        isInsurance: true,
        insurance: 0,
      };
    },
  },
  actions: {},
  getters: {},
};
