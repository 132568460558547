export function formatToRupiah(number) {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(number);
}

export function formatPhone(phone) {
  const isStartWithZero = phone.substr(0, 1) == "0";
  const isStartWithCode = phone.substr(0, 3) == "+62";

  if (isStartWithZero) {
    return `+62${phone.substr(1)}`;
  }

  if (isStartWithCode) {
    return phone;
  }

  return `+62${phone}`;
}
