<template>
  <div class="PackingList">
    <div class="PackingList-Option">
      <v-container class="ma-0">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="search ma-0"
          @submit.prevent="fetchPackingList"
        >
          <v-container class="ma-0 mb-4">
            <v-row>
              <v-col sm="4" class="pa-0">
                <v-text-field
                  v-model="fromDate"
                  type="date"
                  outlined
                  label="from"
                  dense
                ></v-text-field>
              </v-col>

              <v-col sm="4" class="pa-0 mx-4">
                <v-text-field
                  v-model="toDate"
                  type="date"
                  outlined
                  label="to"
                  dense
                ></v-text-field>
              </v-col>

              <v-col sm="4" class="pa-0">
                <v-text-field
                  label="Search City"
                  outlined
                  dense
                  v-model="city"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0">
                <v-btn
                  depressed
                  color="cyan"
                  class="mr-2 white--text"
                  type="submit"
                >
                  Search
                </v-btn>
                <v-btn depressed color="error" @click="refresh">refresh</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>

      <v-container class="ma-0">
        <v-row id="button">
          <v-col sm="4" class="pa-0">
            <v-btn
              depressed
              class="mr-2"
              small
              color="success"
              @click="bulkPrint"
            >
              {{ isShowCheckbox ? "Bulk Print" : "Print Packing List" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by="sortBy"
      :sort-desc="[true]"
      :loading="isLoading"
      item-key="packingId"
      v-model="selected"
      :show-select="isShowCheckbox"
    >
    </v-data-table>

    <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
      :value="showAlert"
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import moment from "moment";

export default {
  name: "PackingList",
  data() {
    return {
      valid: false,
      sortBy: [
        // "packingId",
        "date",
        "destination",
        "totalColly",
        "totalWeight",
        // "status",
      ],
      isLoading: false,
      city: "",
      toDate: "",
      fromDate: "",
      headers: [
        {
          text: "Packing Id",
          value: "packingId",
          filterable: false,
        },
        { text: "Date", value: "date", filterable: false },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
        // { text: "Status", value: "status", filterable: false },
        // { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      selected: [],
      isShowCheckbox: false,
      showAlert: false,
      message: "",
    };
  },
  methods: {
    view() {
      // console.log(item);
    },
    bulkPrint() {
      if (!this.isShowCheckbox) {
        this.isShowCheckbox = true;
        return;
      }

      if (this.selected.length) {
        const data = this.selected;
        this.$router.push({
          name: "packingPrint", //use name for router push
          params: { data },
        });
      } else {
        this.showAlert = true;
        this.message = "Pilih packing list terlebih dahulu!";

        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      }
    },
    refresh() {
      this.fromDate = "";
      this.toDate = "";
      this.city = "";
      this.fetchPackingList();
    },
    fetchPackingList() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      let query = ``;

      if (this.fromDate) query += `?fromDate=${this.fromDate}`;
      if (this.fromDate && this.toDate) query += `&toDate=${this.toDate}`;
      if (this.fromDate && this.city) {
        query += `&city=${this.city}`;
      } else if (this.city) query += `?city=${this.city}`;

      // console.log(query);
      axios({
        method: "get",
        url: `${baseUrl}/PackingList` + query ?? "",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredData = res.data.data.filter(
            (el) => el.IDPackingList != 0
          );

          this.items = filteredData.map((packingList) => {
            return {
              packingId: packingList.IDPackingList,
              date: moment(packingList.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              destination: packingList.KotaTujuan,
              totalColly: packingList.TotalKoli,
              totalWeight: packingList.TotalBerat,
              details: packingList.InboundOrder,
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchPackingList();
  },
};
</script>

<style lang="scss" scoped>
.PackingList {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
  width: 100%;

  &-Option {
    display: flex;
    justify-content: space-between;

    .container {
      #button {
        justify-content: end;
      }
    }

    .search {
      display: flex;
    }
  }
}
</style>
