export const INSURANCE_RATE = 0.0025;
export const INSURANCE_ADDITIONAL_COST = 5000;
// SENTRAL
export const INSURANCE_RATE_SENTRAL = 0.0025;
export const INSURANCE_ADDITIONAL_COST_SENTRAL = 1000;
export const PACKING_WOOD_SENTRAL = 4500;

//INTERNATIONAL
export const INSURANCE_RATE_INTERNATIONAL = 0.0055;
export const INSURANCE_ADDITIONAL_COST_INTERNATIONAL = 5000;

//ADDITIONAL PACKING WOOD
export const ADDITIONAL_LENGTH = 15;
export const ADDITIONAL_WIDTH = 10;
export const ADDITIONAL_HEIGHT = 10;
