<template>
  <div class="RecipientFormComponent">
    <div class="RecipientFormComponent-Header">
      <h3>
        Recipient Information
        <!-- <v-btn
          v-if="!readOnly"
          @click="dialog = true"
          color="primary"
          elevation="2"
          x-small
          >Cari Recipient</v-btn
        >
        <v-btn
          v-if="readOnly"
          @click="reset"
          color="warning"
          class="ml-1"
          elevation="2"
          x-small
          >reset</v-btn
        > -->
      </h3>

      <hr />
    </div>

    <div class="RecipientFormComponent-Container">
      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0">
            <h4>Nama Penerima</h4>
          </v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rule.namaPenerima"
              :value="recipientName"
              @input="setRecipientName"
              outlined
              dense
              :readonly="readOnly"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>No. Handphone</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rule.nohp"
              :value="recipientPhone"
              @input="setRecipientPhone"
              outlined
              dense
              type="number"
              :readonly="readOnly"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Email</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :value="recipientEmail"
              @input="setRecipientEmail"
              outlined
              dense
              :readonly="readOnly"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kota</h4></v-col>

          <v-col cols="8" class="pa-0">
            <!-- <v-text-field :rules="rules" outlined dense></v-text-field>
             -->
            <v-combobox
              outlined
              dense
              :rules="rules"
              :items="cityList"
              :value="city"
              @input="setCity"
              :readonly="readOnly"
            ></v-combobox>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col class="pa-0">
            <v-checkbox label="Tambah ke address book"></v-checkbox>
          </v-col>
        </v-row> -->
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kelurahan</h4></v-col>

          <v-col cols="8" class="pa-0">
            <!-- <v-text-field :rules="rules" outlined dense></v-text-field>
             -->
            <v-combobox
              outlined
              dense
              :rules="rules"
              :items="villageList"
              :value="village"
              @input="setVillage"
              :readonly="readOnly"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Kode Pos</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-text-field
              :rules="rules"
              :value="postCode"
              @input="setPostCode"
              outlined
              dense
              disabled
              :readonly="readOnly"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pa-0"> <h4>Alamat</h4></v-col>

          <v-col cols="8" class="pa-0">
            <v-textarea
              :rules="rule.alamat"
              :value="address"
              @input="setAddress"
              outlined
              rows="3"
              no-resize
              :readonly="readOnly"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Shipper</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4" class="pa-0">
                <h4>Nama Penerima</h4>
              </v-col>

              <v-col cols="8" class="pa-0">
                <v-combobox
                  @input="setRecipientAuto"
                  :items="recipientData"
                  item-text="Nama"
                  item-value="IDShipper"
                  outlined
                  dense
                  :loading="false"
                  return-object
                  :rules="rules"
                  hint="Maximum of 30 Character"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { getUserProfile } from "../../../services/user";
import { mapState, mapActions } from "vuex";

export default {
  name: "RecipientFormComponent",
  data() {
    return {
      rules: [(value) => !!value || "Required"],
      rule: {
        nohp: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 15 || "Maxsimal input 15 characters",
        ],
        namaPenerima: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 30 || "Maxsimal input 30 characters",
        ],
        alamat: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 85 || "Maxsimal input 85 characters",
        ],
      },
      namaPenerima: "",
      dialog: false,
      readOnly: false,
      KabupatenName: "",
      originKec: "",
    };
  },
  computed: {
    ...mapState({
      recipientData: (state) => state.shipper.recipientData,
    }),
    recipientName() {
      return this.$store.state.order.recipientData.recipientName;
    },
    recipientPhone() {
      return this.$store.state.order.recipientData.recipientPhone;
    },
    recipientEmail() {
      return this.$store.state.order.recipientData.recipientEmail;
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    city() {
      return this.$store.state.order.recipientData.recipientCity;
    },
    cityId() {
      return this.$store.state.order.recipientData.recipientCityId;
    },
    villageList() {
      let villageArray = [];
      if (this.cityId) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.cityId;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
    village() {
      return this.$store.state.order.recipientData.recipientKelurahan;
    },
    villageId() {
      return this.$store.state.order.recipientData.recipientKelurahanId;
    },
    postCode() {
      return this.$store.state.order.recipientData.recipientPostCode;
    },
    address() {
      return this.$store.state.order.recipientData.recipientAddress;
    },
    newRecipient() {
      return this.$store.state.order.recipientData.newRecipient;
    },
  },
  created() {},
  async mounted() {
    const profile = await getUserProfile();
    // console.log(profile);
    this.IDHub = profile.KCX_HubIDHub;
    //get recipient data
    await this.getHubRecipient({
      IDHub: this.IDHub,
      Type: "domestik",
    });
    this.$store.commit("order/SET_RECIPIENT", {
      recipientIDHub: this.IDHub,
    });
    // console.log(this.recipientData);
  },
  watch: {},
  methods: {
    ...mapActions({
      getHubRecipient: "shipper/getHubRecipient",
    }),
    setRecipientAuto(e) {
      if (e.Telp) {
        this.namaPenerima = e.Nama;
        this.$store.commit("order/SET_RECIPIENT", { recipientName: e.Nama });
        this.setRecipientPhone(e.Telp);
        this.setRecipientEmail(e.Email);
        this.setAddress(e.Alamat);
        this.$store.commit("order/SET_RECIPIENT", {
          recipientCityId: e.CityIDCity,
          recipientCity: e.Kota,
        });
        const village = villageData.find(
          ({ idKelurahan }) => idKelurahan == e.KelurahanIDKelurahan
        );
        if (village) {
          this.$store.commit("order/SET_RECIPIENT", {
            recipientKelurahanId: village.idKelurahan,
            recipientKecamatanId: village.idKecamatan,
            recipientKelurahan: village.kecamatan + "," + village.kelurahan,
            recipientNamaKelurahan: village.kelurahan,
            recipientPostCode: village.kodePos,
            recipientNamaKecamatan: village.kecamatan,
          });
        }
        this.dialog = false;
        this.$store.commit("order/SET_RECIPIENT", { newRecipient: false });
        this.readOnly = true;
      } else {
        this.$store.commit("order/SET_RECIPIENT", { recipientName: e });
      }
      // this.$store.commit("order/SET_RECIPIENT", {
      //   recipientIDHub: this.IDHub,
      // });
    },
    setRecipientName(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientName: e });
    },
    setRecipientPhone(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientPhone: e });
    },
    setRecipientEmail(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientEmail: e });
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);
      // console.log(city);
      if (city) {
        this.$store.commit("order/SET_RECIPIENT", {
          recipientCityId: city.idCity,
          recipientCity: e,
        });
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtname = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtname
      );

      if (village) {
        this.$store.commit("order/SET_RECIPIENT", {
          recipientKelurahanId: village.idKelurahan,
          recipientKecamatanId: village.idKecamatan,
          recipientKelurahan: e,
          recipientNamaKelurahan: village.kelurahan,
          recipientPostCode: village.kodePos,
          recipientNamaKecamatan: village.kecamatan,
        });
      }
    },
    setPostCode(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientPostCode: e });
    },
    setAddress(e) {
      this.$store.commit("order/SET_RECIPIENT", { recipientAddress: e });
    },
    reset() {
      this.readOnly = false;
      this.$store.commit("order/SET_RECIPIENT", { newRecipient: true });

      this.$store.commit("order/CLEAR_RECIPIENT");
    },
  },
};
</script>

<style lang="scss" scoped>
.RecipientFormComponent {
  padding: 0 20px;

  hr {
    margin: 16px auto;
  }
  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
