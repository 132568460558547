<template>
  <div class="DashboardHome d-flex flex-column justify-center items-center">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6">
          <a href="create-order" style="text-decoration: none">
            <v-hover v-slot:default="{ hover }">
              <v-card class="mx-auto" max-width="344">
                <v-img :src="domestic" height="200px"></v-img>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute style="z-index: 0">
                    <h1>domestic</h1>
                  </v-overlay>
                </v-fade-transition>
                <v-card-title class="text-h6"> domestic </v-card-title>
              </v-card>
            </v-hover>
          </a>
        </v-col>

        <v-col cols="12" sm="6">
          <a href="create-order-international" style="text-decoration: none">
            <v-hover v-slot:default="{ hover }">
              <v-card class="mx-auto" max-width="344">
                <v-img :src="international" height="200px"></v-img>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute style="z-index: 0">
                    <h1>International</h1>
                  </v-overlay>
                </v-fade-transition>
                <v-card-title class="text-h6"> International </v-card-title>
              </v-card>
            </v-hover>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import domestic from "../../../assets/images/domestic.png";
import international from "../../../assets/images/international.jpg";
export default {
  name: "Order",
  data() {
    return {
      domestic,
      international,
    };
  },
};
</script>

<style lang="scss" scoped>
.DashboardHome {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}
</style>
