<template>
  <div class="Outgoing">
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :sort-desc="[true]"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="manifestId"
      :sort-by="sortBy"
      show-expand
    >
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-outgoing-component :items="item.data" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DropDownOutgoingComponent from "../../../components/dashboard/delivery/DropDownOutgoingComponent.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import moment from "moment";

export default {
  components: { DropDownOutgoingComponent },
  name: "Outgoing",
  data() {
    return {
      orderId: "",
      isLoading: false,
      sortBy: ["date"],
      expanded: [],
      headers: [
        { text: "DRS Id", value: "drsId", filterable: false },
        { text: "Date", value: "date", filterable: false },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
        { text: "Info", value: "data-table-expand", filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    fetchOrder() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        url: `${baseUrl}/HubDrs`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredData = res.data.data.filter((el) => el.IDDrs != 0);

          this.items = filteredData.map((drs) => {
            return {
              drsId: drs.IDDrs,
              date: moment(drs.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),

              totalColly: drs.InboundOrders.reduce((acc, obj) => {
                return acc + obj.TotalKoli;
              }, 0),
              totalWeight: drs.InboundOrders.reduce((acc, obj) => {
                return acc + obj.TotalBerat;
              }, 0),
              data: drs.InboundOrders.map((el) => {
                return {
                  orderId: el.OrderIDOrder,
                  wbNumber: el.WBNumber,
                  colly: el.TotalKoli,
                  weight: el.TotalBerat,
                  status: el.PackageStatus.Status,
                };
              }),
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchOrder();
  },
};
</script>

<style lang="scss" scoped>
.Outgoing {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
