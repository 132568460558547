<template>
  <div class="DialogConfirmOrderComponent">
    <v-dialog v-model="dialogConfirmOrder" persistent max-width="600px">
      <v-card>
        <v-card-title> Scan QRCode </v-card-title>

        <v-card-text>
          <v-container>
            <StreamBarcodeReader
              @decode="(a, b, c) => onDecode(a, b, c)"
              @loaded="() => onLoaded()"
              ref="scanner"
            ></StreamBarcodeReader>
            Input Value: {{ text || "Nothing" }}</v-container
          >
        </v-card-text>

        <v-card-actions>
          <v-btn color="cyan" outlined @click="toggleDialog"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "HelloWorld",
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      dialogConfirmOrder: false,
      text: "",
      id: null,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    toggleDialog() {
      this.dialogConfirmOrder = !this.dialogConfirmOrder;
    },
    onDecode(a) {
      // console.log(a);
      this.$emit("valueScan", a);
      this.text = a;
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = "";
        }
      }, 5000);
      // this.$refs.scanner.codeReader.stream
      //   .getTracks()
      //   .forEach(function (track) {
      //     console.log(track);
      //     track.stop();
      //   });
      this.toggleDialog();
    },
    onLoaded() {
      console.log("load");
    },
  },
};
</script>
<style scoped></style>
