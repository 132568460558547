<template>
  <div class="DialogAssignDriverComponent">
    <v-dialog v-model="dialogAssignDriver" persistent max-width="600px">
      <v-card>
        <v-card-title> Assign Driver </v-card-title>

        <v-card-text>
          <v-data-table :headers="headers" :items="items"> </v-data-table>

          <br />

          <v-form class="Form">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-select
                    :items="items"
                    label="Pilih Driver"
                    item-text="name"
                    item-value="driverId"
                    v-model="driverId"
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn
            :loading="isLoading"
            color="cyan"
            class="white--text"
            dense
            depressed
            @click="assignDriver"
          >
            assign driver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogAssignDriverComponent",
  props: ["driverType", "orderDetail"],
  data() {
    return {
      isLoading: false,
      dialogAssignDriver: false,
      driverId: null,
      snackbar: false,
      snackbarText: "",
      headers: [
        { text: "Id", value: "driverId" },
        { text: "Name", value: "name", filterable: false },
        { text: "Phone", value: "phone", filterable: false },
        { text: "Status", value: "status" },
      ],
      items: [],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogAssignDriver = !this.dialogAssignDriver;
    },
    assignDriver() {
      if (this.driverId === null) {
        this.snackbar = true;
        this.snackbarText = "Pick driver";
        return;
      }

      if (this.driverType == "pickUp") {
        this.assignPickup();
      } else {
        this.assignDeliver();
      }
    },
    fetchDriver() {
      const hubId = getProfileCookie().KCX_HubIDHub;
      let endPoint =
        this.driverType == "pickUp" ? "DriverPickup" : "DriverDeliver";

      axios({
        method: "get",
        url: `${baseUrl}/${endPoint}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filterData = res.data.data.filter((el) => el.IDDriver != 0);

          this.items = filterData.map((el) => {
            return {
              driverId: el.IDDriver,
              name: el.Nama,
              phone: el.Telp,
              status: el.User.Status,
            };
          });
        })
        .catch(() => {
          this.hubList = [];
        });
    },
    assignPickup() {
      this.isLoading = true;
      axios({
        method: "post",
        url: `${baseUrl}/AssignPickup`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          idDriver: this.driverId,
          idOrder: this.orderDetail.idOrder,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.toggleDialog();
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.$emit("refresh");
        })
        .catch((err) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
    assignDeliver() {
      this.isLoading = true;
      axios({
        method: "post",
        url: `${baseUrl}/AssignDeliver`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          idDriver: this.driverId,
          idDrs: this.orderDetail.drsId,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.toggleDialog();
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.$emit("refresh");
        })
        .catch((err) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
  created() {
    this.fetchDriver();
  },
};
</script>

<style lang="scss" scoped></style>
