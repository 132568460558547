<template>
  <div class="DialogBulkColyComponent">
    <v-dialog v-model="dialogAddItem" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="resetForm()"
          block
          depressed
          color="warning"
          v-bind="attrs"
          v-on="on"
          :disabled="packageCategory"
        >
          Upload Bulk
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          Upload File Csv |
          <small
            ><a href="/files/koli.csv" target="_blank"
              >Download Template</a
            ></small
          ></v-card-title
        >
        <br />

        <v-card-text>
          <v-form ref="form">
            <v-file-input
              ref="fileupload"
              accept=".csv"
              label="Upload Data Koli"
              @change="onFileChange"
              :clearable="true"
              v-model="filecsv"
            ></v-file-input>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="dialogAddItem = false">
            cancel
          </v-btn>
          <v-btn color="cyan" class="white--text" depressed @click="addBulk">
            add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top color="red">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  calculateVolumetricWeight,
  calculateVolumetricWeightUdara,
} from "../../../utils/calculation";

export default {
  name: "DialogAddItemComponent",
  props: {
    resetListPrice: { type: Function },
    packageCategory: { type: Boolean },
  },
  data() {
    return {
      dialogAddItem: false,
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      rule: {
        minNumber: [
          (value) => !!value || "Required",
          (v) => v > 0 || "Minimal Number is 1",
        ],
      },
      snackbar: false,
      snackbarText: "",
      colly: [],
      fileinput: "",
      filecsv: null,
    };
  },

  methods: {
    resetForm() {
      this.filecsv = null;
    },
    async addBulk() {
      try {
        this.colly.map((colly) => {
          if (colly.weight) {
            this.addColly(colly);
          }
        });
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      }
    },
    async addColly(colly) {
      let volumeWeight, mostWeight;
      const collies = [...this.$store.state.order.itemData.collies];
      const weight = Number(colly.weight);

      if (this.moda == "Darat") {
        volumeWeight = calculateVolumetricWeight(
          colly.length,
          colly.width,
          colly.height
        );
        mostWeight = volumeWeight > weight ? volumeWeight : weight;
      } else {
        volumeWeight = calculateVolumetricWeightUdara(
          colly.length,
          colly.width,
          colly.height
        );
        let isSurcharge = volumeWeight > weight ? volumeWeight : weight;
        mostWeight = isSurcharge > 50 ? isSurcharge * 2 : isSurcharge;
      }

      collies.push({
        numberColly: Number(colly.numberColly),
        weight,
        length: Number(colly.length),
        width: Number(colly.width),
        height: Number(colly.height),
        volumeWeight,
        isPacking: false,
        packingPrice: 0,
        mostWeight,
        totalPrice: 0,
        descriptionItem: colly.descriptionItem,
      });

      this.dialogAddItem = false;
      this.$store.commit("order/SET_ITEM", { collies });
      this.$store.commit("order/SET_ITEM", {
        kargoPrice: 0,
      });
      this.resetListPrice();
    },
    onFileChange(e) {
      if (e.type.match("text/csv")) {
        this.createInput(e);
      } else {
        this.snackbar = true;
        this.snackbarText = "File Harus CSV";
        this.filecsv = null;
      }
    },
    createInput(file) {
      let promise = new Promise((resolve) => {
        var reader = new FileReader();
        var vm = this;
        reader.onload = () => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          let json = this.csvJSON(result);
          this.colly = json;
        },
        (error) => {
          this.snackbar = true;
          this.snackbarText = error;
          return;
        }
      );
    },
    csvJSON(text, quoteChar = '"', delimiter = ",") {
      var rows = text.split("\n");
      var headers = rows[0].split(",");

      const regex = new RegExp(
        `\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`,
        "gs"
      );

      const match = (line) =>
        [...line.matchAll(regex)].map((m) => m[2]).slice(0, -1);

      var lines = text.split("\n");
      const heads = headers ?? match(lines.shift());
      lines = lines.slice(1);

      return lines.map((line) => {
        return match(line).reduce((acc, cur, i) => {
          const val = cur.length <= 0 ? null : Number(cur) || cur.trim();
          const key = heads[i].trim() ?? `{i}`;
          return { ...acc, [key]: val };
        }, {});
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
