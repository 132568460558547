import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie } from "../../utils/cookies";

export const shipperModule = {
  namespaced: true,
  state: () => ({
    shipperData: [],
    recipientData: [],
    countryData: [],
  }),

  mutations: {
    _SHIPPER_HUB(state, payload) {
      state.shipperData = payload;
    },
    _RECIPIENT_HUB(state, payload) {
      state.recipientData = payload;
    },
    _COUNTRY(state, payload) {
      state.countryData = payload;
    },
  },

  actions: {
    async getHubShipper({ commit }, IDHub) {
      try {
        let { data: resp } = await axios({
          method: "get",
          url: `${baseUrl}/shipper/${IDHub}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });

        commit("_SHIPPER_HUB", resp.data);
        return resp.data;
      } catch (e) {
        return false;
      }
    },
    async getHubRecipient({ commit }, { IDHub, Type }) {
      try {
        let { data: resp } = await axios({
          method: "get",
          url: `${baseUrl}/shipper/Recipient/${IDHub}/${Type}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });

        commit("_RECIPIENT_HUB", resp.data);
        return resp.data;
      } catch (e) {
        return false;
      }
    },

    async getCountry({ commit }) {
      try {
        let { data: resp } = await axios({
          method: "get",
          url: `${baseUrl}/Country`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });

        commit("_COUNTRY", resp.data);
        return resp.data;
      } catch (e) {
        return false;
      }
    },
  },

  getters: {},
};
