<template>
  <div class="Bagging">
    <div class="Bagging-Form">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" class="pa-0">
            <v-text-field
              label="Wb Number"
              placeholder=""
              outlined
              dense
              clearable
              v-model="wbNumber"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" class="pa-0">
            <v-btn block depressed color="secondary" @click="getOrder"
              >search</v-btn
            >
          </v-col>
          <br />
          <br />
          <v-col cols="12" sm="12" class="pa-0 mb-2">
            <h2>Koli: {{ totalColly }}</h2>
          </v-col>
          <v-col cols="12" sm="12" class="pa-0 mb-2">
            <h2>Berat : {{ totalWeight }} Kg</h2>
          </v-col>
          <v-col cols="12" sm="12" class="pa-0">
            <v-btn
              :loading="isLoading"
              depressed
              color="cyan"
              class="white--text"
              @click="createPackingList"
              >tutup kantong</v-btn
            >
            <v-btn
              :loading="isLoadingScan"
              depressed
              color="orange"
              class="white--text ml-2"
              @click="scan"
              >scan</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <hr />
    <BarcodeScannerComponent ref="scanWaybil" @valueScan="valueScan" />

    <div class="Bagging-Result">
      <h2 class="mb-2">Order List</h2>
      <v-data-table :headers="headers" :items="items" :sort-by="sortBy">
        <template v-slot:[`item.totalBerat`]="{ item }">
          {{ item.weight }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn small color="error" depressed @click="deleteItem(item)">
            <v-icon color="white"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";
import BarcodeScannerComponent from "../../../components/dashboard/operation/BarcodeScannerComponent.vue";

export default {
  name: "Bagging",
  components: { BarcodeScannerComponent },

  data() {
    return {
      isLoading: false,
      isLoadingScan: false,
      wbNumber: "",
      snackbar: false,
      snackbarText: "",
      sortBy: [
        "wbNumber",
        "date",
        "destination",
        "colly",
        "totalBerat",
        "status",
      ],
      headers: [
        { text: "Wb Number", value: "wbNumber" },
        { text: "Date", value: "date" },
        { text: "Tujuan", value: "destination" },
        { text: "Koli", value: "colly" },
        { text: "Total Berat", value: "totalBerat" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  computed: {
    totalColly() {
      if (this.items.length == 0) {
        return 0;
      }
      return this.items.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.colly),
        0
      );
    },
    totalWeight() {
      if (this.items.length == 0) {
        return 0;
      }
      return this.items.reduce(
        (accumulator, currentValue) =>
          Number(accumulator) + Number(currentValue.weight),
        0
      );
    },
  },
  methods: {
    scan() {
      this.$refs.scanWaybil.toggleDialog();
    },
    async valueScan(value) {
      this.wbNumber = value;
      this.getOrder();
    },
    deleteItem(item) {
      const filteredItems = this.items.filter(
        (el) => el.wbNumber != item.wbNumber
      );
      this.items = filteredItems;
    },
    getOrder() {
      if (!this.wbNumber) {
        this.snackbar = true;
        this.snackbarText = "Masukkan No. AWB";
        return;
      }

      axios({
        url: `${baseUrl}/HubInboundOrder/Find?wbNumber=${this.wbNumber}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const { data } = res.data;

          let bag = this.items.find(
            (item) =>
              item.wbNumber === data.WBNumber ||
              item.destination !== data.KotaTujuan ||
              item.vendor !== data.Vendor
          );
          if (!bag) {
            this.items.push({
              wbNumber: data.WBNumber,
              date: timezone(data.DateCreated)
                .tz("Asia/Jakarta")
                .format("YYYY-MM-DD, HH:mm"),
              destination: data.KotaTujuan,
              colly: data.TotalKoli,
              weight: data.TotalBerat,
              status: data.PackageStatus,
              idOrder: data.OrderIDOrder,
              vendor: data.Vendor,
            });
          } else {
            this.snackbar = true;
            this.snackbarText = "Waybil Sudah Ada / Berbeda Kota Tujuan ";
          }
          this.wbNumber = "";
        })
        .catch((err) => {
          this.wbNumber = "";
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
    createPackingList() {
      // console.log(this.items);
      if (this.items.length === 0) {
        this.snackbar = true;
        this.snackbarText = "No order added";
        return;
      }

      this.isLoading = true;

      axios({
        url: `${baseUrl}/PackingList`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: this.items,
      })
        .then((res) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.items = [];
        })
        .catch((err) => {
          this.isLoading = false;
          this.snackbar = true;
          this.snackbarText = err.response.data.message.error[0];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Bagging {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px auto;
  }

  &-Form {
    display: flex;
  }
}
</style>
