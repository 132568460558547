// export function groupBy(array, key) {
//   return array.reduce((result, currentValue) => {
//     console.log(result[currentValue[key]]);
//     console.log(result);
//     console.log(currentValue);
//     (result[currentValue[key]] = result[currentValue[key]] || []).push(
//       currentValue
//     );
//     return result;
//   }, []);
// }

export function groupBy(key) {
  return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
}
