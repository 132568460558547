const env = process.env.NODE_ENV;

let baseUrl = "";
let trackingJNE = "";
let trackingInternational = "";

switch (env) {
  case "development":
    baseUrl = "https://api-dev.trolly-app.com";
    // baseUrl = "http://localhost:2000";
    trackingJNE = "https://serene-coast-079360.herokuapp.com/api/tracking";
    // trackingJNE = "http://localhost:3000/api/tracking";
    trackingInternational = "https://tracking-trolly.herokuapp.com/";
    break;
  case "production":
    baseUrl = "https://api.trolly-app.com";
    trackingJNE = "https://serene-coast-079360.herokuapp.com/api/tracking";
    trackingInternational = "https://tracking-trolly.herokuapp.com/";
    break;
}

export { baseUrl, trackingJNE, trackingInternational };
