import { render, staticRenderFns } from "./DialogDeleteUserComponent.vue?vue&type=template&id=5099de79&scoped=true&"
import script from "./DialogDeleteUserComponent.vue?vue&type=script&lang=js&"
export * from "./DialogDeleteUserComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5099de79",
  null
  
)

export default component.exports