<template>
  <div class="ManageManifest">
    <div class="ManageManifest-Create mb-4">
      <h3 class="mb-2">Create Manifest</h3>
      <v-form ref="form" v-model="valid">
        <div class="ManageManifest-Container">
          <v-container>
            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Kota Asal</h4></v-col>
              <v-col cols="8" class="pa-0">
                <v-combobox
                  outlined
                  dense
                  disabled
                  :rules="rules"
                  :items="cityList"
                  :value="cityOrigin"
                  @input="setCityOrigin"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Kota Tujuan</h4></v-col>
              <v-col cols="8" class="pa-0">
                <v-combobox
                  outlined
                  dense
                  disabled
                  :rules="rules"
                  :items="cityList"
                  :value="cityDestination"
                  @input="setCityDestination"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Moda</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-select
                  outlined
                  dense
                  disabled
                  :items="listModa"
                  v-model="moda"
                  @input="setModa"
                ></v-select>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="4" class="pa-0"> <h4>Time Pickup</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-textarea
                  outlined
                  rows="2"
                  no-resize
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col cols="12" class="pa-0">
                <h4 v-if="orderType == 'domestic'">Waktu Pickup</h4>
                <h4 v-else>Waktu Kirim</h4></v-col
              >
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="datePickup"
                      label="Date Pickup"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="datePickup"
                    no-title
                    scrollable
                    @input="menuDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="timePickup"
                  label="Time Pickup"
                  prepend-icon="mdi-clock-time-four-outline"
                  :rules="rules"
                  type="text"
                  v-mask="'##:##'"
                  placeholder="hh:mm, ex:18:00 "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="4" class="pa-0">
                <h4>Total Koli</h4>
              </v-col>

              <v-col cols="8" class="pa-0">
                <v-text-field outlined dense disabled v-model="totalColly">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Total Berat</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-text-field
                  outlined
                  dense
                  disabled
                  v-model="totalWeight"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" class="pa-0"> <h4>Hub Destination</h4></v-col>

              <v-col cols="8" class="pa-0">
                <v-select
                  outlined
                  dense
                  disabled
                  label="Hub Destination"
                  :items="hubList"
                  v-model="idHub"
                  item-text="NamaHub"
                  :rules="rules"
                  item-value="IDHub"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8" class="pa-0 d-flex">
                <dialog-add-packing-list-component
                  :prop-manifest-items="manifestedPacking"
                  @savePackingList="savePackingList"
                  @deletePackingList="deletePackingList"
                  @setDestinationCity="setDestinationCity"
                  @removeListOrder="removeListOrder"
                  ref="packingListDialog"
                />

                <v-btn
                  @click="createManifest"
                  :loading="isRequestLoading"
                  color="cyan"
                  depressed
                  class="ml-2 white--text"
                >
                  request
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-form>
    </div>

    <div class="ManageManifest-List">
      <h3 class="mb-2">Manifest List</h3>

      <v-data-table
        :headers="headers"
        :items="syncItems"
        :sort-by="sortBy"
        :loading="isLoading"
        :sort-desc="[true]"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            depressed
            class="mr-2 white--text"
            small
            color="cyan"
            @click="print(item)"
          >
            print
          </v-btn>
          <!-- <v-btn
            v-if="item.orderType == 'international'"
            depressed
            class="mr-2 white--text"
            small
            color="orange"
            @click="resi(item)"
          >
            Resi
          </v-btn> -->
        </template>
      </v-data-table>
    </div>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DialogAddPackingListComponent from "../../../components/dashboard/operation/DialogAddPackingListComponent.vue";

import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import cityData from "../../../data/city.json";
import { getUserProfile } from "../../../services/user";

export default {
  name: "ManageManifest",
  components: { DialogAddPackingListComponent },
  data() {
    return {
      valid: false,
      rules: [(value) => !!value || "Required"],
      sortBy: ["date", "destination", "totalColly", "totalWeight", "status"],
      moda: 1,
      listModa: ["DARAT", "LAUT", "UDARA"],
      cityDestination: "",
      idCityDestination: "",
      cityOrigin: "",
      idCityOrigin: "",
      listOrders: [],
      hubList: [],
      idHub: "",
      description: "",
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      isRequestLoading: false,
      headers: [
        { text: "No Manifest", value: "manifestId" },
        { text: "Date", value: "date" },
        { text: "Asal", value: "origin" },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly" },
        { text: "Total Berat", value: "totalWeight" },
        { text: "Keterangan", value: "description" },
        { text: "Status", value: "status" },
        // { text: "Resi", value: "Resi3PL" },
        { text: "Action", value: "action", sortable: false },
      ],
      items: [],
      manifestedPacking: [],
      userProfile: {},
      menuDate: false,
      timeDate: false,
      datePickup: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      timePickup: null,
      date: "",
      orderType: "domestic",
    };
  },
  computed: {
    totalColly() {
      if (this.listOrders.length === 0) {
        return 0;
      }

      return this.listOrders.reduce((acc, obj) => {
        return acc + obj.totalColly;
      }, 0);
    },
    totalWeight() {
      if (this.listOrders.length === 0) {
        return 0;
      }

      return this.listOrders.reduce((acc, obj) => {
        return Number(acc) + Number(obj.totalWeight);
      }, 0);
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    syncItems() {
      // return [...this.items, ...this.listOrders];
      return [...this.items];
    },
  },
  methods: {
    print(item) {
      let routeData = this.$router.resolve(
        `/document/Manifest?manifestId=${item.manifestId}`
      );
      window.open(routeData.href, "_blank");
    },
    resi(item) {
      console.log(item);
    },
    setModa(e) {
      this.moda = e.moda;
    },
    setCityDestination(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.cityDestination = cityName;
        this.idCityDestination = city.idCity;
      }
    },
    setCityOrigin(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.cityOrigin = cityName;
        this.idCityOrigin = city.idCity;
      }
    },
    savePackingList(e) {
      this.listOrders.push(e);
    },
    deletePackingList(e) {
      const filteredItems = this.listOrders.filter(
        (el) => el.packingId != e.packingId
      );
      this.listOrders = filteredItems;
    },
    fetchManifest() {
      const hubId = getProfileCookie().KCX_HubIDHub;

      this.isLoading = true;
      axios({
        url: `${baseUrl}/HubManifest`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredData = res.data.data.filter((el) => el.IDManifest != 0);

          this.items = filteredData.map((el) => {
            this.manifestedPacking = [...this.manifestedPacking, ...el.Order];
            return {
              manifestId: el.IDManifest,
              date: moment(el.Date).format("YYYY-MM-DD, HH:mm"),
              origin: el.KotaAsal,
              destination: el.KotaTujuan,
              totalColly: el.TotalKoli,
              totalWeight: el.TotalBerat,
              description: el.Keterangan,
              status: el.Status,
              orderType: el.Order[0].Order.OrderType,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    fetchHub() {
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const hubData = res.data.data.filter(
            (el) => el.IDHub != 0 && el.Status == "ACTIVE"
          );

          this.hubList = hubData;
          this.assignHubDestination();
        })
        .catch(() => {
          this.hubList = [];
        });
    },
    createManifest() {
      // console.log({
      //   Date: new Date(),
      //   TotalKoli: this.totalColly,
      //   TotalBerat: this.totalWeight,
      //   Keterangan: `${String(this.datePickup || "")} ${String(
      //     this.timePickup
      //   )}`,
      //   KotaAsal: this.cityOrigin,
      //   KotaTujuan: this.cityDestination,
      //   KCX_HubIDHub: getProfileCookie().KCX_HubIDHub,
      //   KCX_ViaIDVia: this.moda,
      //   IDHubTujuan: this.idHub,
      //   PackingList:
      //     this.listOrders.length === 0
      //       ? []
      //       : this.listOrders.map((el) => {
      //           return el.packingId;
      //         }),
      // });
      // console.log(this.listOrders);
      if (this.listOrders.length === 0) {
        this.isRequestLoading = false;
        this.snackbar = true;
        this.snackbarText = "Pilih Packinglist Terlebih Dahulu";
        return;
      }
      this.valid = this.$refs.form.validate();
      // console.log(this.cityDestination.join());
      if (this.valid) {
        this.isRequestLoading = true;

        //set moda
        let viaModa;
        switch (this.moda) {
          case "DARAT":
            viaModa = 1;
            break;
          case "LAUT":
            viaModa = 2;
            break;
          case "UDARA":
            viaModa = 3;
            break;
          default:
            viaModa = 4;
            break;
        }
        axios({
          url: `${baseUrl}/HubManifest`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            Date: new Date(),
            TotalKoli: this.totalColly,
            TotalBerat: this.totalWeight,
            Keterangan: `${this.datePickup} ${this.timePickup}:00`,
            KotaAsal: this.cityOrigin,
            KotaTujuan: this.cityDestination.join(),
            KCX_HubIDHub: getProfileCookie().KCX_HubIDHub,
            KCX_ViaIDVia: viaModa,
            IDHubTujuan: this.idHub,
            PackingList:
              this.listOrders.length === 0
                ? []
                : this.listOrders.map((el) => {
                    return el.packingId;
                  }),
          },
        })
          .then((res) => {
            this.isRequestLoading = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.manifestedPacking = [];
            this.listOrders = [];
            this.cityDestination = "";
            this.$refs.packingListDialog.packingDatas = [];
            this.$refs.packingListDialog.clearList();
            this.$refs.packingListDialog.fetchPackingList();
            this.fetchManifest();
          })
          .catch((err) => {
            this.isRequestLoading = false;
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    async assignCityOrigin() {
      this.userProfile = await getUserProfile();
      const cityName = this.userProfile.City.NamaCity.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.cityOrigin = cityName;
        this.idCityOrigin = city.idCity;
      }
    },
    setDestinationCity(v) {
      this.cityDestination = v.cityDestination;
      this.idCityOrigin = v.idCityDestination;
      this.moda = v.moda;
      this.orderType = v.orderType;
    },
    assignHubDestination() {
      const indexHubDestionation = this.hubList.findIndex((v) => {
        return v.NamaHub === "co-loader";
      });
      // console.log(this.hubList[indexHubDestionation]);
      this.idHub = this.hubList[indexHubDestionation].IDHub;
    },
    removeListOrder() {
      this.listOrders = [];
    },
  },
  created() {
    this.fetchManifest();
    this.fetchHub();
    this.assignCityOrigin();
  },
};
</script>

<style lang="scss" scoped>
.ManageManifest {
  &-Create {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
  }

  &-List {
    background-color: white;
    border-radius: 5px;
    padding: 16px;
  }

  &-Container {
    display: flex;
    gap: 32px;
  }
}
</style>
