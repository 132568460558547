<template>
  <div class="CreateOrder">
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirmation</v-card-title>
        <v-card-text>Apakah anda menambah order lain?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="clear">SELESAI</v-btn>
          <v-btn color="green darken-1" text @click="addMore">TAMBAH</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="4">
          <ShipperFormComponent />
        </v-col>

        <v-col cols="8">
          <RecipientFormComponent />
        </v-col>

        <v-col cols="12">
          <ItemFormComponent :book="book" />
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ShipperFormComponent from "@/components/dashboard/inbound/international/ShipperFormComponent.vue";
import RecipientFormComponent from "@/components/dashboard/inbound/international/RecipientFormComponent.vue";
import ItemFormComponent from "@/components/dashboard/inbound/international/ItemFormComponent.vue";
import { INSURANCE_RATE_INTERNATIONAL } from "../../../constant/insurance";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";

export default {
  name: "CreateOrderInternational",
  components: {
    ShipperFormComponent,
    RecipientFormComponent,
    ItemFormComponent,
  },
  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      showDialog: false,
    };
  },
  methods: {
    book() {
      this.valid = this.$refs.form.validate();
      const hubId = getProfileCookie().KCX_HubIDHub;
      if (!this.valid) {
        this.snackbar = true;
        this.snackbarText = "Mohon periksa data anda kembali!";
      }
      if (this.valid) {
        this.isLoading = true;
        let endPoint = "";

        //cek price
        if (this.sendPrice == 0) {
          this.snackbar = true;
          this.snackbarText = "Silahkan Check Price Terebih dahulu!";
          return;
        }

        const isWalkin = this.$store.state.order.isWalkin;
        const idShipper = this.$store.state.order.idShipper;
        const idRecipient = this.$store.state.order.idRecipient;
        const idCollies = this.$store.state.order.idCollies;
        const idOrder = this.$store.state.order.idOrder;
        const shipper = this.$store.state.order.shipperData;
        const recipient = this.$store.state.order.recipientData;
        const item = this.$store.state.order.itemData;

        if (isWalkin) {
          endPoint = "HubOrdersInternational";
        } else {
          endPoint = "BookOrder";
        }

        const insurance = this.insurance;
        axios({
          url: `${baseUrl}/${endPoint}`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            hub: hubId,
          },
          data: {
            endPoint,
            idOrder,
            idShipper,
            idRecipient,
            idCollies,
            shipper: {
              Nama: shipper.shipperName,
              Telp: shipper.shipperPhone,
              Email: shipper.shipperEmail,
              Alamat: shipper.shipperAddress,
              IDCity: shipper.shipperCityId,
              NamaCity: shipper.shipperCity,
              NamaKecamatan: shipper.shipperKecamatan,
              NamaKelurahan: shipper.shipperKelurahan,
              KodePos: shipper.shipperPostCode,
              IDKecamatan: shipper.shipperKecamatanId,
              IDKelurahan: shipper.shipperKelurahanId,
              IDHub: shipper.shipperIDHub,
              NewShipper: shipper.newShipper,
            },
            recipient: {
              Nama: recipient.recipientName,
              Telp: recipient.recipientPhone,
              Email: recipient.recipientEmail,
              Kota: recipient.recipientCity,
              Alamat: recipient.recipientAddress,
              KodePos: recipient.recipientPostCode,
              ContactPerson: recipient.recipientContactPerson,
              IDCountry: recipient.recipientIDCountry,
              CountryName: recipient.recipientCountryName,
              IDHub: recipient.recipientIDHub,
              NewRecipient: recipient.newRecipient,
              IDType: recipient.idType,
              IDNo: recipient.idNo,
            },
            item: {
              itemsDetail: item.itemsDetail,
              itemValue: item.itemValue,
              pebHardCopy: item.pebHardCopy,
              paymentType: item.paymentType,
              kategoryPacket: item.kategoryPacket,
              shipmentType: item.shipmentType,
              Moda: item.moda.toUpperCase(),
              Keterangan: item.description,
              Instruction: item.instruction,
              Diskon: item.discount,
              PPN: this.tax,
              EstimasiNilai: item.estimateValue,
              Asuransi: insurance,
              TotalKoli: 1,
              TotalBerat: item.totalWeight,
              TotalBiayaPacking: 0,
              BiayaKirim: item.kargoPrice,
              // TotalBiaya: item.addCostTotal,
              TotalBiaya: Math.round(
                this.totalPrice +
                  this.insurance +
                  this.ppn +
                  Number(this.price3PL) +
                  Number(item.costSurcharge) +
                  Number(item.addCostTotal)
              ),
              IsInsurance: item.isInsurance,
              Price3PL: this.price3PL,
              AdditionalCost: item.additionalCost,
              NameSurcharge: item.nameSurcharge,
              CostSurcharge: item.costSurcharge,
              DescSurcharge: item.descSurcharge,
              PrefixOrder: item.prefixOrder,
              collies: {
                JumlahKoli: 1,
                Berat: item.collies.weight,
                Panjang: item.collies.length,
                Lebar: item.collies.width,
                Tinggi: item.collies.height,
                BeratVolume: item.collies.volumeWeight,
                Terberat: item.totalWeight,
                IsPacking: item.collies.isPacking,
                BiayaPacking: item.collies.packingPrice,
                TotalBiaya: Math.round(
                  this.totalPrice +
                    this.insurance +
                    this.ppn +
                    Number(this.price3PL)
                ),
              },
              Vendor: item.vendor,
              Etd: item.etd,
              Service: item.service,
            },
          },
        })
          .then((res) => {
            this.showDialog = true;
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            // this.clear();
            this.showDialog = true;
          })
          .catch((err) => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
    clear() {
      this.$store.commit("order/CLEAR_DATA");
      // this.valid = this.$refs.form.resetValidation();
      this.showDialog = false;
      this.$router.push("/dashboard/order-confirmation");
    },

    addMore() {
      // this.$store.commit("order/CLEAR_RECIPIENT_INFORMATION");
      // this.valid = this.$refs.form.resetValidation();
      // this.showDialog = false;
      window.scrollTo({ top: 0 });
      window.location.reload();
    },
  },
  computed: {
    insurance() {
      if (this.$store.state.order.itemData.isInsurance) {
        const estimateValue = parseFloat(
          this.$store.state.order.itemData.estimateValue
        );
        return estimateValue * INSURANCE_RATE_INTERNATIONAL;
      }
      return 0;
      // return this.$store.state.order.itemData.insurance;
    },
    discount() {
      return this.$store.state.order.itemData.discount;
    },
    tax() {
      return this.$store.state.order.itemData.tax;
    },
    ppn() {
      // return this.sendPrice * 0.01;
      return 0;
    },
    sendPrice() {
      return this.$store.state.order.itemData.kargoPrice;
    },
    packingPrice() {
      return 0;
    },
    totalPrice() {
      const total =
        this.packingPrice + this.sendPrice + this.tax - this.discount;
      return total;
    },
    price3PL() {
      return this.$store.state.order.itemData.price3PL;
    },
    totalBiaya() {
      if (this.totalPrice > 0)
        return this.totalPrice + this.insurance + this.ppn;
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateOrder {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  hr {
    margin: 16px;
  }

  &-Action {
    text-align: right;
    padding: 16px;
  }

  &-Summary {
    text-align: right;
  }
}
</style>
