import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAeFrFgllResaigZoXpSMh38qKXFXYlPyY",
  authDomain: "user-trolly.firebaseapp.com",
  projectId: "user-trolly",
  storageBucket: "user-trolly.appspot.com",
  messagingSenderId: "87551666550",
  appId: "1:87551666550:web:96efc9ae463e020df67257",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
