<template>
  <div class="DialogEditVendorComponent">
    <v-dialog v-model="dialogEditVendor" persistent max-width="600px">
      <v-card>
        <v-card-title> Edit Vendor </v-card-title>

        <v-card-text>
          <v-form class="Form">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.name"
                    outlined
                    dense
                    label="Nama Vendor"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.pic"
                    outlined
                    dense
                    label="PIC"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.contact"
                    outlined
                    dense
                    label="No. Handphone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.email"
                    outlined
                    dense
                    label="Email"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.city"
                    outlined
                    dense
                    label="Kota"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="vendor.village"
                    outlined
                    dense
                    label="Kelurahan"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    v-model="vendor.address"
                    outlined
                    dense
                    label="Alamat"
                    rows="2"
                    no-resize
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
          >
            edit vendor
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogEditVendorComponent",
  props: ["vendor"],
  data() {
    return {
      dialogEditVendor: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogEditVendor = !this.dialogEditVendor;
    },
  },
};
</script>

<style lang="scss" scoped></style>
