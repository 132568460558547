const env = process.env.NODE_ENV;
import Vue from "vue";
import VueRouter from "vue-router";
import { dashboardRoute } from "@/constant/routeName.js";

import Home from "../views/Home.vue";

import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardHome from "../views/dashboard/DashboardHome.vue";

// settings
// import AreaManagement from "../views/dashboard/settings/AreaManagement.vue";
// import HubManagement from "../views/dashboard/settings/HubManagement.vue";
import UserManagement from "../views/dashboard/settings/UserManagement.vue";
import VendorManagement from "../views/dashboard/settings/VendorManagement.vue";

// module
import DetailTransaction from "../views/dashboard/module/DetailTransaction.vue";
import RecapTransaction from "../views/dashboard/module/RecapTransaction.vue";
import ShipmentStatus from "../views/dashboard/module/ShipmentStatus.vue";
import Wallet from "../views/dashboard/module/Wallet.vue";
import Profile from "../views/dashboard/settings/Profile.vue";

// inbound
import ChoseOrder from "../views/dashboard/inbound/ChoseOrder.vue";
import CreateOrder from "../views/dashboard/inbound/CreateOrder.vue";
import CreateOrderInternational from "../views/dashboard/inbound/CreateOrderInternational.vue";
import FareCheck from "../views/dashboard/inbound/FareCheck.vue";
import FareCheckInternational from "../views/dashboard/inbound/FareCheckInternational.vue";
import JobOrder from "../views/dashboard/inbound/JobOrder.vue";
import ManagePickup from "../views/dashboard/inbound/ManagePickup.vue";
import OrderConfirmation from "../views/dashboard/inbound/OrderConfirmation.vue";
import OrderManagement from "../views/dashboard/inbound/OrderManagement.vue";

// operation
import Bagging from "../views/dashboard/operation/Bagging.vue";
import Incoming from "../views/dashboard/operation/Incoming.vue";
import ManageManifest from "../views/dashboard/operation/ManageManifest.vue";
import ManageOutbound from "../views/dashboard/operation/ManageOutbound.vue";
import PackingList from "../views/dashboard/operation/PackingList.vue";
import Unbagging from "../views/dashboard/operation/Unbagging.vue";

// delivery
import DeliveryRunSheet from "../views/dashboard/delivery/DeliveryRunSheet.vue";
import Irregularity from "../views/dashboard/delivery/Irregularity.vue";
import ManageDelivery from "../views/dashboard/delivery/ManageDelivery.vue";
import Outgoing from "../views/dashboard/delivery/Outgoing.vue";
import Tracking from "../views/dashboard/delivery/Tracking.vue";

import Document from "../views/document/Document.vue";
import ShippingLabel from "../views/document/ShippingLabel.vue";
import Receipt from "../views/document/Receipt.vue";
import FinancialReport from "../views/dashboard/report/FinancialReport.vue";
import PackingListDoc from "../views/document/PackingList.vue";

import ShippingLabelInter from "../views/document/ShippingLabelInter.vue";
import ReceiptInter from "../views/document/ReceiptInter.vue";

import PageNotFound from "../views/PageNotFound.vue";
import { getTokenCookie } from "../utils/cookies";

Vue.use(VueRouter);

let login;
if (env == "development") {
  login = {
    path: "/",
    name: "HomePage",
    component: Home,
  };
} else if (env == "production") {
  login = {
    path: "/",
    beforeEnter() {
      window.location.href = "https://trolly.asia/mitra/login";
    },
  };
}

const routes = [
  login,
  {
    path: "*",
    component: PageNotFound,
  },
  {
    path: "/document",
    name: "document",
    component: Document,
    children: [
      {
        path: "label",
        component: ShippingLabel,
      },
      {
        path: "receipt",
        component: Receipt,
      },
      {
        path: "label-inter",
        component: ShippingLabelInter,
      },
      {
        path: "receipt-inter",
        component: ReceiptInter,
      },
      {
        path: "manifest",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/document/Manifest.vue"
          ),
      },
      {
        path: "packing-list",
        name: "packingPrint",
        component: PackingListDoc,
        props: { id: "coba" },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: dashboardRoute.dashboardHome.path,
        name: dashboardRoute.dashboardHome.name,
        component: DashboardHome,
      },
      // {
      //   path: dashboard.areaManagement.path,
      //   name: dashboard.areaManagement.name,
      //   component: AreaManagement,
      // },
      {
        path: dashboardRoute.userManagement.path,
        name: dashboardRoute.userManagement.name,
        component: UserManagement,
      },
      // {
      //   path: dashboard.hubManagement.path,
      //   name: dashboard.hubManagement.name,
      //   component: HubManagement,
      // },
      {
        path: dashboardRoute.vendorManagement.path,
        name: dashboardRoute.vendorManagement.name,
        component: VendorManagement,
      },
      {
        path: dashboardRoute.profile.path,
        name: dashboardRoute.profile.name,
        component: Profile,
      },
      {
        path: dashboardRoute.shipmentStatus.path,
        name: dashboardRoute.shipmentStatus.name,
        component: ShipmentStatus,
      },
      {
        path: dashboardRoute.detailTransaction.path,
        name: dashboardRoute.detailTransaction.name,
        component: DetailTransaction,
      },
      {
        path: dashboardRoute.recapTransaction.path,
        name: dashboardRoute.recapTransaction.name,
        component: RecapTransaction,
      },
      {
        path: dashboardRoute.wallet.path,
        name: dashboardRoute.wallet.name,
        component: Wallet,
      },
      {
        path: dashboardRoute.choseOrder.path,
        name: dashboardRoute.choseOrder.name,
        component: ChoseOrder,
      },
      {
        path: dashboardRoute.createOrder.path,
        name: dashboardRoute.createOrder.name,
        component: CreateOrder,
      },
      {
        path: dashboardRoute.createOrderInternational.path,
        name: dashboardRoute.createOrderInternational.name,
        component: CreateOrderInternational,
      },
      {
        path: dashboardRoute.fareCheck.path,
        name: dashboardRoute.fareCheck.name,
        component: FareCheck,
      },
      {
        path: dashboardRoute.fareCheckInternational.path,
        name: dashboardRoute.fareCheckInternational.name,
        component: FareCheckInternational,
      },
      {
        path: dashboardRoute.jobOrder.path,
        name: dashboardRoute.jobOrder.name,
        component: JobOrder,
      },
      {
        path: dashboardRoute.managePickup.path,
        name: dashboardRoute.managePickup.name,
        component: ManagePickup,
      },
      {
        path: dashboardRoute.orderConfirmation.path,
        name: dashboardRoute.orderConfirmation.name,
        component: OrderConfirmation,
      },
      {
        path: dashboardRoute.orderManagement.path,
        name: dashboardRoute.orderManagement.name,
        component: OrderManagement,
      },
      {
        path: dashboardRoute.bagging.path,
        name: dashboardRoute.bagging.name,
        component: Bagging,
      },
      {
        path: dashboardRoute.incoming.path,
        name: dashboardRoute.incoming.name,
        component: Incoming,
      },
      {
        path: dashboardRoute.manageManifest.path,
        name: dashboardRoute.manageManifest.name,
        component: ManageManifest,
      },
      {
        path: dashboardRoute.manageOutbound.path,
        name: dashboardRoute.manageOutbound.name,
        component: ManageOutbound,
      },
      {
        path: dashboardRoute.packingList.path,
        name: dashboardRoute.packingList.name,
        component: PackingList,
      },
      {
        path: dashboardRoute.unbagging.path,
        name: dashboardRoute.unbagging.name,
        component: Unbagging,
      },
      {
        path: dashboardRoute.deliveryRunSheet.path,
        name: dashboardRoute.deliveryRunSheet.name,
        component: DeliveryRunSheet,
      },
      {
        path: dashboardRoute.irregularity.path,
        name: dashboardRoute.irregularity.name,
        component: Irregularity,
      },
      {
        path: dashboardRoute.manageDelivery.path,
        name: dashboardRoute.manageDelivery.name,
        component: ManageDelivery,
      },
      {
        path: dashboardRoute.outgoing.path,
        name: dashboardRoute.outgoing.name,
        component: Outgoing,
      },
      {
        path: dashboardRoute.tracking.path,
        name: dashboardRoute.tracking.name,
        component: Tracking,
      },
      {
        path: dashboardRoute.financialReport.path,
        name: dashboardRoute.financialReport.name,
        component: FinancialReport,
      },
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getTokenCookie();

  if (to.fullPath !== "/" && !isAuthenticated) {
    next("/");
  }
  if (to.fullPath === "/" && isAuthenticated) {
    next(dashboardRoute.dashboardHome);
  }
  next();
});

export default router;
