<template>
  <div>
    <v-dialog v-model="dialogDeleteUser" persistent width="600px">
      <v-card>
        <v-card-title> {{ user.username }} </v-card-title>

        <br />

        <v-card-text>
          <h3>Are you sure want to delete this user??</h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
          >
            delete user
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogDeleteUserComponent",
  props: ["user"],
  data() {
    return {
      dialogDeleteUser: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogDeleteUser = !this.dialogDeleteUser;
    },
  },
};
</script>

<style lang="scss" scoped></style>
