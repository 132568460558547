<template>
  <div class="ReceiptDocument">
    <loading-component v-if="isLoading"></loading-component>

    <div v-else>
      <div id="PrintReceiptAction">
        <h4>Print Receipt</h4>
        <v-btn @click="print" color="warning">Print</v-btn>
      </div>
      <div class="sprint">
        <v-app>
          <v-main>
            <div>
              <table style="border: 1px solid black !important">
                <tr style="border: none !important">
                  <td
                    width="60%"
                    align="center"
                    style="border: none !important"
                  >
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          width="50%"
                          style="border: none !important"
                          align="center"
                        >
                          <img
                            style="float: left"
                            :src="trollyLogo"
                            alt="shipping logo"
                            width="50%"
                          />
                        </td>
                        <td
                          width="50%"
                          align="center"
                          style="border: none !important"
                        >
                          <strong style="font-size: 12px"
                            >COMMERCIAL INVOICE</strong
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td
                    width="40%"
                    style="font-size: 12px; border: none !important"
                  >
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          width="75%"
                          align="center"
                          style="border: none !important"
                        >
                          <barcode
                            :value="barcodeValue"
                            text-margin="0"
                            background="none"
                            width="1"
                            height="30"
                            font-size="14"
                          >
                          </barcode>
                        </td>
                        <td
                          width="25%"
                          align="center"
                          style="border: none !important"
                        >
                          <qrcode-vue
                            :value="qrValue"
                            :size="50"
                            level="H"
                          ></qrcode-vue>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table style="width: 100%; border: none !important">
                <tr class="text-center">
                  <td style="width: 50%"><strong>DETAIL SHIPPER</strong></td>
                  <td style="width: 50%"><strong>DETAIL CONNSIGNEE</strong></td>
                </tr>
                <tr>
                  <td valign="top" style="width: 50%; padding: 5px !important">
                    <strong>Shipper Name : {{ order.shipperName }}</strong>
                    <br />
                    {{ order.shipperAlamat }}
                    <br />
                    <br />
                  </td>
                  <td style="width: 50%; padding: 5px !important" valign="top">
                    <strong>Consignee Name : {{ order.recipientName }}</strong>
                    <br />
                    {{ order.recipientAddress }}

                    <br />
                    <strong>{{ order.IDType }} : {{ order.IDNo }}</strong>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%">
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          style="
                            width: 70%;
                            padding: 5px !important;
                            border-left: none !important;
                            border-top: none !important;
                            border-bottom: none !important;
                          "
                        >
                          Shipper Phone : {{ phone }}
                        </td>
                        <td
                          style="
                            width: 30%;
                            border: none !important;
                            padding-left: 5px !important;
                          "
                        >
                          <strong>INDONESIA</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style="width: 50%">
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          rowspan="3"
                          style="
                            border: none !important;
                            padding: 5px !important;
                          "
                        >
                          Consignee Phone : {{ order.recipientPhone }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 5px !important;
                            border-right: none !important;
                          "
                        >
                          KODEPOS <strong>{{ order.recipientPostCode }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 5px !important;
                            border-bottom: none !important;
                            border-right: none !important;
                          "
                        >
                          <strong>{{ order.recipientCountry }}</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr class="text-center">
                  <td style="width: 50%"><strong>SHIPMENT DETAILS</strong></td>
                  <td style="width: 50%">&nbsp;</td>
                </tr>
                <tr>
                  <td style="width: 50%" rowspan="2" valign="top">
                    <table
                      style="width: 95%; margin: 10px; padding: 5px !important"
                    >
                      <tr>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Value (USD)</th>
                        <th>Weight</th>
                      </tr>
                      <tr
                        class="text-center"
                        v-for="(item, index) in order.itemDetails"
                        :key="index"
                      >
                        <td>{{ item.ItemDescription }}</td>
                        <td>{{ item.ItemPieces }}</td>
                        <td>
                          {{ (Number(item.ItemPrice) / priceUSD).toFixed(2) }}
                        </td>
                        <!-- <td>{{ item.ItemPrice }}</td> -->
                        <td>{{ item.ItemWeight }}</td>
                      </tr>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                      </tr>
                      <tr>
                        <th>&nbsp;</th>
                        <th>{{ order.collies.Panjang }}</th>
                        <th>{{ order.collies.Lebar }}</th>
                        <th>{{ order.collies.Tinggi }}</th>
                      </tr>
                    </table>
                    <div style="margin-left: 10px">
                      <strong>{{ order.IDType }} : {{ order.IDNo }}</strong>
                    </div>
                  </td>
                  <td style="width: 50%">
                    <div style="padding: 10px">
                      permintaan pengirim bila tidak diantar/Sender's
                      instructions in case 0f non-delivery
                    </div>

                    <table style="width: 100%; border: none !important">
                      <tr class="text-center" style="border: none !important">
                        <td style="border: none !important">
                          [&nbsp;&nbsp;] Kembali ke pengirim <br />
                          Return To Sender
                        </td>
                        <td style="border: none !important">
                          [&nbsp;&nbsp; ] dianggap dilepaskan <br />
                          treat as abandoned
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%">
                    <div style="padding: 10px">
                      I certify that particular given in this custom declaration
                      are correct and that this item does not any dangerous
                      article or article prohibited by legislation or by postal
                      or custom regulations.I alo agree to pay costs related to
                      my adjacent instructions in case of non-dellivery
                    </div>
                    <table style="width: 100%; border: none !important">
                      <tr class="text-center">
                        <td style="border-left: none !important">
                          <strong>Official Sign</strong>
                        </td>
                        <td style="border-right;: none !important">
                          <strong>Shipper Sign</strong>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-left: none !important">
                          &nbsp; <br /><br />
                        </td>
                        <td style="border-right;: none !important">
                          &nbsp;<br /><br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              Sheet 1 - Attached On Packet || Date : {{ order.date }}
              <div class="mt-5"></div>

              <table style="border: 1px solid black !important">
                <tr style="border: none !important">
                  <td
                    width="60%"
                    align="center"
                    style="border: none !important"
                  >
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          width="50%"
                          style="border: none !important"
                          align="center"
                        >
                          <img
                            style="float: left"
                            :src="trollyLogo"
                            alt="shipping logo"
                            width="50%"
                          />
                        </td>
                        <td
                          width="50%"
                          align="center"
                          style="border: none !important"
                        >
                          <strong style="font-size: 12px"
                            >INTERNATIONAL AIR WAYBILL</strong
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td
                    width="40%"
                    style="font-size: 12px; border: none !important"
                  >
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          width="75%"
                          align="center"
                          style="border: none !important"
                        >
                          <barcode
                            :value="barcodeValue"
                            text-margin="0"
                            background="none"
                            width="1"
                            height="30"
                            font-size="14"
                          >
                          </barcode>
                        </td>
                        <td
                          width="25%"
                          align="center"
                          style="border: none !important"
                        >
                          <qrcode-vue
                            :value="qrValue"
                            :size="50"
                            level="H"
                          ></qrcode-vue>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>

              <table style="width: 100%; border: none !important">
                <tr class="text-center">
                  <td style="width: 50%"><strong>DETAIL SHIPPER</strong></td>
                  <td style="width: 50%"><strong>DETAIL CONNSIGNEE</strong></td>
                </tr>
                <tr>
                  <td valign="top" style="width: 50%; padding: 5px !important">
                    <strong>Shipper Name : {{ order.shipperName }}</strong>
                    <br />
                    {{ order.shipperAlamat }}
                    <br />
                    <br />
                  </td>
                  <td style="width: 50%; padding: 5px !important" valign="top">
                    <strong>Consignee Name : {{ order.recipientName }}</strong>
                    <br />
                    {{ order.recipientAddress }}

                    <br />
                    <br />
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%">
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          style="
                            width: 70%;
                            padding: 5px !important;
                            border-left: none !important;
                            border-top: none !important;
                            border-bottom: none !important;
                          "
                        >
                          Shipper Phone : {{ order.ShipperTelp }}
                        </td>
                        <td
                          style="
                            width: 30%;
                            border: none !important;
                            padding-left: 5px !important;
                          "
                        >
                          <strong>INDONESIA</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style="width: 50%">
                    <table style="width: 100%; border: none !important">
                      <tr>
                        <td
                          rowspan="3"
                          style="
                            border: none !important;
                            padding: 5px !important;
                          "
                        >
                          Consignee Phone : {{ order.recipientPhone }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 5px !important;
                            border-right: none !important;
                          "
                        >
                          KODEPOS <strong>{{ order.recipientPostCode }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 5px !important;
                            border-bottom: none !important;
                            border-right: none !important;
                          "
                        >
                          <strong>{{ order.recipientCountry }}</strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr class="text-center">
                  <td style="width: 50%"><strong>SHIPMENT DETAILS</strong></td>
                  <td style="width: 50%"><strong>DETAIL PRICE</strong></td>
                </tr>
                <tr>
                  <td style="width: 50%" rowspan="2" valign="top">
                    <table
                      style="width: 95%; margin: 10px; padding: 5px !important"
                    >
                      <tr>
                        <td>Kategory</td>
                        <td colspan="4" style="padding: 5px !important">
                          {{ order.Kategory }}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 3px !important">Description</td>
                        <td style="padding: 3px !important" colspan="4">
                          <span
                            v-for="(desc, index) in order.itemDetails"
                            :key="index"
                          >
                            {{ index + 1 }}. {{ desc.ItemDescription }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 3px !important">Quantity</td>
                        <td style="padding: 3px !important" class="text-center">
                          {{ totalQuantity(order.itemDetails) }}
                        </td>
                        <td
                          style="padding: 3px !important"
                          class="text-center"
                          rowspan="2"
                        >
                          Length
                        </td>
                        <td
                          style="padding: 3px !important"
                          class="text-center"
                          rowspan="2"
                        >
                          Width
                        </td>
                        <td
                          style="padding: 3px !important"
                          class="text-center"
                          rowspan="2"
                        >
                          Height
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 3px !important">Value (USD)</td>
                        <td style="padding: 3px !important" class="text-center">
                          {{ estimateUSD(order.itemDetails) }}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 3px !important">Weight</td>
                        <td style="padding: 3px !important" class="text-center">
                          {{ order.totalWeight }}
                        </td>
                        <td style="padding: 3px !important" class="text-center">
                          {{ order.collies.Panjang }}
                        </td>
                        <td style="padding: 3px !important" class="text-center">
                          {{ order.collies.Lebar }}
                        </td>
                        <td style="padding: 3px !important" class="text-center">
                          {{ order.collies.Tinggi }}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding: 3px !important">HS Code</td>
                        <td style="padding: 3px !important" colspan="4">
                          {{ order.itemDetails[0].ItemHSCode }}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style="width: 50%">
                    <table
                      style="width: 98%; border: none !important; margin: 5px"
                    >
                      <tr>
                        <td style="border: none !important">Delivery Cost</td>
                        <td style="border: none !important">:</td>
                        <td style="border: none !important">
                          {{ order.deviveryCost }}
                        </td>
                      </tr>
                      <tr>
                        <td style="border: none !important">Other Cost</td>
                        <td style="border: none !important">:</td>
                        <td style="border: none !important">Rp. 0</td>
                      </tr>
                      <tr>
                        <td style="border: none !important">Insurance</td>
                        <td style="border: none !important">:</td>
                        <td style="border: none !important">
                          {{ order.insurance }}
                        </td>
                      </tr>
                      <tr>
                        <td style="border: none !important">Discount</td>
                        <td style="border: none !important">:</td>
                        <td style="border: none !important">Rp. 0</td>
                      </tr>
                      <tr>
                        <td
                          style="
                            border-left: none !important;
                            border-bottom: none !important;
                            border-right: none !important;
                          "
                        >
                          Total Cost
                        </td>
                        <td
                          style="
                            border-left: none !important;
                            border-bottom: none !important;
                            border-right: none !important;
                          "
                        >
                          :
                        </td>
                        <td
                          style="
                            border-left: none !important;
                            border-bottom: none !important;
                            border-right: none !important;
                          "
                        >
                          <strong> {{ order.totalPrice }} </strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="width: 50%">
                    <table style="width: 100%; border: none !important">
                      <tr class="text-center">
                        <td style="border-left: none !important">
                          <strong>Official Sign</strong>
                        </td>
                        <td style="border-right;: none !important">
                          <strong>Shipper Sign</strong>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-left: none !important">
                          &nbsp; <br /><br />
                        </td>
                        <td style="border-right;: none !important">
                          &nbsp;<br /><br />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 100% !important;
                      font-size: 8px;
                      border-top: none !important;
                    "
                    colspan="2"
                  >
                    NOTES : <br />
                    <ol>
                      <li>
                        Semua pajak yang timbul di negara tujuan akan menjadi
                        tanggung jawab penerima atau pengirim.
                      </li>
                      <li>
                        Pengirim wajib memberitahukan secara detil paket yang
                        akan dikirim (uraian barang dan harga).
                      </li>
                      <li>
                        Jika terdapat perbedaan antara invoice dengan isi paket
                        maka segala konsekuensi yang timbul baik materil maupun
                        non materil dinegara tujuan menjadi tanggung jawab
                        pengirim.
                      </li>
                      <li>
                        Trolly Express tidak bertanggung jawab jika paket
                        ditahan oleh pihak Bea Cukai dinegara tujuan
                      </li>
                      <li>
                        Jika terjadi kehilangan karena kelalaian Trolly express,
                        maka Trolly express akan mengganti sesuai dengan nilai
                        invoice atau maksimal 100 USD.
                      </li>
                      <li>
                        Trolly Express tidak bertanggung jawab terhadap
                        kerusakan barang yang dikarena sifat barang itu sendiri
                        atau karena packing yang tidak baik.
                      </li>
                      <li>
                        Trolly Express tidak bertanggung jawab jika paket harus
                        di destroy (dimusnahkan) karena penerima / pengirim
                        tidak dapat dihubungi.
                      </li>
                      <li>
                        Trolly Express tidak bertanggung jawab atas kerugian
                        yang timbul (tanggung renteng) atas keterlambatan
                        pengiriman
                      </li>
                    </ol>
                  </td>
                </tr>
              </table>
              Sheet 2 - For Customer | Estimate Time Delivery {{ minEtd }} -
              {{ maxEtd }} days || Date :
              {{ order.date }}
            </div>
          </v-main>
        </v-app>
      </div>

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :manual-pagination="true"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-content-width="207mm"
        pdf-orientation="portrait"
        ref="html2PdfInvoice"
        :html-to-pdf-options="htmlToPdfOptions"
      >
        <section slot="pdf-content">
          <div class="sprint">
            <v-app>
              <v-main>
                <div>
                  <table style="border: 1px solid black !important">
                    <tr style="border: none !important">
                      <td
                        width="60%"
                        align="center"
                        style="border: none !important"
                      >
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              width="50%"
                              style="border: none !important"
                              align="center"
                            >
                              <img
                                style="float: left"
                                :src="trollyLogo"
                                alt="shipping logo"
                                width="50%"
                              />
                            </td>
                            <td
                              width="50%"
                              align="center"
                              style="border: none !important"
                            >
                              <strong style="font-size: 12px"
                                >COMMERCIAL INVOICE</strong
                              >
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        width="40%"
                        style="font-size: 12px; border: none !important"
                      >
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              width="75%"
                              align="center"
                              style="border: none !important"
                            >
                              <barcode
                                :value="barcodeValue"
                                text-margin="0"
                                background="none"
                                width="1"
                                height="30"
                                font-size="14"
                              >
                              </barcode>
                            </td>
                            <td
                              width="25%"
                              align="center"
                              style="border: none !important"
                            >
                              <qrcode-vue
                                :value="qrValue"
                                :size="50"
                                level="H"
                              ></qrcode-vue>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>

                  <table style="width: 100%; border: none !important">
                    <tr class="text-center">
                      <td style="width: 50%">
                        <strong>DETAIL SHIPPER</strong>
                      </td>
                      <td style="width: 50%">
                        <strong>DETAIL CONNSIGNEE</strong>
                      </td>
                    </tr>
                    <tr>
                      <td
                        valign="top"
                        style="width: 50%; padding: 5px !important"
                      >
                        <strong>Shipper Name : {{ order.shipperName }}</strong>
                        <br />
                        {{ order.shipperAlamat }}
                        <br />
                        <br />
                      </td>
                      <td
                        style="width: 50%; padding: 5px !important"
                        valign="top"
                      >
                        <strong
                          >Consignee Name : {{ order.recipientName }}</strong
                        >
                        <br />
                        {{ order.recipientAddress }}

                        <br />
                        <strong>{{ order.IDType }} : {{ order.IDNo }}</strong>
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 50%">
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              style="
                                width: 70%;
                                padding: 5px !important;
                                border-left: none !important;
                                border-top: none !important;
                                border-bottom: none !important;
                              "
                            >
                              Shipper Phone : {{ phone }}
                            </td>
                            <td
                              style="
                                width: 30%;
                                border: none !important;
                                padding-left: 5px !important;
                              "
                            >
                              <strong>INDONESIA</strong>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td style="width: 50%">
                        <table style="width: 100%; border: none !important">
                          <tr>
                            <td
                              rowspan="3"
                              style="
                                border: none !important;
                                padding: 5px !important;
                              "
                            >
                              Consignee Phone : {{ order.recipientPhone }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                padding: 5px !important;
                                border-right: none !important;
                              "
                            >
                              KODEPOS
                              <strong>{{ order.recipientPostCode }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style="
                                padding: 5px !important;
                                border-bottom: none !important;
                                border-right: none !important;
                              "
                            >
                              <strong>{{ order.recipientCountry }}</strong>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td style="width: 50%">
                        <strong>SHIPMENT DETAILS</strong>
                      </td>
                      <td style="width: 50%">&nbsp;</td>
                    </tr>
                    <tr>
                      <td style="width: 50%" rowspan="2" valign="top">
                        <table
                          style="
                            width: 95%;
                            margin: 10px;
                            padding: 5px !important;
                          "
                        >
                          <tr>
                            <th>Description</th>
                            <th>Qty</th>
                            <th>Value (USD)</th>
                            <th>Weight</th>
                          </tr>
                          <tr
                            class="text-center"
                            v-for="(item, index) in order.itemDetails"
                            :key="index"
                          >
                            <td>{{ item.ItemDescription }}</td>
                            <td>{{ item.ItemPieces }}</td>
                            <td>
                              {{
                                (Number(item.ItemPrice) / priceUSD).toFixed(2)
                              }}
                            </td>
                            <td>{{ item.ItemWeight }}</td>
                          </tr>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Height</th>
                          </tr>
                          <tr>
                            <th>&nbsp;</th>
                            <th>{{ order.collies.Panjang }}</th>
                            <th>{{ order.collies.Lebar }}</th>
                            <th>{{ order.collies.Tinggi }}</th>
                          </tr>
                        </table>
                        <div style="margin-left: 10px">
                          <strong>{{ order.IDType }} : {{ order.IDNo }}</strong>
                        </div>
                      </td>
                      <td style="width: 50%">
                        <div style="padding: 10px">
                          permintaan pengirim bila tidak diantar/Sender's
                          instructions in case 0f non-delivery
                        </div>

                        <table style="width: 100%; border: none !important">
                          <tr
                            class="text-center"
                            style="border: none !important"
                          >
                            <td style="border: none !important">
                              [&nbsp;&nbsp;] Kembali ke pengirim <br />
                              Return To Sender
                            </td>
                            <td style="border: none !important">
                              [&nbsp;&nbsp; ] dianggap dilepaskan <br />
                              treat as abandoned
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 50%">
                        <div style="padding: 10px">
                          I certify that particular given in this custom
                          declaration are correct and that this item does not
                          any dangerous article or article prohibited by
                          legislation or by postal or custom regulations.I alo
                          agree to pay costs related to my adjacent instructions
                          in case of non-dellivery
                        </div>
                        <table style="width: 100%; border: none !important">
                          <tr class="text-center">
                            <td style="border-left: none !important">
                              <strong>Official Sign</strong>
                            </td>
                            <td style="border-right;: none !important">
                              <strong>Shipper Sign</strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="border-left: none !important">
                              &nbsp; <br /><br />
                            </td>
                            <td style="border-right;: none !important">
                              &nbsp;<br /><br />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  Sheet 1 - Attached On Packet || Date : {{ order.date }}
                  <div class="mt-5"></div>
                </div>
                <div class="html2pdf__page-break" />
                <div class="mt-5"></div>
                <table style="border: 1px solid black !important">
                  <tr style="border: none !important">
                    <td
                      width="60%"
                      align="center"
                      style="border: none !important"
                    >
                      <table style="width: 100%; border: none !important">
                        <tr>
                          <td
                            width="50%"
                            style="border: none !important"
                            align="center"
                          >
                            <img
                              style="float: left"
                              :src="trollyLogo"
                              alt="shipping logo"
                              width="50%"
                            />
                          </td>
                          <td
                            width="50%"
                            align="center"
                            style="border: none !important"
                          >
                            <strong style="font-size: 12px"
                              >INTERNATIONAL AIR WAYBILL</strong
                            >
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      width="40%"
                      style="font-size: 12px; border: none !important"
                    >
                      <table style="width: 100%; border: none !important">
                        <tr>
                          <td
                            width="75%"
                            align="center"
                            style="border: none !important"
                          >
                            <barcode
                              :value="barcodeValue"
                              text-margin="0"
                              background="none"
                              width="1"
                              height="30"
                              font-size="14"
                            >
                            </barcode>
                          </td>
                          <td
                            width="25%"
                            align="center"
                            style="border: none !important"
                          >
                            <qrcode-vue
                              :value="qrValue"
                              :size="50"
                              level="H"
                            ></qrcode-vue>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>

                <table style="width: 100%; border: none !important">
                  <tr class="text-center">
                    <td style="width: 50%">
                      <strong>DETAIL SHIPPER</strong>
                    </td>
                    <td style="width: 50%">
                      <strong>DETAIL CONNSIGNEE</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      valign="top"
                      style="width: 50%; padding: 5px !important"
                    >
                      <strong>Shipper Name : {{ order.shipperName }}</strong>
                      <br />
                      {{ order.shipperAlamat }}
                      <br />
                      <br />
                    </td>
                    <td
                      style="width: 50%; padding: 5px !important"
                      valign="top"
                    >
                      <strong
                        >Consignee Name : {{ order.recipientName }}</strong
                      >
                      <br />
                      {{ order.recipientAddress }}
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%">
                      <table style="width: 100%; border: none !important">
                        <tr>
                          <td
                            style="
                              width: 70%;
                              padding: 5px !important;
                              border-left: none !important;
                              border-top: none !important;
                              border-bottom: none !important;
                            "
                          >
                            Shipper Phone : {{ order.ShipperTelp }}
                          </td>
                          <td
                            style="
                              width: 30%;
                              border: none !important;
                              padding-left: 5px !important;
                            "
                          >
                            <strong>INDONESIA</strong>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style="width: 50%">
                      <table style="width: 100%; border: none !important">
                        <tr>
                          <td
                            rowspan="3"
                            style="
                              border: none !important;
                              padding: 5px !important;
                            "
                          >
                            Consignee Phone : {{ order.recipientPhone }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              padding: 5px !important;
                              border-right: none !important;
                            "
                          >
                            KODEPOS
                            <strong>{{ order.recipientPostCode }}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              padding: 5px !important;
                              border-bottom: none !important;
                              border-right: none !important;
                            "
                          >
                            <strong>{{ order.recipientCountry }}</strong>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td style="width: 50%">
                      <strong>SHIPMENT DETAILS</strong>
                    </td>
                    <td style="width: 50%"><strong>DETAIL PRICE</strong></td>
                  </tr>
                  <tr>
                    <td style="width: 50%" rowspan="2" valign="top">
                      <table
                        style="
                          width: 95%;
                          margin: 10px;
                          padding: 5px !important;
                        "
                      >
                        <tr>
                          <td>Kategory</td>
                          <td colspan="4" style="padding: 5px !important">
                            {{ order.Kategory }}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 3px !important">Description</td>
                          <td style="padding: 3px !important" colspan="4">
                            <span
                              v-for="(desc, index) in order.itemDetails"
                              :key="index"
                            >
                              {{ index + 1 }}. {{ desc.ItemDescription }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 3px !important">Quantity</td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                          >
                            {{ totalQuantity(order.itemDetails) }}
                          </td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                            rowspan="2"
                          >
                            Length
                          </td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                            rowspan="2"
                          >
                            Width
                          </td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                            rowspan="2"
                          >
                            Height
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 3px !important">Value (USD)</td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                          >
                            {{ estimateUSD(order.itemDetails) }}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 3px !important">Weight</td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                          >
                            {{ order.totalWeight }}
                          </td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                          >
                            {{ order.collies.Panjang }}
                          </td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                          >
                            {{ order.collies.Lebar }}
                          </td>
                          <td
                            style="padding: 3px !important"
                            class="text-center"
                          >
                            {{ order.collies.Tinggi }}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 3px !important">HS Code</td>
                          <td style="padding: 3px !important" colspan="4">
                            {{ order.itemDetails[0].ItemHSCode }}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td style="width: 50%">
                      <table
                        style="width: 98%; border: none !important; margin: 5px"
                      >
                        <tr>
                          <td style="border: none !important">Delivery Cost</td>
                          <td style="border: none !important">:</td>
                          <td style="border: none !important">
                            {{ order.deviveryCost }}
                          </td>
                        </tr>
                        <tr>
                          <td style="border: none !important">Other Cost</td>
                          <td style="border: none !important">:</td>
                          <td style="border: none !important">Rp. 0</td>
                        </tr>
                        <tr>
                          <td style="border: none !important">Insurance</td>
                          <td style="border: none !important">:</td>
                          <td style="border: none !important">
                            {{ order.insurance }}
                          </td>
                        </tr>
                        <tr>
                          <td style="border: none !important">Discount</td>
                          <td style="border: none !important">:</td>
                          <td style="border: none !important">Rp. 0</td>
                        </tr>
                        <tr>
                          <td
                            style="
                              border-left: none !important;
                              border-bottom: none !important;
                              border-right: none !important;
                            "
                          >
                            Total Cost
                          </td>
                          <td
                            style="
                              border-left: none !important;
                              border-bottom: none !important;
                              border-right: none !important;
                            "
                          >
                            :
                          </td>
                          <td
                            style="
                              border-left: none !important;
                              border-bottom: none !important;
                              border-right: none !important;
                            "
                          >
                            <strong> {{ order.totalPrice }} </strong>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50%">
                      <table style="width: 100%; border: none !important">
                        <tr class="text-center">
                          <td style="border-left: none !important">
                            <strong>Official Sign</strong>
                          </td>
                          <td style="border-right;: none !important">
                            <strong>Shipper Sign</strong>
                          </td>
                        </tr>
                        <tr>
                          <td style="border-left: none !important">
                            &nbsp; <br />
                          </td>
                          <td style="border-right;: none !important">
                            &nbsp;<br />
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 100% !important;
                        font-size: 8px;
                        border-top: none !important;
                      "
                      colspan="2"
                    >
                      NOTES : <br />
                      <ol>
                        <li>
                          Semua pajak yang timbul di negara tujuan akan menjadi
                          tanggung jawab penerima atau pengirim.
                        </li>
                        <li>
                          Pengirim wajib memberitahukan secara detil paket yang
                          akan dikirim (uraian barang dan harga).
                        </li>
                        <li>
                          Jika terdapat perbedaan antara invoice dengan isi
                          paket maka segala konsekuensi yang timbul baik materil
                          maupun non materil dinegara tujuan menjadi tanggung
                          jawab pengirim.
                        </li>
                        <li>
                          Trolly Express tidak bertanggung jawab jika paket
                          ditahan oleh pihak Bea Cukai dinegara tujuan
                        </li>
                        <li>
                          Jika terjadi kehilangan karena kelalaian Trolly
                          express, maka Trolly express akan mengganti sesuai
                          dengan nilai invoice atau maksimal 100 USD.
                        </li>
                        <li>
                          Trolly Express tidak bertanggung jawab terhadap
                          kerusakan barang yang dikarena sifat barang itu
                          sendiri atau karena packing yang tidak baik.
                        </li>
                        <li>
                          Trolly Express tidak bertanggung jawab jika paket
                          harus di destroy (dimusnahkan) karena penerima /
                          pengirim tidak dapat dihubungi.
                        </li>
                        <li>
                          Trolly Express tidak bertanggung jawab atas kerugian
                          yang timbul (tanggung renteng) atas keterlambatan
                          pengiriman
                        </li>
                      </ol>
                    </td>
                  </tr>
                </table>
                <span style="font-size: 8px"
                  >Sheet 2 - For Customer | Estimate Time Delivery
                  {{ minEtd }} - {{ maxEtd }} days || Date :
                  {{ order.date }}
                </span>
              </v-main>
            </v-app>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "../../components/dashboard/LoadingComponent.vue";

import VueBarcode from "vue-barcode";
import QrcodeVue from "qrcode.vue";
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../../constant/url";
import { getTokenCookie } from "../../utils/cookies";
import { formatToRupiah } from "../../utils/formatter";
import trollyLogo from "../../assets/images/trolly-logo.png";
import eparcelLogo from "../../assets/images/eparcel.png";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Receipt",
  components: {
    barcode: VueBarcode,
    QrcodeVue,
    LoadingComponent,
    VueHtml2pdf,
  },
  data() {
    return {
      trollyLogo,
      eparcelLogo,
      barcodeValue: "",
      qrValue: "",
      order: {},
      isLoading: true,
      htmlToPdfOptions: {
        margin: 0,
        DisablePdfCompression: 1,
        image: {
          type: "jpeg",
          quality: 0.95,
        },

        enableLinks: false,

        html2canvas: {
          dpi: 300,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },

        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
        },
      },
      priceUSD: 0,
      phone: 0,
      minEtd: "",
      maxEtd: "",
    };
  },
  methods: {
    print() {
      try {
        window.scrollTo({ top: 0 });
        this.$refs.html2PdfInvoice.generatePdf();
      } catch (error) {
        console.log(error);
      }
    },
    fetchDetail() {
      this.isLoading = true;
      const wbNumber = this.$route.query.wb;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrdersInter/${wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const data = res.data.data;
          const { ShipperLoc } = data.orderResult.Order;
          const { RecipientInter } = data.orderResult.Order;
          this.priceUSD = Number(data.priceUSD.ValueConstant);
          this.phone = data.phone.ValueConstant;
          this.barcodeValue = data.orderResult.WBNumber.replace(/ /g, "");
          this.qrValue = data.orderResult.WBNumber.replace(/ /g, "");

          this.order = {
            date: moment(data.orderResult.DateCreated).format("ll"),
            time: moment(data.orderResult.DateCreated).format("LT"),
            cityOrigin: res.data.data.orderResult.CodeCountry,
            itemDetails: data.orderResult.Order.ItemDetails,
            hubOrigin: data.orderResult.Order.Hub.NamaHub,
            hubAlamat: data.orderResult.Order.Hub.Alamat,
            hubPhone: data.orderResult.Order.Hub.Telp,
            collies: data.orderResult.InboundCollies[0],
            packingPrice: formatToRupiah(data.orderResult.TotalBiayaPacking),
            price: formatToRupiah(data.orderResult.TotalBiaya),
            totalPrice: formatToRupiah(
              data.orderResult.TotalBiayaPacking + data.orderResult.TotalBiaya
            ),
            deviveryCost: formatToRupiah(
              data.orderResult.TotalBiaya - data.orderResult.Order.Insurance
            ),
            shipperAlamat: `${data.orderResult.Order.Shipper.City.NamaCity} , ${ShipperLoc.KodePos}`,
            totalWeight: data.orderResult.TotalBerat,
            totalColly: data.orderResult.TotalKoli,
            wbNumber: data.orderResult.WBNumber,
            description: data.orderResult.Order.Keterangan,
            shipperName: ShipperLoc.Nama,
            shipperAddress: `${ShipperLoc.Alamat}, Kel. ${ShipperLoc.NamaKelurahan}, Kec. ${ShipperLoc.NamaKecamatan}, ${ShipperLoc.NamaCity}, ${ShipperLoc.KodePos}`,
            shipperPhone: ShipperLoc.Telp,
            shipperPostCode: ShipperLoc.KodePos,
            recipientCountry: RecipientInter.Country.CountryName.toUpperCase(),
            recipientName: RecipientInter.Nama,
            recipientAddress: `${RecipientInter.Alamat}`,
            recipientPhone: RecipientInter.Telp,
            recipientPostCode: RecipientInter.KodePos,
            isInsurance: data.orderResult.Order.IsInsurance,
            insurance: formatToRupiah(data.orderResult.Order.Insurance),
            estimasiNilai: formatToRupiah(data.orderResult.EstimasiNilai),
            IDCountry: RecipientInter.Country.IDCountry,
            ShipperTelp: data.orderResult.Order.Shipper.Telp,
            Kategory: data.orderResult.Order.Kategory,
            IDType: RecipientInter.IDType,
            IDNo: RecipientInter.IDNo,
          };

          //get ETD
          this.getPrice(this.order);
          this.isLoading = false;
          // let self = this;

          // setTimeout(function () {
          //   self.print();
          // }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },

    getPrice(order) {
      axios({
        url: `${baseUrl}/CheckPriceInternational`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Koli: 1,
          Length: 0,
          Height: 0,
          Width: 0,
          Weight: order.totalWeight,
          IDCountry: order.IDCountry,
        },
      })
        .then((res) => {
          this.minEtd = res.data.data.minEtd;
          this.maxEtd = res.data.data.maxEtd;
        })
        .catch(() => {});
    },
    totalQuantity(items) {
      return items.reduce((acc, obj) => {
        return Number(acc) + Number(obj.ItemPieces);
      }, 0);
    },
    estimateUSD(items) {
      let allEstimate = items.reduce((acc, obj) => {
        return Number(acc) + Number(obj.ItemPrice);
      }, 0);

      return (allEstimate / this.priceUSD).toFixed(2);
    },
  },
  created() {
    this.fetchDetail();
  },
};
</script>

<style lang="scss" scoped>
.ReceiptDocument {
  background-color: grey;
  min-height: 100vh;

  #PrintReceiptAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;

    h4 {
      color: white;
    }
  }
}

@media print {
  .ReceiptDocument {
    background-color: white;
  }

  #PrintReceiptAction {
    visibility: hidden;
  }

  #Receipt {
    position: absolute;
    top: 0;
    margin: 10px auto;
    width: 400px;
  }
}

.sprint {
  font-size: 12px !important;
  margin: 10px;
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0px !important;
    // border: none !important;
  }
}
</style>
