<template>
  <div class="DialogAssignOrderComponent">
    <v-dialog v-model="dialogAssignOrder" persistent max-width="600px">
      <v-card>
        <v-card-title> Assign Order to Hub </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Order Id</p>
                <p class="ma-0">
                  <strong>{{ order.orderId }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Date</p>
                <p class="ma-0">
                  <strong>{{ order.date }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Origin</p>
                <p class="ma-0">
                  <strong>{{ order.origin }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Destintation</p>
                <p class="ma-0">
                  <strong>{{ order.destination }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Status</p>
                <p class="ma-0">
                  <strong>{{ order.status }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>
          </v-container>

          <br />

          <v-form class="Form">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="Pilih Hub"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
          >
            assign hub
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogAssignOrderComponent",
  props: ["order"],
  data() {
    return {
      dialogAssignOrder: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogAssignOrder = !this.dialogAssignOrder;
    },
  },
};
</script>

<style lang="scss" scoped></style>
