<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    // check login
    console.log(process.env.NODE_ENV);
  },
  data: () => ({
    //
  }),
};
</script>
