<template>
  <div>
    <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
      :value="showAlert"
    >
      {{ message }}
    </v-alert>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="container ma-0 mr-0 form-financial"
    >
      <div class="d-flex flex-row justify-space-between">
        <div class="d-flex flex-row align-start">
          <!-- <v-text-field
            class="mr-2"
            v-model="startDate"
            type="date"
            outlined
            label="From"
            dense
            :rules="[(v) => !!v || 'You must pick date from to searching!']"
          ></v-text-field> -->
          <v-menu
            ref="menu"
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="From"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              scrollable
              @input="menuDate = false"
            >
            </v-date-picker>
          </v-menu>

          <!-- <v-text-field
            v-model="endDate"
            class="mr-4"
            type="date"
            outlined
            label="To"
            dense
            :rules="[(v) => !!v || 'You must pick date to for searching!']"
          ></v-text-field> -->
          <v-menu
            ref="menu"
            v-model="menuDateend"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                label="To"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              no-title
              scrollable
              @input="menuDateend = false"
            >
            </v-date-picker>
          </v-menu>

          <v-btn
            depressed
            color="cyan"
            class="mr-2 white--text"
            @click="submit"
          >
            Search
          </v-btn>
        </div>
        <div>
          <download-excel
            :data="datas"
            :name="excelFileName"
            :fields="jsonFields"
          >
            <v-btn depressed color="primary">Download Data</v-btn>
          </download-excel>
        </div>
      </div>
    </v-form>

    <v-data-table
      :headers="headers"
      :items="datas"
      :search="idOrder"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
      }"
    >
      <template v-slot:[`item.hubName`]="{ item }">
        {{ item.idHub }} - {{ item.namaHub }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2 white--text"
          small
          color="cyan"
          @click="showDetail(item)"
        >
          detail
        </v-btn>
      </template>
    </v-data-table>

    <dialog-detail-financial-report
      ref="detailFinancialReport"
      :propDetail="selectedData"
      :userProfile="userProfile"
    />
  </div>
</template>

<script>
// import moment from "moment";
import timezone from "moment-timezone";
import { formatToRupiah } from "../../../utils/formatter";
import { getFinancialReport } from "../../../services/hub";
import { getUserProfile } from "../../../services/user";
import DialogDetailFinancialReport from "./DialogDetailFinancialReport.vue";
export default {
  name: "FinancialReport",
  components: {
    DialogDetailFinancialReport,
  },
  data: () => ({
    valid: false,
    menuDate: false,
    menuDateend: false,
    idOrder: "",
    sortBy: [
      "date",
      "idHo",
      "idHub",
      "service",
      "total_qty",
      "ongkir",
      "totalAmount",
    ],
    headers: [
      { text: "Tanggal Transaksi", value: "date" },
      { text: "ID HO", value: "idHo", filterable: false },
      { text: "HUB", value: "hubName" },
      // { text: "Service", value: "service", filterable: false },
      // { text: "Produk", value: "", filterable: false },
      { text: "Qty", value: "total_qty", filterable: false },
      // { text: "Ongkos Kirim", value: "ongkir", filterable: false },
      // { text: "Biaya Packing", value: "biayaPacking", filterable: false },
      // { text: "Premi", value: "insurance", filterable: false },
      // { text: "PPN", value: "total_ppn", filterable: false },
      { text: "Total", value: "totalAmount", filterable: false },
      { text: "Action", value: "action", filterable: false },
    ],
    jsonFields: {
      "Tanggal Transaksi": "date",
      "ID HO": "idHo",
      "ID HUB": "idHub",
      Service: "service",
      Produk: "",
      // Qty: "total_qty",
      // "Ongkos Kirim": "ongkir",
      // Premi: "insurance",
      // PPN: "total_ppn",
      Total: "totalAmount",
    },
    datas: [],
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    showAlert: false,
    message: "",
    selectedData: {},
    userProfile: {},
    isLoading: false,
  }),
  computed: {
    excelFileName() {
      const startDate = timezone(this.startDate)
        .tz("Asia/Jakarta")
        .format("DDMMYYYY");
      const endDate = timezone(this.endDate)
        .tz("Asia/Jakarta")
        .format("DDMMYYYY");
      return `Financial${this.userProfile.KCX_HOIDHO}-${startDate}to${endDate}`;
    },
  },
  async mounted() {
    // const now = moment().format("YYYY-MM-DD");
    // const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
    const startOfMonth = timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = timezone()
      .tz("Asia/Jakarta")
      .endOf("month")
      .format("YYYY-MM-DD");
    const resp = await getUserProfile();
    this.startDate = startOfMonth;
    this.endDate = endOfMonth;
    this.getData(startOfMonth, endOfMonth);
    this.userProfile = resp;
  },
  methods: {
    async getData(startDate, endDate) {
      const resp = await getFinancialReport(startDate, endDate);
      this.datas = resp.map((v) => {
        return {
          plain_date: v.date,
          date: timezone(v.date).tz("Asia/Jakarta").format("DD/MM/YYYY"),
          idHub: v.IDHub,
          idHo: v.IDHo,
          namaHub: v.Hub.NamaHub,
          totalAmount: formatToRupiah(parseFloat(v.total_amount)),
          biayaPacking: formatToRupiah(parseFloat(v.totalBiayaPacking)),
          insurance: formatToRupiah(parseFloat(v.insurance)),
          total_qty: parseInt(v.total_qty),
          total_ppn: parseFloat(v.total_ppn),
          // service: "DOMESTIC/EXPRESS",
          ongkir: formatToRupiah(
            parseFloat(v.total_amount) -
              (parseFloat(v.insurance) +
                parseFloat(v.total_ppn) +
                parseFloat(v.totalBiayaPacking))
          ),
        };
      });
    },
    submit() {
      this.showAlert = false;
      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.message = "your end date must before the start date";
        this.showAlert = true;
        return;
      }

      this.getData(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
    },
    showDetail(item) {
      // console.log(item);
      this.selectedData = item;
      this.$refs.detailFinancialReport.toggleDialog();
    },
  },
};
</script>

<style scoped>
.form-financial {
  max-width: 100% !important;
  width: 100%;
}
</style>
