const env = process.env.NODE_ENV;
import Cookies from "js-cookie";

export const cookiesName = {
  userProfile: "UserProfileHub",
  tokenKey: "TokenKeyHub",
};
let domain;
if (env == "development") {
  domain = "";
} else if (env == "production") {
  domain = { domain: "trolly.asia" };
}

export function getTokenCookie() {
  return Cookies.get(cookiesName.tokenKey);
}
export function setTokenCookie(value) {
  return Cookies.set(cookiesName.tokenKey, value, { expires: 7 });
}
export function removeTokenCookie() {
  Cookies.remove(cookiesName.tokenKey, domain);
  return Cookies.remove(cookiesName.tokenKey);
}
export function getProfileCookie() {
  return JSON.parse(Cookies.get(cookiesName.userProfile));
}
export function setProfileCookie(value) {
  return Cookies.set(cookiesName.userProfile, JSON.stringify(value), {
    expires: 7,
  });
}
export function removeProfileCookie() {
  Cookies.remove(cookiesName.userProfile, domain);
  return Cookies.remove(cookiesName.userProfile);
}
