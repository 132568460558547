<template>
  <div class="DialogViewVendorComponent">
    <v-dialog v-model="dialogViewVendor" persistent max-width="600px">
      <v-card>
        <v-card-title> Detail Vendor </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Id</p>
                <p class="ma-0">
                  <strong>{{ vendor.id }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Name</p>
                <p class="ma-0">
                  <strong>{{ vendor.name }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">PIC</p>
                <p class="ma-0">
                  <strong>{{ vendor.pic }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Contact</p>
                <p class="ma-0">
                  <strong>{{ vendor.contact }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Email</p>
                <p class="ma-0">
                  <strong>{{ vendor.email }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Kota</p>
                <p class="ma-0">
                  <strong>{{ vendor.city }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Kelurahan</p>
                <p class="ma-0">
                  <strong>{{ vendor.village }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-space-between pa-0">
                <p class="ma-0">Alamat</p>
                <p class="ma-0">
                  <strong>{{ vendor.address }}</strong>
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 pb-1"><hr /></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogViewVendorComponent",
  props: ["vendor"],
  data() {
    return {
      dialogViewVendor: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogViewVendor = !this.dialogViewVendor;
    },
  },
};
</script>

<style lang="scss" scoped></style>
