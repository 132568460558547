<template>
  <div class="Dashboard">
    <v-navigation-drawer v-model="drawer" app>
      <SideBarComponent />
    </v-navigation-drawer>

    <v-main class="Dashboard-Content content">
      <HeaderComponent @toggleSidebar="drawer = !drawer" />
      <div class="Dashboard-Container">
        <router-view></router-view>
      </div>
    </v-main>
  </div>
</template>

<script>
import HeaderComponent from "../../components/dashboard/HeaderComponent.vue";
import SideBarComponent from "../../components/dashboard/SideBarComponent.vue";

export default {
  name: "Dashboard",
  components: {
    HeaderComponent,
    SideBarComponent,
  },
  data() {
    return {
      drawer: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.Dashboard {
  // display: grid;
  // grid-template-columns: auto 1fr;
  min-height: 100%;

  &-Content {
    min-height: 100%;
    background-color: #f8feff;
  }

  &-Container {
    padding: 16px;
  }
}
</style>
