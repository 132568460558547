<template>
  <div class="DialogAddPackingList">
    <v-dialog v-model="dialogAddDetails" persistent width="70%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" depressed v-bind="attrs" v-on="on">
          Add details
        </v-btn>
      </template>
      <v-card>
        <v-card-title> Add Packing List </v-card-title>

        <br />

        <v-container>
          <v-row>
            <!-- <v-col cols="1"> <h4>Kota Asal</h4></v-col>
            <v-col cols="4">
              <v-combobox
                outlined
                dense
                :rules="rules"
                :items="cityList"
                :value="cityOrigin"
                @input="setCityOrigin"
              ></v-combobox>
            </v-col> -->

            <v-col cols="1 ml-4"> <h4>Kota Tujuan</h4></v-col>

            <v-col cols="4">
              <v-combobox
                outlined
                dense
                v-model="searchCity"
                :rules="rules"
                :items="cityList"
                :value="cityDestination"
                @input="setCityDestination"
              ></v-combobox>
            </v-col>
            <v-col cols="4">
              <v-combobox
                outlined
                dense
                :rules="rules"
                :items="countryData"
                :value="countryText"
                v-model="searchCountry"
                label="Country Destination"
                @input="setCountry"
                item-text="CountryName"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-container>

        <v-card-text>
          <!-- <v-container class="ma-0">
            <v-row>
              <v-col sm="4" class="pa-0">
                <v-text-field
                  label="Packing List Id"
                  outlined
                  dense
                  v-model="idPackinglist"
                ></v-text-field>
              </v-col>

              <v-col sm="1" class="pa-0 pl-2">
                <v-btn
                  :loading="isLoading"
                  block
                  depressed
                  @click="addPackinglist"
                  color="secondary"
                  >add</v-btn
                >
              </v-col>
            </v-row>
          </v-container> -->

          <div>
            <h2 class="mb-2">Packing List</h2>

            <v-data-table
              :headers="headers"
              :items="filteredPackingDatas"
              :sort-desc="[true]"
              :loading="isLoading"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  v-if="!checkAddedPacking(item.packingId)"
                  depressed
                  class="mr-2"
                  small
                  color="success"
                  @click="addToManifest(item)"
                >
                  Add To Manifest
                </v-btn>
                <v-btn
                  v-else
                  depressed
                  class="mr-2"
                  small
                  color="warning"
                  @click="removeFromManifest(item)"
                >
                  Delete From Manifest
                </v-btn>
              </template>
            </v-data-table>
            <!-- <v-data-table :headers="headers" :items="items">
              <template v-slot:[`item.action`]="{ item }">
                <v-btn depressed small color="error" @click="deleteItem(item)">
                  <v-icon color="white"> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table> -->
          </div>
        </v-card-text>

        <v-divider> </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="dialogAddDetails = false">
            cancel
          </v-btn>

          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="onClickOk()"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import { groupBy } from "../../../utils/array";
import cityData from "../../../data/city.json";
import { mapState, mapActions } from "vuex";

export default {
  name: "DialogAddPackingList",
  props: ["propManifestItems"],
  data() {
    return {
      rules: [],
      isLoading: false,
      dialogAddDetails: false,
      idPackinglist: "",
      snackbar: false,
      snackbarText: "",
      headers: [
        { text: "Packing Id", value: "packingId", filterable: false },
        { text: "Date", value: "date", filterable: false },
        { text: "Origin", value: "origin", filterable: false },
        { text: "Tujuan", value: "destination", filterable: false },
        { text: "Total Koli", value: "totalColly", filterable: false },
        { text: "Total Berat", value: "totalWeight", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      packingDatas: [],
      listCityDestination: [],
      cityOrigin: null,
      cityDestination: null,
      idCityOrigin: null,
      idCityDestination: null,
      countryText: null,
      destinationInter: null,
      searchCountry: "",
      searchCity: "",
    };
  },
  mounted() {
    this.fetchPackingList();
    this.getCountry();
  },
  computed: {
    ...mapState({
      countryData: (state) => state.shipper.countryData,
    }),
    manifestItems() {
      return this.propManifestItems.map((v) => {
        return v.PackingListIDPackingList;
      });
    },
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    filteredPackingDatas() {
      let tempPackingData = this.packingDatas;
      if (this.cityDestination !== null || this.countryText !== null) {
        tempPackingData = tempPackingData.filter((v) => {
          return v.destination === this.cityDestination;
        });
      }
      // console.log(tempPackingData);
      return tempPackingData;
    },
  },
  methods: {
    ...mapActions({
      getCountry: "shipper/getCountry",
    }),
    deleteItem(item) {
      const filteredItems = this.items.filter(
        (el) => el.packingId != item.packingId
      );
      this.items = filteredItems;
      this.$emit("deletePackingList", item);
    },
    // addPackinglist() {
    //   this.isLoading = true;

    //   axios({
    //     url: `${baseUrl}/PackingList/Find?idPackinglist=${this.idPackinglist}`,
    //     method: "get",
    //     headers: {
    //       Authorization: `Bearer ${getTokenCookie()}`,
    //     },
    //   })
    //     .then((res) => {
    //       const { data } = res.data;
    //       this.idPackinglist = "";
    //       this.isLoading = false;

    //       this.items.push({
    //         packingId: data.IDPackingList,
    //         date: moment(data.Date).format("YYYY-MM-DD, HH:mm"),
    //         totalColly: data.TotalKoli,
    //         totalWeight: data.TotalBerat,
    //       });

    //       this.$emit("savePackingList", {
    //         packingId: data.IDPackingList,
    //         date: moment(data.Date).format("YYYY-MM-DD, HH:mm"),
    //         totalColly: data.TotalKoli,
    //         totalWeight: data.TotalBerat,
    //       });
    //     })
    //     .catch((err) => {
    //       const { data } = err.response;
    //       this.idPackinglist = "";
    //       this.isLoading = false;

    //       this.snackbarText = data.message.error[0];
    //       this.snackbar = true;
    //     });
    // },
    fetchPackingList() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/PackingList`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hub: hubId,
        },
      })
        .then((res) => {
          const filteredData = res.data.data.filter(
            (el) => el.IDPackingList != 0
          );

          filteredData.forEach((packingList) => {
            if (!this.manifestItems.includes(packingList.IDPackingList)) {
              this.packingDatas.push({
                packingId: packingList.IDPackingList,
                date: moment(packingList.Date).format("YYYY-MM-DD, HH:mm"),
                destination: packingList.KotaTujuan,
                origin: packingList.KotaAsal,
                totalColly: packingList.TotalKoli,
                totalWeight: packingList.TotalBerat,
              });
            }
          });
          // console.log(this.packingDatas);

          this.isLoading = false;
        })
        .catch(() => {
          this.packingDatas = [];
          this.isLoading = false;
        });
    },
    getPackingById(item) {
      this.isLoading = true;
      const packingId = item.packingId;

      axios({
        url: `${baseUrl}/PackingList/Find?idPackinglist=${packingId}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          const { data } = res.data;
          this.isLoading = false;

          // console.log(item);
          this.listCityDestination.push(item.destination);
          this.items.push({
            packingId: data.IDPackingList,
            date: moment(data.Date).format("YYYY-MM-DD, HH:mm"),
            totalColly: data.TotalKoli,
            totalWeight: data.TotalBerat,
            orderType: data.OrderType,
            destination: item.destination,
            vendor: data.Vendor,
            moda: data.Moda,
            dfod: data.Dfod,
          });

          this.$emit("savePackingList", {
            packingId: data.IDPackingList,
            date: moment(data.Date).format("YYYY-MM-DD, HH:mm"),
            totalColly: data.TotalKoli,
            totalWeight: data.TotalBerat,
            orderType: data.OrderType,
            destination: item.destination,
            vendor: data.Vendor,
            moda: data.Moda,
            dfod: data.Dfod,
          });
        })
        .catch((err) => {
          const { data } = err.response;
          this.isLoading = false;

          this.snackbarText = data.message.error[0];
          this.snackbar = true;
        });
    },
    addToManifest(item) {
      this.getPackingById(item);
    },
    checkAddedPacking(packingId) {
      const filtered = this.items.filter((v) => {
        return packingId === v.packingId;
      });
      return filtered.length > 0;
    },
    removeFromManifest(item) {
      this.items = this.items.filter((v) => {
        return item.packingId !== v.packingId;
      });
    },
    setCityDestination(e) {
      if (e) {
        const cityName = e.split(",")[0];
        const city = cityData.find(({ city }) => city == cityName);

        // console.log(city);
        if (city) {
          this.cityDestination = cityName;
          this.idCityDestination = city.idCity;

          this.destinationInter = null;
          this.countryText = null;
          this.searchCountry = "";
        }
      } else {
        this.cityDestination = null;
        this.idCityDestination = "";
        this.searchCity = "";
        this.packingDatas = [];
        this.fetchPackingList();
      }
    },
    setCountry(e) {
      if (e) {
        this.destinationInter = e.IDCountry;
        this.cityDestination = e.CountryName;

        // this.cityDestination = "";
        this.idCityDestination = "";
        this.searchCity = "";
      } else {
        this.cityDestination = null;
        this.countryText = null;
        this.packingDatas = [];
        this.fetchPackingList();
      }
    },
    setCityOrigin(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.cityOrigin = cityName;
        this.idCityOrigin = city.idCity;
      }
    },
    onClickOk() {
      if (this.items < 1) {
        this.dialogAddDetails = false;
        return;
      }

      const groupedItemByDestination = groupBy("orderType");
      const groupedItemByVendor = groupBy("vendor");
      // const groupedItemByDfod = groupBy("dfod");
      const groupedItemByModa = groupBy("moda");
      const result = groupedItemByDestination(this.items);
      const resultVendor = groupedItemByVendor(this.items);
      // const resultDfod = groupedItemByDfod(this.items);
      const resultModa = groupedItemByModa(this.items);
      // console.log(this.items);
      // console.log(groupedItemByDestination);
      if (
        Object.keys(result).length > 1 ||
        Object.keys(resultVendor).length > 1 ||
        // Object.keys(resultDfod).length > 1 ||
        Object.keys(resultModa).length > 1
      ) {
        this.snackbarText = "Cannot pick manifest with different order type";
        this.snackbar = true;
        this.items = [];
        this.listCityDestination = [];
        this.$emit("removeListOrder");
        return;
      }

      const cityIndex = cityData.findIndex((v) => {
        return v.city === this.items[0].destination;
      });
      const countryIndex = this.countryData.findIndex((v) => {
        return v.CountryName === this.items[0].destination;
      });

      let cityDes = this.listCityDestination.filter(
        (v, i, a) => a.indexOf(v) === i
      );

      if (cityData[cityIndex]) {
        this.$emit("setDestinationCity", {
          // cityDestination: cityData[cityIndex].city,
          cityDestination: cityDes,
          idCityDestination: cityData[cityIndex].idCity,
          moda: this.items[0].moda,
          orderType: "domestic",
        });
      } else {
        this.$emit("setDestinationCity", {
          cityDestination: cityDes,
          // cityDestination: this.countryData[countryIndex].CountryName,
          idCityDestination: this.countryData[countryIndex].IDCountry,
          moda: this.items[0].moda,
          orderType: "international",
        });
      }
      // console.log(this.items[0].moda);
      this.dialogAddDetails = false;
    },
    clearList() {
      this.items = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.Form {
  display: flex;
  gap: 20px;
}
</style>
