<template>
  <div class="Irregularity">
    <div class="Irregularity-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="wbNumber"
              label="WB Number"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="wbNumber"
      :sort-by="sortBy"
    >
      <!-- <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed small color="error" @click="printDrs(item)">
          print DRS
        </v-btn>
      </template> -->
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "Irregularity",
  data() {
    return {
      wbNumber: null,
      sortBy: [
        "wbNumber",
        "date",
        "recipient",
        "destination",
        "address",
        "colly",
        "weight",
        "status",
      ],
      headers: [
        { text: "WB Number", value: "wbNumber" },
        { text: "Date", value: "date", filterable: false },
        { text: "Penerima", value: "recipient", filterable: false },
        { text: "Destination", value: "destination", filterable: false },
        { text: "Alamant", value: "address", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Status", value: "status", filterable: false },
        // { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [
        // {
        //   wbNumber: "123212",
        //   drsId: "23433",
        //   date: "12/07/2021",
        //   recipient: "Vindit",
        //   destination: "Bandung",
        //   address: "Suryalaya Tengah No.5, Kec. Lengkong, Kel. Cijagra",
        //   colly: 2,
        //   weight: 20,
        //   status: "missing",
        // },
        // {
        //   wbNumber: "123213",
        //   drsId: "23433",
        //   date: "12/07/2021",
        //   recipient: "Mikhail Botvinic",
        //   destination: "Bandung",
        //   address: "Hasan Saputra NO. 33, Kec Reog, Kel. Turangga",
        //   colly: 1,
        //   weight: 10,
        //   status: "missing",
        // },
      ],
    };
  },
  methods: {
    // printDrs(item) {
    //   console.log(item);
    // },
  },
};
</script>

<style lang="scss" scoped>
.Irregularity {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
