var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-3",attrs:{"outlined":""}},[_c('div',{staticClass:"RecipientFormComponent"},[_c('div',{staticClass:"RecipientFormComponent-Header"},[_c('h3',[_vm._v(" Recipient Information ")]),_c('hr')]),_c('div',{staticClass:"RecipientFormComponent-Container"},[_c('v-container',[_c('v-row',[_c('v-select',{attrs:{"items":['REGULAR', 'DIRECT', 'GLOBAL'],"value":_vm.service,"item-text":"text","item-value":"value","label":"Service","dense":"","rules":_vm.rules},on:{"input":_vm.setService}}),_c('v-combobox',{attrs:{"label":"Receiver Country","dense":"","rules":_vm.rules,"items":_vm.filterCountry,"value":_vm.country,"item-text":"CountryName"},on:{"input":_vm.setCountry}})],1),_c('v-row',[_c('v-text-field',{attrs:{"rules":_vm.rule.namaPenerima,"value":_vm.recipientName,"label":"Nama Penerima","dense":""},on:{"input":_vm.setRecipientName}})],1),_c('v-row',[_c('v-text-field',{attrs:{"value":_vm.recipientEmail,"label":"Email","dense":"","type":"email","rules":_vm.rules},on:{"input":_vm.setRecipientEmail}})],1),_c('v-row',[_c('v-combobox',{attrs:{"label":"ID TYPE","dense":"","rules":_vm.recipientIDCountry == 209 || _vm.recipientIDCountry == 114
                  ? _vm.rules
                  : [],"items":[
                'National Identity (KTP)',
                'Tax Identication Number (NPWP)',
                'Passport',
                'Tax Collected Number',
              ],"value":_vm.idType,"item-text":"CountryName"},on:{"input":_vm.setIDType}})],1),_c('v-row',[_c('v-text-field',{attrs:{"rules":_vm.recipientIDCountry == 209 || _vm.recipientIDCountry == 114
                  ? _vm.rules
                  : [],"value":_vm.idNo,"label":"ID. NO","dense":""},on:{"input":_vm.setIDNo}})],1)],1),_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"rules":_vm.rule.nohp,"value":_vm.recipientContactPerson,"label":"PIC","dense":""},on:{"input":_vm.setRecipientContactPerson}})],1),_c('v-row',[_c('v-text-field',{attrs:{"rules":_vm.rule.nohp,"value":_vm.recipientPhone,"label":"Receiver Telephone","dense":""},on:{"input":_vm.setRecipientPhone}})],1),_c('v-row',[_c('v-text-field',{attrs:{"rules":_vm.rules,"value":_vm.postCode,"label":"Kode Pos","dense":"","type":"text"},on:{"input":_vm.setPostCode}})],1),_c('v-row',[_c('v-textarea',{attrs:{"rules":_vm.rule.alamat,"value":_vm.address,"label":"Alamat","rows":"3","no-resize":""},on:{"input":_vm.setAddress}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Shipper")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-combobox',{attrs:{"label":"Nama Penerima","items":_vm.recipientData,"item-text":"Nama","item-value":"IDShipper","dense":"","loading":false,"return-object":"","rules":_vm.rules},on:{"input":_vm.setRecipientAuto}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }