<template>
  <div>
    <div class="LabelDocument">
      <div>
        <div id="PrintLabelAction">
          <h4>Print Packing List</h4>
          <v-btn @click="print" color="warning">Print</v-btn>
        </div>
      </div>
    </div>

    <div class="PackingListTable" v-for="(packingList, id) in items" :key="id">
      <h4>Packing list - {{ packingList[0].packingId }}</h4>
      <v-data-table
        :headers="headers"
        :items="packingList"
        item-key="packingId"
        disable-pagination
        hide-default-footer
        class="table"
      />
    </div>
  </div>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
export default {
  name: "PackingListDoc",
  props: ["data", "id"],
  data() {
    return {
      headers: [
        { text: "Date", value: "date", filterable: false },
        {
          text: "Packing List ID",
          value: "packingId",
          filterable: false,
        },
        { text: "ID Hub", value: "idHub" },
        { text: "ID Pesanan", value: "idOrder" },
        { text: "No WB", value: "noWB", filterable: false },
        { text: "Moda ID", value: "modaId", filterable: false },
        { text: "Carrier", value: "carrier", filterable: false },
        { text: "Asal", value: "origin", filterable: false },
        { text: "Tujuan", value: "destination", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Tinggi", value: "height", filterable: false },
        { text: "Lebar", value: "wide", filterable: false },
        { text: "Panjang", value: "length", filterable: false },
        { text: "Total Koli", value: "totalKoli", filterable: false },
        { text: "Berat Total", value: "totalWeight", filterable: false },
        { text: "Status", value: "status", filterable: false },
        // { text: "Status", value: "status", filterable: false },
        // { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  mounted() {
    let data = this.$route.params.data;
    const items = data.map((el) => {
      const item = {
        ...el,
        date: el.date,
        packingId: el.packingId,
        destination: el.destination,
      };
      return el.details.map((detail) => {
        const newItem = {
          ...item,
          idOrder: detail.Order.IDOrder,
          noWB: detail.WBNumber,
          modaId: "DARAT",
          carrier: "Carrier 1",
          weight: detail.Order.TotalBerat,
          totalKoli: detail.Order.TotalKoli,
          status: detail.Order.PackageStatus.Status,
          origin: detail.Order.ShipperLoc.NamaCity,
          idHub: detail.Order.KCX_HubIDHub,
          height: detail.Order.Collies[0].Tinggi,
          wide: detail.Order.Collies[0].Lebar,
          length: detail.Order.Collies[0].Panjang,
          // from: detail
        };
        return newItem;
      });
    });

    this.items = items;
  },
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
<style lang="scss">
.LabelDocument {
  background-color: grey;
  // min-height: 100vh;

  #PrintLabelAction {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    padding: 10px 20px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  h4 {
    color: white;
  }
}
.PackingListTable {
  margin-bottom: 5em;
  .table {
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    // height: 100vh;
    // width: 100vw;
  }
}
@page {
  size: A4 landscape;
}
</style>
