<template>
  <div class="OrderConfirmation">
    <div class="OrderConfirmation-Option">
      <!-- <v-container fluid class="ma-0 w-full">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="idOrder"
              label="Order Id"
              type="number"
              clearable
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container> -->
      <v-form ref="form" v-model="valid" lazy-validation class="container ma-0">
        <v-container class="ma-0 mb-4">
          <v-row>
            <v-col sm="4" class="pa-0">
              <v-text-field
                class="mr-2"
                v-model="startDate"
                type="date"
                outlined
                label="From"
                dense
                :rules="[(v) => !!v || 'You must pick date from to searching!']"
              ></v-text-field>
            </v-col>

            <v-col sm="4" class="pa-0">
              <v-text-field
                v-model="endDate"
                type="date"
                outlined
                label="To"
                dense
                :rules="[(v) => !!v || 'You must pick date to for searching!']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="4" class="pa-0 pr-2">
              <v-combobox
                outlined
                dense
                :rules="rules"
                :items="cityList"
                :value="originText"
                label="City Origin"
                @input="setCity"
              ></v-combobox>
              <!-- <v-select
                class="mr-2"
                outlined
                dense
                label="Origin"
                item-text="text"
                item-value="value"
                :items="cityList"
                v-model="origin"
              ></v-select> -->
            </v-col>

            <v-col sm="4" class="pa-0 pr-2">
              <v-combobox
                outlined
                dense
                :rules="rules"
                :items="cityList"
                :value="destinationText"
                label="City Destination"
                @input="setCityDestination"
              ></v-combobox>
            </v-col>

            <v-col sm="4" class="pa-0">
              <v-combobox
                outlined
                dense
                :rules="rules"
                :items="countryData"
                :value="countryText"
                label="Country Destination"
                @input="setCountry"
                item-text="CountryName"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="4" class="pa-0 mr-2">
              <v-btn
                depressed
                color="cyan"
                class="mr-2 white--text"
                @click="search"
              >
                Search
              </v-btn>
              <v-btn depressed color="error" @click="refresh">refresh</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="idOrder"
      :sort-by="sortBy"
      :loading="isLoading"
      item-key="idOrder"
      :sort-desc="[true]"
      :expanded.sync="expanded"
      :single-expand="true"
      show-expand
    >
      <template v-slot:top>
        <dialog-detail-order-component
          :order="itemDetail"
          @refresh="fetchOrder()"
          ref="detailOrderDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2 white--text"
          small
          color="cyan"
          @click="view(item)"
        >
          detail
        </v-btn>
        <v-btn
          v-if="item.orderType == 'international'"
          depressed
          class="mr-2 white--text"
          small
          color="orange"
          @click="showResi(item)"
        >
          Resi
        </v-btn>
      </template>

      <template v-slot:[`item.orderType`]="{ item }">
        {{ item.orderType }} /
        <v-chip v-if="item.dfod" color="green" text-color="white" x-small>
          DFOD
        </v-chip>
        <v-chip v-else color="orange" text-color="white" x-small> CASH </v-chip>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-detail-order :order="item.detailColly" />
        </td>
      </template>

      <template v-slot:[`item.modaType`]="{ item }">
        {{ modaType(item) }}
      </template>
    </v-data-table>

    <v-dialog v-model="showDialog" persistent width="600px">
      <v-card>
        <v-card-title class="text-h5">No. Resi</v-card-title>
        <v-card-text>Resi pengiriman ke Jakarta</v-card-text>
        <v-card-actions>
          <v-container v-if="isLoading" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="No. Resi"
                  type="text"
                  outlined
                  dense
                  v-model="resi3PL"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  depressed
                  class="white--text"
                  color="orange"
                  @click="updateResi"
                  small
                >
                  Update Resi
                </v-btn>
                &nbsp;
                <v-btn
                  depressed
                  class="white--text mr-2"
                  color="red"
                  @click="showDialog = false"
                  small
                >
                  close
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import axios from "axios";
import DialogDetailOrderComponent from "../../../components/dashboard/inbound/DialogDetailOrderComponent.vue";

import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import { formatToRupiah } from "../../../utils/formatter";
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { mapState, mapActions } from "vuex";
import DropDownDetailOrder from "../../../components/dashboard/inbound/DropDownDetailOrder.vue";

export default {
  components: { DialogDetailOrderComponent, DropDownDetailOrder },
  name: "OrderConfirmation",
  data() {
    return {
      idOrder: "",
      itemDetail: {},
      rules: [],
      valid: false,
      showDialog: false,
      isLoading: false,
      sortBy: [
        "date",
        "shipper",
        "recipient",
        "colly",
        "weight",
        "price",
        "status",
      ],
      headers: [
        { text: "Order Id", value: "idOrder" },
        { text: "WB Number", value: "wbNumber", filterable: false },
        { text: "Date", value: "date" },
        { text: "Pengirim", value: "shipper", filterable: false },
        { text: "Kota Pengirim", value: "origin", filterable: false },
        { text: "Penerima", value: "recipient", filterable: false },
        { text: "Kota Penerima", value: "destination", filterable: false },
        { text: "Type", value: "orderType", filterable: false },
        { text: "Moda", value: "modaType", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Total Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      expanded: [],
      startDate: "",
      endDate: "",
      origin: "",
      destination: "",
      destinationInter: "",
      originText: "",
      destinationText: "",
      countryText: "",
      status: "",
      formValidation: {
        dateForm: [(v) => v.length < 1 && this.endDate.length > 0],
      },
      resi3PL: "",
      wbNumber: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapState({
      countryData: (state) => state.shipper.countryData,
    }),
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];
      if (this.cityId) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.cityId;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      getCountry: "shipper/getCountry",
    }),
    view(item) {
      this.itemDetail = item;
      this.$refs.detailOrderDialog.toggleDialog();
    },
    fetchOrder() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;
      let search = ``;
      if (this.startDate !== "") search = `dateFrom=${this.startDate}`;
      if (this.endDate !== "") search = `${search}&dateTo=${this.endDate}`;
      if (this.origin !== "") search = `${search}&origin=${this.origin}`;
      if (this.destination !== "")
        search = `${search}&destination=${this.destination}`;
      if (this.destinationInter !== "")
        search = `${search}&destinationInter=${this.destinationInter}`;

      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders?${search}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23",
          hub: hubId,
        },
      })
        .then((res) => {
          // console.log(res);
          this.items = res.data.data.map((order) => {
            try {
              let dest, namaShipper;
              if (order.Order.Recipient) {
                dest = order.Order.Recipient.City.NamaCity;
                namaShipper = order.Order.Recipient.Nama;
              } else {
                dest = order.Order.RecipientInter.Country.CountryName;
                namaShipper = order.Order.RecipientInter.Nama;
              }
              return {
                detail: order,
                idOrder: order.OrderIDOrder,
                wbNumber: order.WBNumber,
                date: moment(order.DateCreated)
                  // .add(7, "hours")
                  .format("YYYY-MM-DD, HH:mm"),
                origin: order.Order.ShipperLoc.NamaCity,
                shipper: order.Order.ShipperLoc.Nama,
                phone: order.Order.ShipperLoc.Telp,
                recipient: namaShipper,
                destination: dest,
                orderType: order.Order.OrderType,
                colly: order.TotalKoli,
                weight: order.TotalBerat,
                status: order.PackageStatus.Status,
                idStatus: order.PackageStatus.IDPackageStatus,
                price: formatToRupiah(order.TotalBiaya),
                insurance: order.Order.Insurance,
                isInsurance: order.Order.IsInsurance,
                detailColly: order.Order.Collies,
                price3PL: order.Price3PL,
                resi3PL: order.Resi3PL,
                vendor: order.Order.Vendor,
                kategory: order.Order.Kategory,
                moda: order.Order.Moda,
                dfod: order.Order.Dfod,
                linkPOD: order.Order.LinkPOD,
              };
            } catch (error) {
              console.log(error);
            }
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    async showResi(item) {
      this.showDialog = true;
      this.isLoading = true;
      this.wbNumber = item.wbNumber;

      let response = await axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders/${this.wbNumber}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.resi3PL = response.data.data.orderResult.Resi3PL;
      this.isLoading = false;
    },
    async updateResi() {
      try {
        let response = await axios({
          method: "put",
          url: `${baseUrl}/HubInboundOrders/resi/${this.wbNumber}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: { resi: this.resi3PL },
        });
        this.showDialog = false;
        this.snackbar = true;
        this.snackbarText = response.data.message.info[0];
        this.wbNumber = "";
        this.resi3PL = "";
      } catch (error) {
        this.isLoading = false;
        this.snackbar = true;
        this.snackbarText = error.response.data.message.error[0];
      }
    },
    search() {
      this.fetchOrder();
    },
    refresh() {
      Vue.set(this, "startDate", "");
      Vue.set(this, "endDate", "");
      Vue.set(this, "origin", "");
      Vue.set(this, "destination", "");
      Vue.set(this, "originText", "");
      Vue.set(this, "destinationText", "");
      Vue.set(this, "destinationInter", "");
      Vue.set(this, "countryText", "");
      this.$refs.form.resetValidation();
      this.fetchOrder();
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);
      this.origin = city.idCity;
      this.originText = e;
    },
    setCityDestination(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);
      this.destination = city.idCity;
      this.destinationText = e;

      this.destinationInter = "";
      this.countryText = "";
    },
    setCountry(e) {
      this.destinationInter = e.IDCountry;
      this.countryText = e.CountryName;

      this.destination = "";
      this.destinationText = "";
    },
    modaType(data) {
      if (data.orderType == "domestic") {
        return data.moda ? data.moda : "DARAT";
      } else {
        return "EXPRESS";
      }
    },
  },
  created() {
    this.fetchOrder();
    this.getCountry();
  },
};
</script>

<style lang="scss" scoped>
.OrderConfirmation {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
