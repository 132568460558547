<template>
  <div>
    <v-dialog v-model="dialogEditUser" persistent width="600px">
      <v-card>
        <v-card-title> Edit User </v-card-title>

        <br />

        <v-card-text>
          <v-form class="Form">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.noKtp"
                    outlined
                    dense
                    label="No. KTP"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-file-input
                    prepend-icon=""
                    prepend-inner-icon="$file"
                    outlined
                    dense
                    label="Upload KTP"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.firstName"
                    outlined
                    dense
                    label="First Name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.lastName"
                    outlined
                    dense
                    label="Last Name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.username"
                    outlined
                    dense
                    label="User Name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.phone"
                    outlined
                    dense
                    label="No. Handphone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.email"
                    outlined
                    dense
                    label="Email"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.groupUser"
                    outlined
                    dense
                    label="Group User"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.hub"
                    outlined
                    dense
                    label="Hub Branch"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0 mt-2 mb-3"><h4>Alamat</h4> </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.city"
                    outlined
                    dense
                    label="Kota"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.village"
                    outlined
                    dense
                    label="Kelurahan"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="user.postCode"
                    outlined
                    dense
                    label="Kode Pos"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    v-model="user.address"
                    outlined
                    dense
                    rows="2"
                    no-resize
                    label="Alamat"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
          >
            edit user
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogEditUserComponent",
  props: ["user"],
  data() {
    return {
      dialogEditUser: false,
      // userDetail: {
      //   id: "",
      //   noKtp: "",
      //   firstName: "",
      //   lastName: "",
      //   username: "",
      //   phone: "",
      //   email: "",
      //   groupUser: "",
      //   hub: "",
      //   city: "",
      //   village: "",
      //   postCode: "",
      //   address: "",
      //   active: "",
      // },
    };
  },
  methods: {
    toggleDialog() {
      this.dialogEditUser = !this.dialogEditUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.Form {
  display: flex;
  gap: 20px;
}
</style>
