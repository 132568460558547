<template>
  <div class="LoginComponent">
    <h3>Login</h3>
    <br />
    <v-form v-model="isValid" @submit="login">
      <v-text-field
        outlined
        dense
        label="Phone"
        type="text"
        :rules="phoneRules"
        v-model="phone"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Password"
        :rules="passwordRules"
        v-model="password"
        :append-icon="valueShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (valueShowPassword = !valueShowPassword)"
        :type="valueShowPassword ? 'password' : 'text'"
      ></v-text-field>

      <v-btn
        block
        depressed
        color="cyan"
        class="white--text"
        :disabled="!isValid"
        type="submit"
        :loading="isLoading"
        >login</v-btn
      >
      <v-btn
        class="mt-2"
        block
        outlined
        color="cyan"
        @click="$emit('changeType', 'register')"
        >register
      </v-btn>
    </v-form>

    <v-snackbar v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { dashboardRoute } from "@/constant/routeName.js";
import { setTokenCookie } from "../../utils/cookies";
import { baseUrl } from "../../constant/url";
import { setProfileCookie } from "../../utils/cookies";

export default {
  name: "LoginComponent",
  data() {
    return {
      isValid: false,
      phone: "",
      password: "",
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      valueShowPassword: "",
      // emailRules: [
      //   (email) => !!email || "Required",
      //   (email) => /.+@.+/.test(email) || "E-mail must be valid",
      // ],
      phoneRules: [
        (phone) => !!phone || "Required",
        (phone) => Number.isInteger(Number(phone)) || "Must be number",
      ],
      passwordRules: [(password) => !!password || "Required"],
    };
  },
  methods: {
    async login(e) {
      e.preventDefault();
      // const token = await setTokenCookie("token");
      // if (token) {
      //   this.$router.push(dashboardRoute.dashboardHome);
      // }
      this.isLoading = true;
      axios({
        method: "post",
        url: `${baseUrl}/LoginHub`,
        data: {
          Telp: this.phone,
          Password: this.password,
        },
      })
        .then((res) => {
          const { data } = res.data;
          this.isLoading = false;

          setTokenCookie(data.token);
          setProfileCookie(data.profile);

          this.$router.push(dashboardRoute.dashboardHome);
        })
        .catch((err) => {
          const { data } = err.response;

          this.isLoading = false;
          this.snackbarText = data.message.error[0];
          this.snackbar = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
