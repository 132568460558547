<template>
  <div class="LoadingComponent">
    <v-progress-circular :size="50" indeterminate></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
};
</script>

<style lang="scss" scoped>
.LoadingComponent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
