<template>
  <div class="OrderManagement">
    <div class="OrderManagement-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col cols="2" class="pa-0">
            <v-text-field
              v-model="date"
              label="Date"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <dialog-assign-order-component
          :order="itemDetail"
          ref="assignOrderDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2"
          small
          color="success"
          @click="assign(item)"
        >
          assign
        </v-btn>

        <!-- <v-btn depressed small color="error" @click="cancelItem(item)">
          cancel
        </v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAssignOrderComponent from "../../../components/dashboard/inbound/DialogAssignOrderComponent.vue";

export default {
  components: { DialogAssignOrderComponent },
  name: "OrderManagement",
  data() {
    return {
      date: null,
      itemDetail: {
        number: "",
        orderId: "",
        date: "",
        origin: "",
        destination: "",
        status: "",
      },
      headers: [
        { text: "#", value: "number", sortable: false },
        { text: "Order Id", value: "orderId" },
        { text: "Date", value: "date" },
        { text: "Origin", value: "origin" },
        { text: "Destination", value: "destination" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      items: [
        {
          number: 1,
          orderId: 54321,
          date: "22/07/2021",
          origin: "Jakarta",
          destination: "Bandung",
          status: "reserved",
        },
        {
          number: 2,
          orderId: 54322,
          date: "22/07/2021",
          origin: "Jakarta",
          destination: "Surabaya",
          status: "reserved",
        },
      ],
    };
  },
  methods: {
    assign(item) {
      this.itemDetail = item;
      this.$refs.assignOrderDialog.toggleDialog();
    },
    cancelItem(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
