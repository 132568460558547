<template>
  <div class="Wallet"></div>
</template>

<script>
export default {
  name: "Wallet",
};
</script>

<style lang="scss" scoped></style>
