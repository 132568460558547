<template>
  <div>
    <v-dialog v-model="dialogAddUser" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="cyan"
          class="white--text"
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left> mdi-plus </v-icon>
          Add User
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add User </v-card-title>

        <br />

        <v-card-text>
          <v-form class="Form">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="No. KTP"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-file-input
                    prepend-icon=""
                    prepend-inner-icon="$file"
                    outlined
                    dense
                    label="Upload KTP"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="First Name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="Last Name"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="User Name"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="No. Handphone"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    type="email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <!-- <v-text-field
                    outlined
                    dense
                    label="Group User"
                  ></v-text-field> -->
                  <v-select
                    outlined
                    dense
                    label="Group User"
                    :rules="requiredRule"
                    item-text="text"
                    item-value="value"
                    :items="userTypeList"
                    v-model="idUserType"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Hub Branch"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0 mt-2 mb-3"><h4>Alamat</h4> </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="Kota"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="Kelurahan"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field outlined dense label="Kode Pos"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    outlined
                    dense
                    rows="2"
                    no-resize
                    label="Alamat"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="dialogAddUser = false">
            cancel
          </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="dialogAddUser = false"
          >
            add user
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { userTypeList } from "../../../constant/user";
export default {
  name: "DialogAddUserComponent",
  data() {
    return {
      dialogAddUser: false,
      idUserType: "",
    };
  },
  computed: {
    userTypeList() {
      // TODO: Only show Hub Data entry, Operation Hub & Driver for hub
      const userType = userTypeList.filter((v) => {
        return [5, 6, 7].includes(v.value);
      });
      return userType;
    },
  },
};
</script>

<style lang="scss" scoped>
.Form {
  display: flex;
  gap: 20px;
}
</style>
