<template>
  <div>
    <v-dialog v-model="dialogDeleteVendor" persistent width="600px">
      <v-card>
        <v-card-title> {{ vendor.name }} </v-card-title>

        <br />

        <v-card-text>
          <h3>Are you sure want to delete this Vendor??</h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="toggleDialog"> cancel </v-btn>
          <v-btn
            color="cyan"
            class="white--text"
            depressed
            @click="toggleDialog"
          >
            delete vendor
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogDeleteVendorComponent",
  props: ["vendor"],
  data() {
    return {
      dialogDeleteVendor: false,
    };
  },
  methods: {
    toggleDialog() {
      this.dialogDeleteVendor = !this.dialogDeleteVendor;
    },
  },
};
</script>

<style lang="scss" scoped></style>
