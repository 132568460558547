<template>
  <div class="Vendormanagement">
    <div class="Vendormanagement-Option">
      <v-container>
        <v-row>
          <v-col cols="2" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="vendor"
              label="Search Vendor"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="vendor"
      :sort-by="sortBy"
    >
      <template v-slot:top>
        <dialog-view-vendor-component
          :vendor="itemDetail"
          ref="viewVendorDialog"
        />

        <dialog-edit-vendor-component
          :vendor="itemDetail"
          ref="editVendorDialog"
        />

        <dialog-delete-vendor-component
          :vendor="itemDetail"
          ref="delteVendorDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="green" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>

        <!-- <v-btn depressed class="mr-2" small color="blue" @click="edit(item)">
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn depressed small color="error" @click="deleteItem(item)">
          <v-icon color="white"> mdi-delete </v-icon>
        </v-btn> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import DialogAddVendorComponent from "../../../components/dashboard/settings/DialogAddVendorComponent.vue";
import DialogDeleteVendorComponent from "../../../components/dashboard/settings/DialogDeleteVendorComponent.vue";
import DialogEditVendorComponent from "../../../components/dashboard/settings/DialogEditVendorComponent.vue";
import DialogViewVendorComponent from "../../../components/dashboard/settings/DialogViewVendorComponent.vue";

export default {
  name: "Vendormanagement",
  components: {
    DialogViewVendorComponent,
    DialogEditVendorComponent,
    DialogDeleteVendorComponent,
  },
  data() {
    return {
      vendor: null,
      itemDetail: {
        number: "",
        id: "",
        name: "",
        email: "",
        contact: "",
        pic: "",
        city: "",
        village: "",
        address: "",
      },
      sortBy: ["id", "name", "city", "email", "pic"],
      options: [{ text: "transporter one", value: "transporterOne" }],
      headers: [
        { text: "ID", value: "id", filterable: false },
        { text: "Name", value: "name" },
        { text: "Kota", value: "city" },
        { text: "Email", value: "email", filterable: false },
        { text: "Contact", value: "contact", filterable: false },
        { text: "PIC", value: "pic", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [
        {
          id: 123,
          name: "Transporter One",
          email: "transporterOne@mail.com",
          contact: "0812332342",
          pic: "Ding Liren",
          city: "Jakarta",
          village: "Kembangan",
          address: "Meruya Utara No.7",
        },
        {
          id: 124,
          name: "Transporter Two",
          email: "transporterTwo@mail.com",
          contact: "08123342322",
          pic: "Topalov",
          city: "Bandung",
          village: "Cijagra",
          address: "Suryalaya Utara No.7",
        },
      ],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewVendorDialog.toggleDialog();
    },
    edit(item) {
      this.itemDetail = item;
      this.$refs.editVendorDialog.toggleDialog();
    },
    deleteItem(item) {
      this.itemDetail = item;
      this.$refs.delteVendorDialog.toggleDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.Vendormanagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
