import axios from "axios";

import { baseUrl } from "../constant/url";
import { getProfileCookie, getTokenCookie } from "../utils/cookies";

export async function getUserProfile() {
  const id = getProfileCookie().IDUser;
  const { data: resp } = await axios({
    method: "get",
    url: `${baseUrl}/Profile/${id}`,
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`,
    },
  });
  return resp.data;
}
