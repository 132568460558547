<template>
  <div class="ManagePickup">
    <div class="ManagePickup-Option">
      <v-container class="ma-0">
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              v-model="orderId"
              label="Order Id"
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="orderId"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
    >
      <template v-slot:top>
        <dialog-assign-driver-component
          @refresh="fetchOrder"
          :driverType="'pickUp'"
          :orderDetail="itemDetail"
          ref="assignDriverDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          class="mr-2 white--text"
          small
          color="cyan"
          @click="assign(item)"
          :disabled="item.idStatus >= 5"
        >
          assign
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAssignDriverComponent from "../../../components/dashboard/inbound/DialogAssignDriverComponent.vue";

import axios from "axios";
import { getProfileCookie, getTokenCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import moment from "moment";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  components: { DialogAssignDriverComponent },
  name: "ManagePickup",
  data() {
    return {
      itemDetail: {
        number: "",
        driverId: "",
        name: "",
        phone: "",
        jobs: "",
        status: "",
      },
      orderId: null,
      isLoading: false,
      sortBy: [
        "dateCreated",
        "shipper",
        "origin",
        "recipient",
        "destination",
        "colly",
        "weight",
        "price",
        "status",
      ],
      headers: [
        { text: "Order Id", value: "idOrder" },
        { text: "Date", value: "dateCreated", filterable: false },
        { text: "Pengirim", value: "shipper", filterable: false },
        { text: "Origin", value: "origin" },
        { text: "Penerima", value: "recipient", filterable: false },
        { text: "Destination", value: "destination", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewDriverDialog.toggleDialog();
    },
    assign(item) {
      this.itemDetail = item;
      this.$refs.assignDriverDialog.toggleDialog();
    },
    fetchOrder() {
      this.isLoading = true;
      const hubId = getProfileCookie().KCX_HubIDHub;

      axios({
        method: "get",
        url: `${baseUrl}/Orders`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "4,5,6,7",
          hub: hubId,
        },
      })
        .then((res) => {
          this.items = res.data.data.map((order) => {
            return {
              idOrder: order.IDOrder,
              dateCreated: moment(order.DateCreated)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              origin: order.ShipperLoc.NamaCity,
              shipper: order.ShipperLoc.Nama,
              recipient: order.Recipient.Nama,
              destination: order.Recipient.City.NamaCity,
              colly: order.TotalKoli,
              weight: order.TotalBerat,
              status: order.PackageStatus.Status,
              idStatus: order.PackageStatus.IDPackageStatus,
              price: formatToRupiah(order.TotalBiaya),
            };
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchOrder();
  },
};
</script>

<style lang="scss" scoped>
.ManagePickup {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
